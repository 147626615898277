import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import styles from './index.module.scss';
import {
  useGetMyProfile,
  useUpdateMyProfile,
  useGetActiveDater,
  usePatchEditFriend,
} from '../../services/mutations';
import Loader from '../../molecule/Loader';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  localStorageKeys,
  useLocalStorage,
} from '../../utils/localStorageItems';
import SwitchEyeFill from '../../assets/SwitchEyeFill.svg';
import CustomSwitch from '../../atoms/CustomSwitch';
import Profile_Avatar from '../../assets/Profile_Avatar.png';
import { scaleByRatio } from '../../utils/helper';
import MyProfileFriendView from '../MyProfileFriendView';
import MyProfileFriendEdit from '../MyProfileFriendEdit';
import { routeUrls } from '../../utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import close_icon from '../../assets/close_icon.svg';

const MyProfileFriend = () => {
  const nav = useNavigate();
  const updateMyProfile = useUpdateMyProfile();
  const getActiveDater = useGetActiveDater();
  const editFriend = usePatchEditFriend();

  const localStorageItem = useLocalStorage();
  const getMyProfile = useGetMyProfile();
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [friendDetails, setFriendDetails] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');

  const loadInitialdata = () => {
    setLoading(true);
    getMyProfile.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log('getMyProfile onSuccess: ', response);
          // localStorage.setItem(
          //   'profileImage',
          //   response?.data?.payload?.profile_pic,
          // );

          setUserDetails(response?.data?.payload);
          setLoading(false);
          getFriendDetails();
        },
        onError: (err) => {
          console.log('getMyProfile onError: ', err);
          setLoading(false);
        },
      },
    );
  };

  const getFriendDetails = () => {
    setLoading(true);
    getActiveDater.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log('getActiveDater success: ', response);
          setFriendDetails(response?.data?.payload?.friend);
          setLoading(false);
        },
        onError: (error) => {
          setLoading(false);
          console.log('getActiveDater error: ', error);
        },
      },
    );
  };

  const handleEditChange = (_editable) => {
    console.log('handleEditChange >> ', _editable);
    setShowEdit(true);
    nav(routeUrls.home, {
      state: {
        showEdit: false,
      },
    });
  };

  const handleCancelEditOnchange = () => {
    setShowEdit(false);
    nav(routeUrls.home, {
      state: {
        showEdit: false,
      },
    });
  };

  const handleOnSubmitCallback = (details) => {
    console.log('handleOnSubmitCallback: ', details);
    setErrorMsg('');
    if (details.profile_pic) {
      uploadProfilePicture(details);
    } else {
      updateName(details);
    }
  };

  const uploadProfilePicture = (details) => {
    let form = new FormData();
    form.append('profile_pic', details.profile_pic);
    setLoading(true);
    updateMyProfile.mutate(form, {
      onSuccess: (response) => {
        console.log('uploadProfilePicture: ', response);
        localStorage.setItem(
          'profileImage',
          response?.data?.payload?.profile_pic,
        );
        setLoading(false);
        updateName(details);
      },
      onError: (error) => {
        console.log(error.error);
        setErrorMsg(error?.response?.data?.error);
        setLoading(false);
      },
    });
  };
  const updateName = (details) => {
    let data = {
      user: {
        first_name: details?.first_name,
        last_name: details?.last_name,
      },
    };
    setLoading(true);
    updateMyProfile.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        updateDescription(details);
      },
      onError: (error) => {
        console.log(error.error);
        setErrorMsg(error?.response?.data?.error);
        setLoading(false);
        setShowEdit(false);
      },
    });
  };
  const updateDescription = (details) => {
    let data = {
      id: friendDetails.id,
      data: {
        description: details.description || '',
      },
    };
    setLoading(true);
    editFriend.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        setShowEdit(false);
      },
      onError: (error) => {
        console.log('updateDescription: ', error?.response?.data?.error);
        setErrorMsg(error?.response?.data?.error);
        setShowEdit(false);
        setLoading(false);
      },
    });
  };
  const handleClose = () => {
    nav(routeUrls.profile);
    window.history.back();
  };
  useEffect(() => {
    if (!showEdit) {
      loadInitialdata();
    }
  }, [showEdit]);

  return (
    <>
      <Loader open={loading} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          pb: 3,
          pt: scaleByRatio(3),
          marginTop: `${scaleByRatio(80)}px`,
        }}>
        <Grid
          container
          direction="row"
          alignItems="flex-end"
          sx={{
            background: '#000',
            // minHeight: `${scaleByRatio(40)}px`,
            // paddingBottom: `${scaleByRatio(10)}px`,
          }}
          className={styles.friendHeader}
          >
          <Grid item xs={10} justifyContent="left">
            <Typography textAlign="left" className={styles.nameAddressHeader}
            marginLeft={'27px'}>
              My Profile
            </Typography>
          </Grid>
          <Grid
            item
            sx={{
              // position: 'absolute', // Position close icon absolutely
              marginLeft: '25px', // Align it to the extreme right with some margin
              top: 0,
              cursor: 'pointer',
              fontWeight: 'bold',
            }}
            onClick={handleClose}
            className={styles.closeIcon}>
            <Typography>
              <img src={close_icon} alt="Close" />
            </Typography>
          </Grid>
        </Grid>
        {!showEdit && (
          <MyProfileFriendView
            email={userDetails?.user?.email}
            phone_number={userDetails?.user?.phone_number}
            first_name={userDetails?.user?.first_name}
            last_name={userDetails?.user?.last_name}
            profile_pic={userDetails?.profile_pic || Profile_Avatar}
            handleEditOnchange={handleEditChange}
            description={friendDetails?.description}
          />
        )}
        {showEdit && (
          <MyProfileFriendEdit
            first_name={userDetails?.user?.first_name}
            last_name={userDetails?.user?.last_name}
            profile_pic={userDetails?.profile_pic || Profile_Avatar}
            handleCancelEditOnchange={handleCancelEditOnchange}
            handleOnSubmitCallback={handleOnSubmitCallback}
            description={friendDetails?.description}
            errorMsg={errorMsg}
          />
        )}
      </Box>
    </>
  );
};

export default MyProfileFriend;
