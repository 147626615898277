import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import CustomChip from '../../atoms/CustomChip';
import Stack from '@mui/material/Stack';
import { scaleByRatio } from '../../utils/helper';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';
import React from 'react';

const MyProfileSection = ({
  heading,
  description,
  arrChips = [], // this will be array of arrays
  handleChipClick,
}) => {
  // console.log('MyProfileSection heading: ', heading)
  const nav = useNavigate();
  console.log('MyProfileSection arrChips: ', arrChips);
  const ChipClicked = (chip) => {
    console.log('ChipClicked: ', chip);
    handleChipClick(chip);
    nav(routeUrls.home, {
      state: {
        showEdit: false,
      },
    });
  };

  console.log(arrChips)
  return (

    <Grid container direction="column" alignItems="center">
    {/* Grid container for the content */}
    <Grid container direction="column" alignItems="center">
      {/* First row */}
      <Grid container direction="row" alignItems="flex-start">
        {heading && heading.length > 0 && (
          <Grid
            item
            xs={12}
            alignItems="flex-end"
            style={{ minHeight: `${scaleByRatio(20)}px`, borderBottom: '1px solid #cccccc' }}
            className={styles.header}
          >
            <Typography
              fullwidth
              textAlign="left"
              className={styles.subHeader}
              style={{
                fontSize: `${scaleByRatio(24)}px`,
              }}
            >
              {heading}
            </Typography>
          </Grid>
        )}
        {description && description.length > 0 && (
          <Grid item xs={12}>
            <Typography
              fullwidth
              textAlign="left"
              className={styles.description}
            >
              {description}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          {arrChips && arrChips.length > 0 && (
          <Stack
          direction="row"
          spacing={scaleByRatio(5)} // Adjust spacing between chips
          className={styles.stackClass}
          flexWrap="wrap"
          justifyContent="space-between" // Align items with space between them
          style={{
            gap: `${scaleByRatio(5)}px`, // Adjust gap between chips
            width: '100%', // Ensure the stack fills its container width
          }}
        >
          {arrChips.map((chips, index) => (
            <React.Fragment key={index}>
              <CustomChip
                label={chips?.label}
                clickable={chips?.clickable || false}
                handleClick={() =>
                  chips?.clickable ? ChipClicked(chips) : null
                }
                style={{ fontSize: `${scaleByRatio(54)}px` 
              }}
              />
            </React.Fragment>
          ))}
        </Stack>
        
          
          )}
        </Grid>
      </Grid>
      {/* Bottom horizontal line for the row */}
      { arrChips.length>0 &&  
       <hr style={{ width: '100%', border: 'none', height: '0.5px' ,  backgroundColor: '#cccccc'}} />
        //<hr style={{ width: '100%', borderColor: '#cccccc' }} />
        }
    </Grid>
  </Grid>
  
  
  );
};

export default MyProfileSection;
 {/* <Stack direction="row" spacing={1} className={styles.stackClass}>
                    <CustomChip label='Men' clickable={false} /> 
                    <CustomChip label='Want Children' clickable={false} /> 
                </Stack> */}