import React, { useState, useEffect } from 'react'
import styles from './index.module.scss'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
import CustomSwitch from '../../atoms/CustomSwitch'
import SwitchActiveOpenEye from '../../assets/SwitchActiveOpenEye.svg'
import SwitchInactiveCloseEye from '../../assets/SwitchInactiveCloseEye.svg'

import Profile_Avatar from '../../assets/Profile_Avatar.png'
import Stack from '@mui/material/Stack'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { IconButton } from '@mui/material'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import dayjs from 'dayjs';
import SwitchActive from '../../assets/SwitchActive.svg';
import SwitchInactive from '../../assets/SwitchInactive.svg';
import { scaleByRatio } from '../../utils/helper';
import {
    localStorageKeys,
    localStorageUserType,
    useLocalStorage,
} from '../../utils/localStorageItems';

const DaterListItem = ({
    daterDetails,
    callBackViewChange,
    activeUser
}) => {
    const localStorage = useLocalStorage();
    const handleChange = (status) => {
        callBackViewChange(status, daterDetails?.id);
        localStorage?.setLocalStorageItem('activeDaterImage', daterDetails?.profile_pic);
    }
    return (
        <>
            <Grid container direction="row" columnSpacing={0.5} className={styles.root}
                style={{
                    paddingTop: `${scaleByRatio(15)}px`,
                }}
            >
                <div className={styles.daterFirstDiv}>
                    <Grid item alignItems={"center"} justifyContent="left" style={{ paddingTop: `${scaleByRatio(5)}px`,paddingRight:'10px' }}>
                        <Typography textAlign='left'>
                            <img src={daterDetails?.profile_pic || Profile_Avatar} alt="profile" width={scaleByRatio(90)} height={scaleByRatio(90)} style={{borderRadius:'50%'}}/>
                        </Typography>
                    </Grid>
                    <Grid item justifyContent="left" alignItems={"center"} style={{ paddingTop: `${scaleByRatio(5)}px`, display: "flex" }}>
                        {/* <Grid container direction="column" justifyContent="left"> */}
                        <Stack direction="column" spacing={scaleByRatio(1.8)} gap={1} className={styles.stackClass} flexWrap="wrap" style={{ display: "flex" }}>
                            <Grid item xs={12} alignItems={"center"} style={{ display: "flex",marginTop:'-40px' }}>
                                <Typography fullwidth textAlign='left' className={styles.profileName}
                                >
                                    {daterDetails?.dater?.user?.first_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} alignItems={"center"} style={{ display: "flex", margin: '1px' }}>
                                <Typography fullwidth textAlign='left' className={styles.lastUpdate}
                                >
                                    Last Active {dayjs(daterDetails?.updated_on).format('YYYY-MM-DD HH:mm')}
                                </Typography>
                            </Grid>

                        </Stack>
                        {/* </Grid> */}
                    </Grid>
                </div>
                <div className={styles.daterSecDiv}>
                    <Grid item justifyContent="left" alignItems="center" style={{ display: "flex" }}>
                        <Typography fullwidth textAlign='left' className={styles.lastUpdate}
                            style={{
                                fontSize: `${scaleByRatio(10)}px`
                            }}
                        >
                            <CustomSwitch
                                txtSwitchOn={'VIEWING'}
                                txtSwitchOff={'NOT VIEWING'}
                                checked={(activeUser?.id === daterDetails?.dater?.id)}
                                handleChange={handleChange}
                                iconThumb={SwitchActive}
                                iconThumbUncheck={SwitchInactive}
                                switchWidth={180}
                            />
                        </Typography>
                    </Grid>
                </div>
            </Grid>
            <hr style={{ marginLeft: '-5px', marginRight: '-5px' , border: 'none', height: '0.5px' , marginBottom:'5px', backgroundColor: '#cccccc'}} />
        </>
    );
}

export default DaterListItem;