import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import styles from './index.module.scss';

import MyProfileAboutUs from '../MyProfileAbouUs';
import MyProfileMyInterests from '../MyProfileMyInterests';
import Loader from '../../molecule/Loader';

import OthersFriendList from '../OthersFriendList';
import MyProfileSectionView from '../MyProfileSectionView';
import MyProfilePhotos from '../MyProfilePhotos';
import close_icon from '../../assets/close_icon.svg';


import {
  useGetUserProfileByID,
  useGetAllQuestions,
  useGetUsersFriendList,
  useGetResponseForAllQuesionsByUser,
  usePatchChatterBoxProspectiveDaterAcceptanceStatus,
  usePostViewHistory,
} from '../../services/mutations';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import { scaleByRatio } from '../../utils/helper';
import {
  ProfilePageType,
  ACCEPTANCE_STATUS_ELBOW,
  ExploreViewHistoryStatus,
} from '../../utils/constants';
import CustomAlert from '../../atoms/CustomAlert';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import MyProfileOthersData from '../MyProfileOthersData';
import { GroupChatCloseContext } from '../../utils/Context/reportCounter';

const ChatterBoxProspectiveDaterProfile = ({
  onBackClicked,
  handleButtonClickedCallback,
  dater,
  activeDaterID,
}) => {
  console.log('dater: ', dater, activeDaterID);
  const localStorageItem = useLocalStorage();
  const getUserProfileByID = useGetUserProfileByID();
  const getAllQuestions = useGetAllQuestions();
  const getUsersFriendList = useGetUsersFriendList();
  const getResponseForAllQuesionsByUser = useGetResponseForAllQuesionsByUser();
  const patchChatterBoxProspectiveDaterAcceptanceStatus =
    usePatchChatterBoxProspectiveDaterAcceptanceStatus();
  const postViewHistory = usePostViewHistory();

  const [allQuestions, setAllQuestions] = useState(null);
  const [nameAgeLocation, setNameAgeLocation] = useState('');
  const [userDetails, setUserDetails] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [nameAgeLocationArray ,setNameAgeLocationArray ]= useState([]);

  const [userImages, setUserImages] = useState([]);
  const [allAnswers, setAllAnswers] = useState(null);
  const [friendsList, setFriendsList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [showOtherFriendsList, setShowOtherFriendsList] = useState(false);
  const [groupChatClose, setGroupChatClose] = useContext(GroupChatCloseContext);
  const [checkCounter, setCheckCounter] = useState(0);
  const arrProfileSections = [
    {
      header: 'My Work',
      type: 'Questions',
      identifiers: [22, 23],
    },
    {
      header: 'I dig someone that.',
      type: 'Questions',
      identifiers: [7],
    },
    {
      header: 'My Politics',
      type: 'Questions',
      identifiers: [8],
    },
    {
      header: 'I dream of a person who.',
      type: 'Questions',
      identifiers: [2],
    },
    {
      header: 'Things of spirit.',
      type: 'Questions',
      identifiers: [12],
    },
    {
      header: 'Hobbies and fun.',
      type: 'Questions',
      identifiers: [9],
    },
    {
      header: 'My deepest passion is.',
      type: 'Questions',
      identifiers: [3],
    },
    {
      header: 'Excercise and Sports.',
      type: 'Questions',
      identifiers: [9],
    },
    {
      header: 'Dream Job.',
      type: 'Questions',
      identifiers: [24],
    },
    {
      header: 'Home and habbits.',
      type: 'Questions',
      identifiers: [10],
    },
    {
      header: 'I feel most at home when.',
      type: 'Questions',
      identifiers: [4],
    },
    {
      header: 'Culture and Media.',
      type: 'Questions',
      identifiers: [11],
    },
    {
      header: 'The most fun I remember when is.',
      type: 'Questions',
      identifiers: [6],
    },
    {
      header: 'To me my family is.',
      type: 'Questions',
      identifiers: [5],
    },
  ];
  const fetchUserProfileDetailsById = () => {
    let data = {
      id: dater?.user?.id,
    };
    setLoading(true);
    getUserProfileByID.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        console.log('getMyProfile onSuccess: ', response);
        setUserDetails(response?.data?.payload);
        setNameAgeLocationArray(
          [
            response?.data?.payload?.user?.first_name,
            response?.data?.payload?.age,
            response?.data?.payload?.city
          ]
        );
        setNameAgeLocation(
          response?.data?.payload?.user?.first_name +
            ', ' +
            response?.data?.payload?.age +
            ' ' +
            response?.data?.payload?.city,
        );
        setProfilePic(response?.data?.payload?.profile_pic || null);
        setUserImages(response?.data?.payload?.images || []);
        fetchAllQuestions();
      },
      onError: (err) => {
        console.log('getMyProfile onError: ', err);
        setLoading(false);
      },
    });
  };
  const fetchAllQuestions = () => {
    setLoading(true);
    getAllQuestions.mutate(
      {},
      {
        onSuccess: (response) => {
          setLoading(false);
          console.log('getAllQuestions onSuccess: ', response);
          setAllQuestions(response?.data?.payload?.results);
          fetchAllAnswers();
        },
        onError: (error) => {
          setLoading(false);
          console.log('getAllQuestions onError :', error);
          setLoading(false);
        },
      },
    );
  };
  const fetchAllAnswers = () => {
    let answerData = {
      limit: 200,
      offset: 0,
      user: dater?.user?.id,
    };
    setLoading(true);
    getResponseForAllQuesionsByUser.mutate(answerData, {
      onSuccess: (response) => {
        let arrAnswer = response?.data?.payload?.results;
        console.log('arrAnswer: ', arrAnswer);
        setAllAnswers(arrAnswer);
        setLoading(false);
        fetchFriendList();
      },
      onError: (error) => {
        setAllAnswers(null);
        setLoading(false);
      },
    });
  };
  const fetchFriendList = () => {
    setLoading(true);
    let data = {
      dater: dater?.user?.id,
    };
    getUsersFriendList.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        console.log('getUsersFriendList success:', response);
        setFriendsList(response?.data?.payload?.results);
      },
      onError: (error) => {
        setLoading(false);
        console.log('getUsersFriendList error:', error);
        setFriendsList([]);
      },
    });
  };
  const onAlertClose = () => {
    setAlertMsg('');
    onBackClicked();
  };
  const onNoClicked = () => {
    console.log('on NO clicked');
    changeProspectiveDaterAcceptanceStatus(ExploreViewHistoryStatus.NO);
    onBackClicked();
  };
  const onYesClicked = () => {
    console.log('on YES clicked');
    // changeProspectiveDaterAcceptanceStatus(ACCEPTANCE_STATUS_ELBOW.ElbowSent);
    changeProspectiveDaterAcceptanceStatusReachout(
      ACCEPTANCE_STATUS_ELBOW.ElbowSent,
    );
    //onBackClicked();
  };

  const changeProspectiveDaterAcceptanceStatusReachout = (status) => {
    let data = {
      prospective_dater_id: dater?.id,
      acceptance_status: status,
    };
    console.log('changeProspectiveDaterAcceptanceStatus: ', data);
    setLoading(true);
    patchChatterBoxProspectiveDaterAcceptanceStatus.mutate(data, {
      onSuccess: (respons) => {
        setLoading(false);
        let message =
          status === ACCEPTANCE_STATUS_ELBOW.ElbowSent
            ? 'Elbow Request Sent.'
            : 'Profile Declined.';
        setAlertMsg(message);
      },
      onError: (error) => {
        setLoading(false);
        onBackClicked();
      },
    });
  };

  const changeProspectiveDaterAcceptanceStatus = (status) => {
    // let data = {
    //   prospective_dater_id: dater?.id,
    //   acceptance_status: status,
    // };
    // console.log('changeProspectiveDaterAcceptanceStatus: ', data);
    // setLoading(true);
    // patchChatterBoxProspectiveDaterAcceptanceStatus.mutate(data, {
    //   onSuccess: (respons) => {
    //     setLoading(false);
    //     let message =
    //       status === ACCEPTANCE_STATUS_ELBOW.ElbowSent
    //         ? 'Elbow Request Sent.'
    //         : 'Profile Declined.';
    //     setAlertMsg(message);
    //   },
    //   onError: (error) => {
    //     setLoading(false);
    //     onBackClicked();
    //   },
    // });
    // bdff857a-4d43-4ac4-9f1a-d4c401b6b6be
    const formData = new FormData();
    formData.append('action', status);
    formData.append('viewed_profile', userDetails?.id);
    postViewHistory.mutate(formData, {
      onSuccess: (response) => {
        setLoading(false);
        console.log('updateProspectiveDaterViewHistory success: ', response);
        setAlertMsg('Profile Declined!');
      },
      onError: (error) => {
        console.log('updateProspectiveDaterViewHistory error: ', error);
        setLoading(false);
        onBackClicked();
      },
    });
  };
  const friendsClickCallBack = () => {
    console.log('friendsClickCallBack');
    setShowOtherFriendsList(true);
  };
  const friendsBackClickCallBack = () => {
    setShowOtherFriendsList(false);
  };
  useEffect(() => {
    setGroupChatClose(false)
    setCheckCounter(1)
    fetchUserProfileDetailsById();
    console.log('dater: ', dater);
  }, []);

  useEffect(() => { 
    if(groupChatClose && checkCounter==1){
      onBackClicked()
    }
  }, [groupChatClose,checkCounter]);

  


  return (
    <>
      {alertMsg.length > 0 && (
        <CustomAlert
          isOpen={alertMsg.length > 0 ? true : false}
          message={alertMsg}
          onClose={onAlertClose}
        />
      )}
      <Loader open={loading} />
      <Box
        component="main"
        backgroundColor="#000"
        sx={{
          flexGrow: 1,
          pb: 3,
          pt: scaleByRatio(3),
          // background: '#FFFFFF',
          background: '#000',
        }}>
        {!loading && !showOtherFriendsList && (
          <>
            <Grid
              container
              direction="row"
              alignItems="flex-end"
              justifyContent={'space-between'}
              sx={{
                // background: '#FFFFFF',
                background: '#000',
                // minHeight: `${scaleByRatio(50)}px`,
                paddingBottom: `${scaleByRatio(10)}px`,
              }}>
              {/* <Grid
                item
                xs={1}
                justifyContent="left"
                alignItems="flex-end"
                className={styles.logoContainer}>
                <img
                  src={ArrowLeft}
                  alt={'Back icon'}
                  className={styles.logo}
                  onClick={onBackClicked}
                  width={scaleByRatio(24)}
                  height={scaleByRatio(24)}
                />
              </Grid> */}
              <Grid item 
              // xs={10} 
              display='flex'
              justifyContent="left">
                {/* <Typography
                  textAlign="center"
                  className={styles.nameAddressHeader}
                  style={{
                    fontSize: `${scaleByRatio(24)}px`,
                  }}>
                  {nameAgeLocation}
                </Typography> */}
                 <Typography
                 justifyContent={'left'}
                  textAlign="left"
                  className={styles.nameAddressHeader}>
                       <span className={styles.name}>{nameAgeLocationArray[0]}</span>{' '}
                        <span className={styles.ageAddress}>{nameAgeLocationArray[1]}, {nameAgeLocationArray[2]} </span>
                  {/* {nameAgeLocation} */}
                </Typography>
              </Grid>

              <Grid
                display={'flex'}
                justifyContent={'right'}
                color={'#fff'}
                // marginTop={'-40px'}
                // marginBottom={'15px'}
                marginRight={'20px'}
                style={{ cursor: 'pointer', fontWeight: 'bold' }}
                onClick={onBackClicked}
                className={styles.closeIcon}>
                <Typography>
                  <img src={close_icon}/>
                </Typography>
              </Grid>

            </Grid>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                pb: 9,
                pt: 3,
                pr: 3,
                pl: 3,
                // background: '#F3F3F3',
                background: '#000',
              }}>
              <MyProfileAboutUs
                profilePic={profilePic}
                allAnswers={allAnswers}
                userDetails={userDetails}
                handleChipClick={() => {}}
                setClickable={() => {}}
                friendsList={friendsList}
                handleMenuClicked={() => {}}
                setShowAddFriend={() => {}}
                pageType={ProfilePageType.ChatterBox}
                elbow_request_status={dater?.acceptance_status}
                convos_direct_session_introw_status={null}
                showChatterBoxAcceptanceButtons={
                  dater?.acceptance_status === ACCEPTANCE_STATUS_ELBOW.Pending
                    ? true
                    : false
                }
                onNoClicked={onNoClicked}
                onYesClicked={onYesClicked}
                onMaybeClicked={() => {}}
                friendsClickExploreCallBack={friendsClickCallBack}
              />

              <MyProfileMyInterests
                allAnswers={allAnswers}
                handleChipClick={() => {}}
              />
              <MyProfilePhotos
                arrImages={userImages}
                handleChipClick={() => {}}
              />
              {/* <MyProfileSectionView
                arrProfileSections={arrProfileSections}
                allAnswers={allAnswers}
                clickable={false}
                handleChipClick={() => {}}
              /> */}
              <MyProfileOthersData
                allQuestions={allQuestions}
                allAnswers={allAnswers}
                clickable={false}
                // setEditMode={setEditMode}
              />
            </Box>
          </>
        )}
        {!loading && showOtherFriendsList && (
          <OthersFriendList
            dater_id={dater?.user?.id}
            dater_name={dater?.user?.user?.first_name}
            onBackClicked={friendsBackClickCallBack}
            activeDater={activeDaterID}
            showReachout={
              localStorageItem.getLocalStorageItem(
                localStorageKeys.userType,
              ) === localStorageUserType.dater &&
              dater?.acceptance_status !== ACCEPTANCE_STATUS_ELBOW.ElbowSent
            }
          />
        )}
      </Box>
    </>
  );
};

export default ChatterBoxProspectiveDaterProfile;
