import React, { useState } from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import { AnswerType, HeightinFt, HeightinInch } from '../../utils/constants';
import InputField from '../../atoms/InputField';
import Geocode from 'react-geocode';
import { useUpdateMyProfile } from '../../services/mutations';
import Loader from '../../molecule/Loader';
import { scaleByRatio } from '../../utils/helper';
import AppLogoMin from '../../assets/AppLogoMin.svg';

// import {
//     setKey,
//     setDefaults,
//     setLanguage,
//     setRegion,
//     fromAddress,
//     fromLatLng,
//     fromPlaceId,
//     setLocationType,
//     geocode,
//     RequestType,
//   } from "react-geocode";
//est https://developers.google.com/maps/documentation/geocoding/overview

const Location = ({
  questionText,
  answerType,
  onBackClicked,
  handleOnNextClicked,
  error,
  answer = '',
  showBackButton = true,
  onSuccess,
  showSteps = false,
  currentStep,
  totalSteps,
  showAppIcon = true,
}) => {
  Geocode.setApiKey('AIzaSyBtKWw0WPj3eCV398SIlk4UDmzrVPeATXg');
  Geocode.setLanguage('en');
  Geocode.setRegion('us');
  Geocode.setLocationType('ROOFTOP');

  const [zipCode, setZipCode] = useState();
  const [locationError, setLocationError] = useState(error);
  const [loading, setLoading] = useState(false);
  const updateMyProfile = useUpdateMyProfile();

  const onInputChange = (evt) => {
    console.log('onInputChange: ', evt.target.value);
    setZipCode(evt.target.value);
  };
  const onNextClicked = () => {
    setLocationError('');
    console.log('zipcode: ', zipCode);
    setLoading(true);
    Geocode.fromAddress(zipCode)
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        console.log('results geolocation: ', results);
        console.log(lat, lng);
        let address = results[0]?.formatted_address.split(',')[0] || '';

        let locLat = lat;
        let strLat = lat + '';
        if (strLat.length > 11) {
          strLat = strLat.substring(0, 10);
        }
        locLat = parseFloat(strLat);

        let locLng = lng;
        let strLng = lng + '';
        if (strLng.length > 11) {
          strLng = strLng.substring(0, 10);
        }
        locLng = parseFloat(strLng);

        console.log('Long Count:', lng.length);
        let data = {
          lat: locLat,
          lng: locLng,
          city: address,
        };

        updateMyProfile.mutate(data, {
          onSuccess: (response) => {
            setLoading(false);
            onSuccess();
          },
          onError: (err) => {
            console.log('updateMyProfile onError: ', err);
            setLocationError('Something went wrong. Please try again.');
            setLoading(false);
          },
        });
      })
      .catch(() => {
        setLocationError('Could not find the location. Please try again');
        setLoading(false);
      });
  };
  return (
    <>
      <Loader open={loading} />
      {showAppIcon && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer} marginTop={-14}>
            {/* <Typography textAlign='center' className={styles.logoContainer}> */}
            <img
              src={AppLogoMin}
              alt={'App icon'}
              className={styles.logo}
              style={{
                width: `90%`,
                marginBottom: '30px',
                height: `${scaleByRatio(110)}px`,
              }}
            />
            {/* </Typography> */}
          </Grid>
        </Grid>
      )}
      {showBackButton && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer}>
            <img
              src={ArrowLeft}
              alt={'Back icon'}
              className={styles.logoback}
              onClick={onBackClicked}
              width={scaleByRatio(12)}
              height={scaleByRatio(12)}
            />
          </Grid>
          <Grid item>
            <Typography
              textAlign="left"
              className={styles.back}
              onClick={onBackClicked}
              style={{
                fontSize: `${scaleByRatio(12)}px`,
                paddingLeft: `${scaleByRatio(10)}px`,
                paddingBottom: `${scaleByRatio(2)}px`,
              }}>
              Back
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Grid item>
          <Typography
            textAlign="left"
            className={styles.question}
            style={{
              fontSize: `${scaleByRatio(22)}px`,
              lineHeight: `${scaleByRatio(28.64)}px`,
              letterSpacing: '0.06em', 
              marginLeft: '15px'
            }}>
            {questionText}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Grid item xs={12} style={{ paddingRight: `${scaleByRatio(5)}px` }}>
          {/* <TextField label="Mobile Number" variant="filled" type="number" className={styles.inputMobileNumber}/> */}
          <InputField
            name="zipcode"
            id={'zipcode'}
            value={zipCode}
            placeholder={'Zip Code'}
            handleChange={onInputChange}
            type={'text'}
            class_name={styles.inputHeight}
            tabIndex="1"
          />
        </Grid>
      </Grid>
      {locationError && locationError?.length > 0 && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Typography
            textAlign="left"
            className={styles.error}
            style={{
              fontSize: `${scaleByRatio(12)}px`,
              lineHeight: `${scaleByRatio(40)}px`,
            }}>
            {locationError}
          </Typography>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="end"
        alignItems="flex-end">
        <Grid item xs={6}>
          {showSteps && (
            <Typography
              textAlign={'left'}
              className={styles.pagination}
              style={{
                padding: `${scaleByRatio(12)}px ${scaleByRatio(32)}px`,
                fontSize: `${scaleByRatio(25)}px`,
                lineHeight: `${scaleByRatio(34)}px`,
              }}>
              {currentStep}/{totalSteps}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          justifyContent="right"
          style={{
            display: 'flex',
          }}>
          <CustomButton
            text={'Next'}
            variant={ButtonVariant.contained}
            type={ButtonType.primary}
            onClick={() => onNextClicked()}
            customStyle={`yes`}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Location;
