import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styles from './index.module.scss';
import { scaleByRatio } from '../../utils/helper';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import InputField from '../../atoms/InputField';

const MessageDialog = ({
  isOpen = false,
  headingpart1 = '',
  headingpart2 = '',
  onYesClick,
  onNoClick,
}) => {
  const [open, setOpen] = useState(isOpen);
  const [customTextMessage, setCustomTextMessage] = useState('');
  const handleCancel = () => {
    setOpen(false);
    onNoClick();
  };
  const handleAgree = () => {
    if (customTextMessage && customTextMessage.length > 0) {
      onYesClick(customTextMessage);
      setOpen(false);
    }
  };
  const handleCustomMessageChange = (evt) => {
    setCustomTextMessage(evt.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle
        id="alert-dialog-title"
        className={styles.heading}
        style={{
          fontSize: `${scaleByRatio(32)}px`,
          lineHeight: `${scaleByRatio(38)}px`,
        }}>
        Write your{' '}
        <span
          style={{
            color: '#F46B1B',
          }}>
          message
        </span>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent={'left'}
          alignItems="flex-end"
          sx={{ background: '#FFFFFF' }}>
          <InputField
            name="customMessage"
            id={'customMessage'}
            value={customTextMessage}
            placeholder={'Message'}
            handleChange={(evt) => handleCustomMessageChange(evt)}
            type={'text'}
            class_name={styles.customMessage}
            multiline={true}
            rows={7}
          />
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: 'center',
          display: 'flex',
        }}>
        <Button
          onClick={handleCancel}
          autoFocus
          className={`${styles.button}  `}
          style={{
            fontSize: `${scaleByRatio(18)}px`,
            lineHeight: `${scaleByRatio(24)}px`,
          }}>
          Cancel
        </Button>
        <Button
          onClick={handleAgree}
          className={`${styles.button} ${styles.primary}`}
          style={{
            fontSize: `${scaleByRatio(18)}px`,
            lineHeight: `${scaleByRatio(24)}px`,
          }}
          disabled={customTextMessage.length === 0}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MessageDialog;
