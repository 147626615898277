export const API_BASE_PATH = window.location.origin;

export const API_PATH = {
  SEND_OTP: '/api/v1/users/login/send-otp/',
  VERIFY_OTP: '/api/v1/users/login/verify-otp/',
  VERIFY_OTP_SIGNUP: '/api/v1/users/signup/verify-otp/',
  SEND_OTP_INVITE_FRIEND: (data) =>
    `/api/v1/users/friend/${data.request_uid}/send-otp/`,
  VERIFY_OTP_INVITE_FRIEND: (data) =>
    `/api/v1/users/friend/${data.request_uid}/verify-otp/`,
  GET_MY_PROFILE: '/api/v1/users/my-profile/',
  GET_FRIEND_ACTIVITY: (friend_relationship) =>
    `/api/v1/users/friend/${friend_relationship}/activity/`,
  GET_PENDING_QUESTIONS: '/api/v1/users/my-profile/pending-question/?limit=100',
  GET_QUESTION_DETAILS_BY_ID: (data) => `/api/v1/home/questions/${data.id}/`,
  POST_MULTICHOICE_ANSWER: (question_Id) =>
    `/api/v1/users/questions/${question_Id}/multi-choice/`,
  PATCH_SINGLECHOICE_ANSWER: (question_Id) =>
    `/api/v1/users/questionresponse/${question_Id}/`,
  // PATCH_SINGLECHOICE_ANSWER:(question_Id)=>`/api/v1/users/questions/${question_Id}/`,
  POST_SINGLECHOICE_ANSWER: `/api/v1/users/questionresponse/`,
  GET_QUESTION_RESPONSE: (question_Id) =>
    `/api/v1/users/questionresponse/${question_Id}/`,
  GET_ALL_QUESTION_RESPONSE_BY_USER: (user) =>
    `/api/v1/users/questionresponse/?limit=200&user=${user}`,
  POST_OTHER_IMAGES: '/api/v1/home/images/',
  DELETE_IMAGE: (id) => `/api/v1/home/images/${id}/`,
  GET_ALL_QUESTIONS: `/api/v1/home/questions/?limit=200`,
  GET_ALL_FRIENDS: (dater) => `/api/v1/users/friend/?limit=200&dater=${dater}`,
  ADD_FRIEND: `/api/v1/users/friend/`,
  DELETE_FRIEND: (id) => `/api/v1/users/friend/${id}/`,
  EDIT_FRIEND: (id) => `/api/v1/users/friend/${id}/`,
  GET_FRIEND_BY_ID: (data) => `/api/v1/users/friend/${data.request_uid}/`,
  GET_MY_DATERS: `/api/v1/users/my-dater/?limit=200`,
  GET_ACTIVE_DATER: `/api/v1/users/active-dater/`,
  POST_ACTIVE_DATER: (friend_id) => `/api/v1/users/active-dater/${friend_id}/`,
  DATING_PREFERENCE_QUESTION_CHOICES: `/api/v1/dating/preference/questions/`,
  CUSTOM_DATING_PREFERENCE: `/api/v1/users/my-profile/custom-preference/`,
  DATING_PREFERENCE: `/api/v1/dating/preference/?limit=100`,
  EXPLORE_DATER_PROFILE_LIST: `/api/v1/dating/find-match/`,
  EXPLORE_DATER_PROFILE_LIST_BY_ID: (data) =>
    `/api/v1/dating/find-match/${data.id}/`,
  USER_PROFILE_BY_ID: (data) => `/api/v1/users/userprofile/${data.id}/`,
  ADD_PROSPECIVE_DATER: `/api/v1/users/prospective/add/dater/`,
  ADD_PROSPECTIVE_DATER_BY_ID: (data) =>
    `/api/v1/users/prospective/${data.user_id}/dater/`,
  PROSPECTIVE_DATER_VIEW_HISTORY: `/api/v1/home/view-history/`,
  GET_OTHER_FRIENDS_LIST: (data) => `/api/v1/users/friend/${data.dater_id}/`,
  REACH_OUT_FOR_ELBOW: `/api/v1/dating/elbow/`,
  GET_ELBOW_LIST: `/api/v1/dating/elbow/?limit=1000`,
  ELBOW_STATUS: (data) => `/api/v1/dating/elbow/${data.elbow_id}/`,
  GET_CONVOS_LIST: (data) => {
    if (data.friend_id) {
      return `/api/v1/dating/convo/?limit=1000&friend_id=${data.friend_id}`;
    } else {
      return `/api/v1/dating/convo/?limit=1000`;
    }
  },
  MAKE_INTROW: (data) => `/api/v1/dating/make-introw/${data.convo_id}/`,
  DECLINE_INTROW: (data) => `/api/v1/dating/end-introw/${data.convo_id}/`,
  CONVO_ACCEPT_DECLINE: (data) =>
    `/api/v1/dating/convo/member/${data.session_member_id}/`,
  CHATTER_BOX_PROSPECTIVE_DATER: (data) =>
    `/api/v1/users/prospective/dater/?chatterbox__user=${data.chatterbox__user}`,

  GET_CHATTERBOX_DETAILS_BY_USER_PROFILE_ID: (data) =>
    `/api/v1/users/userprofile/${data.profileID}/chatter-box/`,
  PROSPECTIVE_DATER_ACCEPTANCE_STATUS: (data) =>
    `/api/v1/users/prospective/dater/${data.prospective_dater_id}/`,

  POST_BLOCK_USER: (data) => `/api/v1/users/block-user/${data.user_id}/`,
  BLOCK_USER_STATUS: (data) =>
    `/api/v1/users/block-user/status/${data.user_id}/`,

  END_INTROW: (data) => `/api/v1/dating/end-introw/${data.convo_id}/`,
  GET_NOTIFCATION_LIST: `/api/v1/home/notification/`,
  PATCH_NOTIFICATION: (data) =>
    `/api/v1/home/notification/${data.notification_id}/`,
  REPORT_USER: '/api/v1/home/report-user/',
  FEEDBACK: '/api/v1/home/feedback/',
  REQUEST_COUNT: 'api/v1/dating/request-count/',
  SHARE_APP: 'api/v1/users/invite/',
  FRIEND_CONVO: (data) =>
    `/api/v1/dating/get-dater-convos/${data.friend_id_id}/`,

  FRIEND_CONVO: (data) =>
    `/api/v1/dating/get-dater-convos/?limit=1000&friend_id=${data.friend_id}`,

  DATER_REQUEST_INTROW: (data) => `/api/v1/dating/requested-introw/${data.id}/`,
  CLEAR_REQUEST_COUNT: (data) => `api/v1/dating/request-count/${data.request_id}/`,

};
