import React, { useState } from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import List from '@mui/material/List';
import ListItemCheckBox from '../../molecule/ListItemCheckbox';
import ListItemRadio from '../../molecule/ListItemRadio';
import { AnswerType } from '../../utils/constants';
import CheckBoxWithLabel from '../../atoms/CheckBoxWithLabel';
import { scaleByRatio } from '../../utils/helper';
import AppLogoMin from '../../assets/AppLogoMin.svg';

const Questionare = ({
  questionText,
  answerType,
  onBackClicked,
  handleOnNextClicked,
  error,
  answerOptions = [],
  showBackButton = true,
  questionColor = '#F46B1B',
  selectedAnswer = [],
  showVisibility = false,
  isVisible = false,
  onVisibleClick,
  checkBoxLabel = '',
  submiButtonLabel = 'Next',
  showSteps = false,
  currentStep,
  totalSteps,
  showAppIcon = true,
}) => {
  const [checked, setChecked] = useState(selectedAnswer);
  const handleAnswerChecked = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    console.log(newChecked);
    setChecked(newChecked);
  };
  const handleAnswerSingleChecked = (value) => {
    const newChecked = [];
    newChecked.push(value);
    console.log('handleAnswerSingleChecked:', newChecked);
    setChecked(newChecked);
  };
  const onNextClicked = () => {
    handleOnNextClicked(checked, answerType);
  };

  let listItemClass = {
    display: 'flex',
    height: `${scaleByRatio(48)}px`,
    padding: `${scaleByRatio(8)}px`,
    paddingLeft: '10px !important' ,
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: `${scaleByRatio(73)}px`,
    borderBottom: '1px solid #F6F7F7',
    
  };

  let textClass = {
    color: '#ffffff', // Ensure the color value is correctly prefixed with '#'
    fontFamily: 'Gill Sans Nova', // Update the font family to 'Gill Sans Nova'
    fontSize: '14px', // Update the font size to 14px
    fontWeight: '500', // Update the font weight to 600
    lineHeight: '20.08px', // Add the line height property with a value of 20.08px
    letterSpacing: '0.10rem', // Add the letter spacing property with a value of 10%
    textTransform: 'uppercase', // Add the text transform property with a value of 'uppercase
    paddingLeft: '10px'
  };

  return (
    <>
      {showAppIcon && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer} marginTop={4}>
            {/* <Typography textAlign='center' className={styles.logoContainer}> */}
            <img
              src={AppLogoMin}
              alt={'App icon'}
              className={styles.logo}
              style={{
                width: `90%`,
                marginBottom: '20px',
                height: `${scaleByRatio(110)}px`,
              }}
            />
            {/* </Typography> */}
          </Grid>
        </Grid>
      )}
      {showBackButton && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer}>
            <img
              src={ArrowLeft}
              alt={'Back icon'}
              className={styles.logo}
              onClick={onBackClicked}
              width={scaleByRatio(12)}
              height={scaleByRatio(12)}
            />
          </Grid>
          <Grid item>
            <Typography
              textAlign="left"
              className={styles.back}
              onClick={onBackClicked}
              style={{
                fontSize: `${scaleByRatio(12)}px`,
                paddingLeft: `${scaleByRatio(10)}px`,
                paddingBottom: `${scaleByRatio(2)}px`,
              }}>
              Back
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Grid item>
          <Typography
            textAlign="left"
            className={styles.question}
            style={{
              // color:`${questionColor}`,
              // color: '#fff',
              color: '#76d9ff',
              fontSize: `${scaleByRatio(22)}px`,
              lineHeight: `${scaleByRatio(28.64)}px`,
              letterSpacing: '0.06em', 
              marginLeft: '13px',
              marginRight: '15px',
              marginTop:"-10px",
              marginBottom:"-15px"
            }}>
            {questionText}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        {answerType === AnswerType.MC && (
          <List dense sx={{ width: '100%', color: '#fff' }}>
            {answerOptions.map((answer) => {
              return (
                <ListItemCheckBox
                  value={answer.id}
                  onChange={handleAnswerChecked}
                  isChecked={checked.indexOf(answer.id) !== -1}
                  id={answer.id}
                  displayText={answer.value}
                  listItemClass={listItemClass}
                  textClass={textClass}
                />
              );
            })}
          </List>
        )}
        {answerType === AnswerType.SC && (
          <List dense sx={{ width: '100%', color: '#fff' }}>
            {answerOptions.map((answer) => {
              return (
                // <ListItemCheckBox
                // value={answer.id}
                // onChange={handleAnswerSingleChecked}
                // isChecked={checked.indexOf(answer.id) !== -1}
                // id={answer.id}
                // displayText={answer.value}
                // listItemClass={listItemClass}
                // textClass={textClass}
                // />
                <ListItemRadio
                  value={answer.id}
                  onChange={handleAnswerSingleChecked}
                  isChecked={checked.indexOf(answer.id) !== -1}
                  id={answer.id}
                  displayText={answer.value}
                  listItemClass={listItemClass}
                  textClass={textClass}
                />
              );
            })}
          </List>
        )}
      </Grid>

      {showVisibility && (
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end">
          <CheckBoxWithLabel
            isChecked={isVisible}
            labelText={checkBoxLabel}
            handleChange={onVisibleClick}
          />
        </Grid>
      )}
      {error && error?.length > 0 && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Typography
            textAlign="left"
            className={styles.error}
            style={{
              fontSize: `${scaleByRatio(12)}px`,
              lineHeight: `${scaleByRatio(40)}px`,
            }}>
            {error}
          </Typography>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="end"
        alignItems="flex-end">
        <Grid item xs={6}>
          {showSteps && (
            <Typography
              textAlign={'left'}
              className={styles.pagination}
              style={{
                padding: `${scaleByRatio(12)}px ${scaleByRatio(32)}px`,
                fontSize: `${scaleByRatio(25)}px`,
                lineHeight: `${scaleByRatio(34)}px`,
              }}>
              {currentStep}/{totalSteps}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          justifyContent="right"
          style={{
            display: 'flex',
          }}>
          <CustomButton
            text={submiButtonLabel}
            variant={ButtonVariant.contained}
            type={ButtonType.primary}
            onClick={() => onNextClicked()}
            customStyle={`yes`}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Questionare;
