import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styles from './index.module.scss'
import { scaleByRatio } from '../../utils/helper';
import IconClose from '../../assets/IconClose.svg';



const CustomAlertDelete = ({
    isOpen=false,
    message='',
    heading='',
    onYesClick,
    onNoClick,
}) => {
    const [open, setOpen] = useState(isOpen);

  const handleCancel = () => {
    setOpen(false);
    onNoClick()
  };
  const handleAgree = ()=>{
    setOpen(false);
    onYesClick()
  }
    return (  
        <Dialog
        open={open}
        PaperProps={{
          style: {
            marginLeft:'10px',
            marginRight:'10px',
            width:'100%',
            maxWidth:'450px',
            backgroundColor: 'white',
            boxShadow: 'none',
            transform: 'translateY(-40px)'
          },
        }}
        BackdropProps={{
          style: {
            backgroundColor: 'transparent', // This disables the blur by making the backdrop transparent
          },
        }}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

<button
        onClick={handleCancel}
        className="close-icon-button"
        style={{
          position: 'absolute',
          top: '16px',
          right: '16px',
          background: 'none',
          border: 'none',
          padding: '0',
          cursor: 'pointer',
          zIndex: 1,
          width: '16px', // Width of the SVG icon
          height: '16px', // Height of the SVG icon
        }}
      >
        {/* SVG as background */}
        <img
        src={IconClose}
        alt="img np"
        />
      </button>

        <DialogTitle id="alert-dialog-title" className={styles.heading}
        style={{
            fontSize:`${scaleByRatio(22)}px`,
            lineHeight:`${scaleByRatio(38)}px`,
        }}>
          {heading}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"
          className={styles.message}
          style={{
            fontSize: `${scaleByRatio(14)}px`,
            lineHeight: `${scaleByRatio(20)}px`,
          }}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{
          marginBottom:'10px',
            justifyContent:"center",
            display: 'flex'
        }}>
        <Button onClick={handleCancel} autoFocus className={`${styles.buttonCancel} ${styles.primary} `}
        style={{
            fontSize:`${scaleByRatio(14)}px`,
            lineHeight:`${scaleByRatio(24)}px`
        }}
        >
            Cancel
        </Button>
        <Button onClick={handleAgree} className={`${styles.button} ${styles.secondary}`}
        style={{
            fontSize:`${scaleByRatio(14)}px`,
            lineHeight:`${scaleByRatio(24)}px`
        }}>
            Yes, Delete
        </Button>
        </DialogActions>
      </Dialog>
    );
}
 
export default CustomAlertDelete;