import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// import Link from "@mui/material/Link";
import { Box, Grid } from '@mui/material';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';
import AppBar from '@mui/material/AppBar';

const Footer = () => {
  return (
    <Box component="footer">
      <AppBar>
        <Typography variant="body2" align="center" className={styles.footer}>
        <hr style={{
          //marginBottom:'30px',
        //marginTop:'-44px',
        maxWidth:'92%'}} />
        <Grid container display={'flex'} 
        justifyContent={'center'} 
        paddingTop='18px'
        paddingBottom={'24px'}
        marginLeft={1.5}>
          <Link to={routeUrls.termsandconditions} className={styles.footerLink}>
            Terms & Conditions
          </Link>
          {/* {' | '} */}
          <Link to={routeUrls.privacy} className={styles.footerLink}>
            Privacy Policy
          </Link>
          {/* {' | '} */}
          <Link to={routeUrls.contactus} className={styles.footerLink}>
            Contact Us
          </Link>
          </Grid>
        </Typography>
      </AppBar>
    </Box>
  );
};

export default Footer;
