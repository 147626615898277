import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import { scaleByRatio } from '../../utils/helper';
import './styles.scss'

const ListItemCheckBox = ({
    value,
    onChange,
    isChecked,
    id,
    displayText,
    listItemClass = '',
    textClass = ''

}) => {
    return (
        <ListItem
            key={value}
            secondaryAction={
                <Checkbox
                    edge="end"
                    onChange={() => onChange(value)}
                    checked={isChecked}
                    sx={{
                        "& .MuiSvgIcon-root": { fontSize: scaleByRatio(28) }, color: "#F93",
                        '&.Mui-checked': {
                            color: "#F93",
                        },
                    }}
                />
            }
            sx={{ '&.MuiListItem-root': listItemClass }}
        >
            <ListItemText
                id={id}
                primary={displayText}
                // className={textClass}
                sx={{ "& .MuiTypography-root": textClass}}
            />
        </ListItem>
    );

}

export default ListItemCheckBox;