import React, { useState, useEffect } from 'react';
import styles from '../MyProfileFriendView/index.module.scss';

import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import CustomSwitch from '../../atoms/CustomSwitch';
import Profile_Avatar from '../../assets/Profile_Avatar.png';
import { scaleByRatio } from '../../utils/helper';

const MyProfileFriendView = ({
  email,
  phone_number,
  first_name,
  last_name,
  profile_pic,
  handleEditOnchange,
  description = '',
}) => {
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        columnSpacing={0.5}
        sx={{
          display: 'flex',
          padding: `${scaleByRatio(20)}px`,
          gap: '5px',
        }}
        xs={12}
        className={styles.friendProfileWrapper}>
        <Grid
          item
          justifyContent="right"
          style={{ position: 'relative' }}
          className={styles.profilepicContainer}>
          <Typography
            sx={{
              position: 'absolute',
              bottom: `${scaleByRatio(30)}px`,
              left: `${scaleByRatio(20)}px`,
              display: 'block',
            }}>
            <CustomSwitch
              switchWidth='130'
              txtSwitchOn={'Edit On'}
              txtSwitchOff={'Edit Off'}
              checked={false}
              handleChange={handleEditOnchange}
            />
          </Typography>

          <Typography textAlign="left" sx={{ display: { xs: 'block' } }}>
            <img
              src={profile_pic || Profile_Avatar}
              style={{borderRadius: '15px'}}
              alt="profile"
              className={styles.friendPic}
            />
          </Typography>
        </Grid>
        <Grid item className={styles.profileDataContainer}>
          <Grid container direction="column" style={{ gap: '10px' }}>
            <Grid item xs={12} alignItems="flex-start">
              <Typography
                fullwidth
                marginLeft={'10px'}
                textAlign="left"
                marginTop={'10px'}
                style={{ color: '#E66433' }}
                className={styles.subHeader}>
                Profile Information
              </Typography>
            </Grid>

            <Grid container direction="row" paddingLeft={'10px'} paddingRight={'10px'} marginTop={'20px'}>
              <Grid item xs={4} alignItems="flex-start">
                <Typography
                  fullwidth
                  textAlign="left"
                  className={styles.details}>
                  Name
                </Typography>
              </Grid>
              <Grid item xs={8} alignItems="flex-start">
                <Typography
                  fullwidth
                  textAlign="right"
                  className={styles.details}>
                  {first_name} {last_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction="row">
            <hr style={{width:'100%',  border: 'none', height: '0.5px' , backgroundColor: '#cccccc'}} />
            </Grid>

            <Grid container direction="row" paddingLeft={'10px'} paddingRight={'10px'}>
              <Grid item xs={4} alignItems="flex-start" >
                <Typography
                  fullwidth
                  textAlign="left"
                  className={styles.details}>
                  Phone Number
                </Typography>
              </Grid>
              <Grid item xs={8} alignItems="flex-start">
                <Typography
                  fullwidth
                  textAlign="right"
                  className={styles.details}>
                  {phone_number}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row">
            <hr style={{width:'100%',  border: 'none', height: '0.5px' , backgroundColor: '#cccccc'}} />
            </Grid>

            <Grid container direction="row" paddingLeft={'10px'} paddingRight={'10px'}>
              <Grid item xs={4} alignItems="flex-start">
                <Typography
                  fullwidth
                  textAlign="left"
                  className={styles.details}>
                  Email
                </Typography>
              </Grid>
              <Grid item xs={8} alignItems="flex-start">
                <Typography
                  fullwidth
                  textAlign="right"
                  className={styles.details}>
                  {email}
                </Typography>
              </Grid>
            </Grid>

            <Grid container direction="row">
            <hr style={{width:'100%',  border: 'none', height: '0.5px' , backgroundColor: '#cccccc'}} />
            </Grid>   

            <Grid container direction="row" paddingLeft={'10px'} paddingRight={'10px'}>
              <Grid item xs={4} alignItems="flex-start">
                <Typography
                  fullwidth
                  textAlign="left"
                  className={styles.details}>
                  Describe your friendship
                </Typography>
              </Grid>
              <Grid item xs={8} alignItems="flex-start">
                <Typography
                  fullwidth
                  textAlign="right"
                  className={styles.details}>
                  {description}
                </Typography>
              </Grid>
            </Grid>

            {/* <Grid container direction="row">
            <hr style={{width:'100%',  border: 'none', height: '0.5px' , backgroundColor: '#cccccc'}} />
            </Grid> */}
            
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MyProfileFriendView;
