import React, { useState } from 'react';
import styles from './index.module.scss';
import { Grid, ListItem, Radio } from '@mui/material';
import Typography from '@mui/material/Typography';
import AppLogoMin from '../../assets/AppLogoMin.svg';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import RadioSelected from '../../assets/radio-selected-icon.svg';
import RadioUnselected from '../../assets/radio-unselected-icon.svg';
import SingleChoiceSelectionButton from '../../atoms/SingleChoiceSelectionButton';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import CheckBoxWithLabel from '../../atoms/CheckBoxWithLabel';
import { scaleByRatio } from '../../utils/helper';
import ListItemRadio from '../../molecule/ListItemRadio';

const QuestionGender = ({
  displayText = '',
  onBackClicked,
  error,
  onNextClicked,
  answerChoices,
  selectedValue,
  onClickCallBack,
  showBackButton = true,
  showVisibility = false,
  isVisible = false,
  onVisibleClick,
  checkBoxLabel = '',
  showSteps = false,
  currentStep,
  totalSteps,
  showAppIcon = true,
}) => {
  const handleClick = (selectedID) => {
    console.log('handleClick: ', selectedID);
    onClickCallBack(selectedID);
  };
  const [isVisible2, setIsVisible2] = useState(false);
  const handleVisibleClick = () => {
    setIsVisible2((prevState) => {
      const newState = !prevState;
      if (onVisibleClick) {
        onVisibleClick(newState); // Pass the updated state
      }
      return newState;
    });
  };

  return (
    <>
      {showAppIcon && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end">
          <Grid
            item
            className={styles.logoContainer}
            marginTop={-8}
            justifyContent={'space-between'}>
            {/* <Typography textAlign='center' className={styles.logoContainer}> */}
            <img
              src={AppLogoMin}
              alt={'App icon'}
              className={styles.logo}
              style={{
                marginBottom: '30px',
                width: `90%`,
                height: `${scaleByRatio(110)}px`,
              }}
            />
            {/* </Typography> */}
          </Grid>
        </Grid>
      )}
      {showBackButton && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer}>
            <img
              src={ArrowLeft}
              alt={'Back icon'}
              className={styles.logo}
              onClick={onBackClicked}
              width={scaleByRatio(12)}
              height={scaleByRatio(12)}
            />
          </Grid>
          <Grid item>
            <Typography
              textAlign="left"
              className={styles.back}
              onClick={onBackClicked}
              style={{
                fontSize: `${scaleByRatio(12)}px`,
                paddingLeft: `${scaleByRatio(10)}px`,
                paddingBottom: `${scaleByRatio(2)}px`,
              }}>
              Back
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Grid item>
          <Typography
            textAlign="left"
            className={styles.description}
            style={{
              fontSize: `${scaleByRatio(22)}px`,
              lineHeight: `${scaleByRatio(28.64)}px`,
              letterSpacing: '0.06em',
              marginLeft: '15px',
            }}>
            {displayText}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={'center'}
        direction="row"
        className={styles.genderBtn}
        style={{ marginLeft: '15px' }}>
        {answerChoices &&
          answerChoices.map((choice) => {
            return (
              <Grid item justifyContent="left" alignItems="flex-start">
                <SingleChoiceSelectionButton
                  text={choice.value}
                  isSelected={selectedValue === choice.id}
                  onClick={() => handleClick(choice.id)}
                />
              </Grid>
            );
          })}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-end"></Grid>
      {showVisibility && (
        <Grid
          style={{ marginLeft: '15px' }}
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center">
          {/* <CheckBoxWithLabel
            isChecked={isVisible}
            labelText={checkBoxLabel}
            handleChange={onVisibleClick}
          /> */}
          <Grid item>
            <Radio
              edge="end"
              onClick={handleVisibleClick}
              checked={isVisible2}
              icon={
                <img
                  src={RadioUnselected}
                  alt="RadioUnselected"
                  style={{ height: '30px' }}
                />
              }
              checkedIcon={
                <img
                  src={RadioSelected}
                  alt="RadioSelected"
                  style={{ height: '30px' }}
                />
              }
              name="RadioButtons"
            />
          </Grid>
          <Grid marginLeft= '15px' item alignItems={'center'}> 
          <Typography className={styles.back}
          style={{
            fontSize: `${scaleByRatio(12)}px`,
          }}
          >{checkBoxLabel}</Typography></Grid>
        </Grid>
      )}

      {error && error?.length > 0 && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Typography
            textAlign="left"
            className={styles.error}
            style={{
              fontSize: `${scaleByRatio(12)}px`,
              lineHeight: `${scaleByRatio(40)}px`,
            }}>
            {error}
          </Typography>
        </Grid>
      )}
      <Grid container direction="row" alignItems="flex-end">
        <Grid item xs={6}>
          {showSteps && (
            <Typography
              textAlign={'left'}
              className={styles.pagination}
              style={{
                padding: `${scaleByRatio(12)}px ${scaleByRatio(32)}px`,
                fontSize: `${scaleByRatio(25)}px`,
                lineHeight: `${scaleByRatio(34)}px`,
              }}>
              {currentStep}/{totalSteps}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          justifyContent="right"
          style={{
            display: 'flex',
          }}>
          <CustomButton
            text={'Next'}
            variant={ButtonVariant.contained}
            type={ButtonType.primary}
            onClick={() => onNextClicked()}
            customStyle={`yes`}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default QuestionGender;
