import React, { useState, useEffect, useRef, useContext } from 'react';
import { useWindowSize } from '../../utils/useWindowSize';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import styles from './index.module.scss';
import {
  useGetPropectiveDater,
  useGetActiveDater,
  useGetChatterBoxDetailsByProfileID,
  useGetMyProfile,
  useGetRequestCount,
} from '../../services/mutations';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import Loader from '../../molecule/Loader';
import CustomAlert from '../../atoms/CustomAlert';
import { scaleByRatio, getTime } from '../../utils/helper';
import Profile_Avatar from '../../assets/Profile_Avatar.png';
import ChatAttachment from '../../assets/ChatAttachment.svg';
import ChatSend from '../../assets/ChatBoxSend.svg';
import { FormControl } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import PubNub from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';
import dayjs from 'dayjs';
import ChatterBoxProspectiveDaterProfile from '../ChatterBoxProspectiveDaterProfile';
import ImageGallery from '../../organism/ImageGallery';
import ImageCropper from '../../molecule/ImageCropper/ImageCropper';
import {
  ActiveDaterContext,
  FriendDaterId,
  GroupChatCloseContext,
  ReportConvoCounter,
  ReportElbowCounter,
} from '../../utils/Context/reportCounter';
import { APP_DRAWER_WIDTH } from '../../utils/constants';

const ChatterBox = ({ handleCurrentDater }) => {
  const localStorage = useLocalStorage();
  const pubnub = usePubNub();
  const inputImage = useRef(null);

  const getActiveDater = useGetActiveDater();

  console.log(
    'friendImg',
    localStorage.getLocalStorageItem('activeDaterImage'),
  );
  console.log('ProfileImg', localStorage.getLocalStorageItem('ProfileImage'));
  const getPropectiveDaterList = useGetPropectiveDater();
  const getChatterBoxDetailsByProfileID = useGetChatterBoxDetailsByProfileID();
  const getMyProfile = useGetMyProfile();
  // localStorage.setLocalStorageItem(
  //   'profileImage', getMyProfile?.data?.data?.payload?.profile_pic
  // );
  const [height, width] = useWindowSize();
  const [getwidth, setWidth] = useState(0);
  const [myProfileID] = useState(
    localStorage.getLocalStorageItem(localStorageKeys.profileId),
  );

  const [daterID, setDaterID] = useState(null);
  const [prospectiveDatersDetailsList, setProspectiveDatersDetailsList] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [currentMessage, setCurrentMessage] = useState('');
  const [currentFile, setCurrentFile] = useState(null);
  const [channels, setChannels] = useState([]);
  const [messages, addMessage] = useState([]);
  const [message, setMessage] = useState('');
  const [prospectiveDater, setProspectiveDater] = useState(null);
  const [showGallery, setShowGallery] = useState(false);
  const [arrGalleryImages, setArrGalleryImages] = useState([]);
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [fileUpload, setFileUpload] = useState({ imageUrl: null, flag: false });
  const [convoCount, setConvoCount] = useContext(ReportConvoCounter);
  const [elbowCount, setElbowCount] = useContext(ReportElbowCounter);
  const [friendDaterId,setFriendDaterId] = useContext(FriendDaterId);
  const [activeFriendDater,setActiveFriendDater]=useContext(ActiveDaterContext);
  const [groupChatClose, setGroupChatClose] = useContext(GroupChatCloseContext);
  const [checkCounter, setCheckCounter] = useState(0);
  const reportCounterData = useGetRequestCount();

  const handleMsgXs = () => {
    if (getwidth > 1000) {
      return 1;
    } else {
      return 2;
    }
  };

  const handleuserType = () => {
    if (localStorage.getLocalStorageItem('userType') === 'dater') {
      return 'Chat with my Friends';
    } else if (localStorage.getLocalStorageItem('userType') === 'friend') {
      return 'Group Chat';
    }
  };

  const getMyProfileDetails = () => {
    setLoading(true);
    getMyProfile.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log('getMyProfile onSuccess: ', response);
          setUserDetails(response?.data?.payload);
          setProfilePic(response?.data?.payload?.profile_pic || null);
          setLoading(false);
          fetchChatterBoxID();
        },
        onError: (err) => {
          console.log('getMyProfile onError: ', err);
          setLoading(false);
        },
      },
    );
  };
  const fetchChatterBoxID = () => {
    setLoading(true);
    let data = {
      profileID: daterID,
    };
    getChatterBoxDetailsByProfileID.mutate(data, {
      onSuccess: (response) => {
        let arr = [];
        arr.push(response?.data?.payload?.id);
        setChannels(arr);
        setLoading(false);
        //getMyProfileDetails()
      },
      onError: (error) => {
        setLoading(false);
      },
    });
  };
  const fetchActiveDaterOfFriend = () => {
    setLoading(true);
    getActiveDater.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log(
            'getActiveDaterProfileImg onSuccess: ',
            response?.data?.payload?.user?.profile_pic,
          );
          localStorage?.setLocalStorageItem(
            'activeDaterImage',
            response?.data?.payload?.user?.profile_pic,
          );
          localStorage?.setLocalStorageItem(
            'activeDaterId',
            response?.data?.payload?.user?.id,
          );
          setLoading(false);
          console.log('getActiveDater onSuccess: ', response);
          setActiveFriendDater(response?.data?.payload?.user);
          setFriendDaterId(response?.data?.payload?.user?.id)
          setDaterID(response?.data?.payload?.user?.id);
        },
        onError: (error) => {
          setLoading(false);
          console.log('getActiveDater onError: ', error);
        },
      },
    );
  };
  const fetchProspectiveDaters = () => {
    let data = {
      chatterbox__user: daterID,
    };
    setLoading(true);
    getPropectiveDaterList.mutate(data, {
      onSuccess: (response) => {
        console.log('getPropectiveDaterList success: ', response);
        setProspectiveDatersDetailsList(response?.data?.payload?.results);
        setLoading(false);

        getMyProfileDetails();
      },
      onError: (error) => {
        console.log('getPropectiveDaterList error: ', error);
        setLoading(false);
      },
    });
  };
  const handleCurrentMessageChange = (evt) => {
    setCurrentMessage(evt.target.value);
  };

  const reportCount = () => {
    reportCounterData.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log('reportCounterData success:', response);
          console.log(
            'convo count',
            response?.data?.payload?.pending_introw_request,
          );

          setConvoCount(response?.data?.payload?.results[0]?.chat_with_dater_count);
          localStorage.setLocalStorageItem('counterID', response?.data?.payload?.results[0]?.id);
          console.log(
            'elbow count',
            response?.data?.payload?.pending_elbow_request,
          );
          setElbowCount(response?.data?.payload?.results[0]?.reach_out_count);
        },
      },
      // onError: (err) => {
      //   console.log('getMyProfile onError: ', err);
      //   setLoading(false);
      // }
    );
  };
  const sendMessage = () => {
    if (currentMessage.trim().length > 0) {
      let message = {
        text: currentMessage,
        user: {
          name: userDetails.user?.first_name,
          profilePic: profilePic,
        },
      };
      if (message) {
        pubnub
          .publish({ channel: channels[0], message })
          .then(() => setCurrentMessage(''));
      }
    }
  };
  const sendFile = async (_file) => {
    console.log('sendFile::', _file);

    let message = {
      channel: channels[0],
      message: {
        user: {
          name: userDetails.user?.first_name,
          profilePic: profilePic,
        },
        type: 'image',
      },
      file: _file,
    };
    console.log('send file message: ', message);
    let result = await pubnub.sendFile(message);
    console.log('send file result: ', result);
  };
  const handleMessage = (event) => {
    const ev_message = event.message;
    console.log('handleMessage event: ', event);
    let message = {
      ownerProfileID: event.publisher,
      profile_pic: ev_message?.user?.profilePic,
      message: ev_message?.text,
      time: getTime(parseInt(event.timetoken)),
      file: null,
    };
    if (message) {
      addMessage((messages) => [...messages, message]);
    }
  };
  const handleFiles = (event) => {
    console.log('pubnub handleFiles: ', event.file);
    console.log('pubnub handleFiles publisher: ', event.publisher);
    console.log('pubnub handleFiles event: ', event);
    let message = {
      profile_pic: event?.message?.user?.profilePic,
      message: '',
      time: getTime(parseInt(event.timetoken)),
      ownerProfileID: event?.publisher,
      file: {
        id: event?.file?.id,
        name: event?.file?.name,
        url: event?.file?.url,
      },
    };
    addMessage((messages) => [...messages, message]);
  };
  const handleInputImage = (e) => {
    console.log('handleInputImage::', e.target.files);
    if (e.target.files.length > 0) {
      setShowImageCropper(true);
      setFileUpload({
        imageUrl: URL.createObjectURL(e.target.files[0]),
        flag: true,
      });

      setCurrentFile(e.target.files[0]);
      //sendFile(e.target.files[0])
    }
  };
  const callBackImageCropped = (imageFile) => {
    setShowImageCropper(false);
    setCurrentFile(imageFile);
    console.log('callBackImageCropped::', imageFile);
    sendFile(imageFile);
    setFileUpload({
      imageUrl: URL.createObjectURL(imageFile),
      flag: true,
    });
  };
  const callBackImageNotCropped = () => {
    setShowImageCropper(false);
    sendFile(currentFile);
    console.log('callBackImageNotCropped');
  };
  const handleCloseModalImageCropper = () => {
    setShowImageCropper(false);
    setFileUpload({ imageUrl: null, flag: false });
    console.log('handleCloseModalImageCropper');
  };
  const handleProfileClicked = (dater) => {
    setProspectiveDater(dater);
  };
  const onProspectiveDaterProfileBackClicked = () => {
    setProspectiveDater(null);
    setDaterID(null);
  };
  const prepareHistoricalMessage = (channelMessageArray) => {
    console.log('prepareHistoricalMessage: ', channelMessageArray);
    let arr = [];
    if (channelMessageArray && channelMessageArray.length > 0) {
      channelMessageArray.forEach((messageElem) => {
        let profile_Pic = null;
        if (messageElem?.uuid === myProfileID) {
          profile_Pic = profilePic;
        } else {
          if (prospectiveDatersDetailsList?.length > 0) {
            let index = prospectiveDatersDetailsList.findIndex(
              (dater) => dater?.user?.id === messageElem?.uuid,
            );
            if (index > -1) {
              profile_Pic =
                prospectiveDatersDetailsList[index]?.user?.profile_pic;
            }
          }
        }
        if (messageElem?.message?.message?.type !== 'image') {
          let message = {
            ownerProfileID: messageElem?.uuid,
            profile_pic: messageElem?.message?.user?.profilePic,
            message: messageElem?.message?.text,
            time: getTime(parseInt(messageElem.timetoken)),
            file: null,
          };
          arr.push(message);
        } else {
          console.log('File message: ', messageElem);
          let channel = messageElem?.channel;
          let file_id = messageElem?.message?.file?.id;
          let file_name = messageElem?.message?.file?.name;
          const file_url = pubnub.getFileUrl({
            channel: channel,
            id: file_id,
            name: file_name,
          });
          console.log('file url: ', file_url);
          let fileMessage = {
            profile_pic: messageElem?.message?.message?.user?.profilePic,
            message: '',
            time: getTime(parseInt(messageElem.timetoken)),
            ownerProfileID: messageElem?.uuid,
            file: {
              id: file_id,
              name: file_name,
              url: file_url,
            },
          };
          arr.push(fileMessage);
        }
      });
      console.log('time: ', arr);
      addMessage(arr);
    }
  };
  const showImagePreview = (image) => {
    let arr = [];
    console.log(image);
    if (image) {
      let data = {
        label: '',
        image: image,
      };
      arr.push(data);
      setArrGalleryImages(arr);
    }
  };

  useEffect(() => {
    if (channels.length > 0) {
      const listenerParams = { message: handleMessage };
      const fileListnerParams = { file: handleFiles };
      pubnub.addListener(listenerParams);
      pubnub.addListener(fileListnerParams);
      pubnub.subscribe({ channels });

      let currentToken = (
        Math.floor(new Date('2023-10-01').getTime() / 1000) * 10000000
      ).toString();
      pubnub.fetchMessages(
        {
          channels: channels,
          end: currentToken, //'16970822938689523',
          count: 100,
        },
        function (status, response) {
          console.log('>>>>>>>>>>>> Historical message start');
          console.log(status, response);
          if (status?.statusCode === 200) {
            prepareHistoricalMessage(response.channels[channels[0]] || []);
          }

          console.log('>>>>>>>>>>>> Historical message end');
        },
      );
    }
    return () => {
      pubnub.unsubscribe({ channels });
      pubnub.removeListener({ message: handleMessage });
      pubnub.removeListener({ file: handleFiles });
    };
  }, [pubnub, channels]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window?.innerWidth);
    }
  }, [width]);

  useEffect(() => {
    if (daterID) {
      fetchProspectiveDaters();
    } else {
      if (
        localStorage.getLocalStorageItem(localStorageKeys.userType) ===
        localStorageUserType.dater
      ) {
        setDaterID(
          localStorage.getLocalStorageItem(localStorageKeys.profileId),
        );
      } else {
        fetchActiveDaterOfFriend();
      }
    }
  }, [daterID]);

  useEffect(() => {
    setGroupChatClose(false);
    setCheckCounter(1);
    if (
      localStorage.getLocalStorageItem(localStorageKeys.userType) ===
      localStorageUserType.dater
    ) {
      setDaterID(localStorage.getLocalStorageItem(localStorageKeys.profileId));
    } else {
      fetchActiveDaterOfFriend();
    }
  }, []);
  useEffect(() => {
    console.log('counter');
    // loadInitialdata();
    reportCount();
  }, []);

  useEffect(() => { 
    if(groupChatClose && checkCounter==1){
      // onBackClickedData()
      onProspectiveDaterProfileBackClicked()
    }
  }, [groupChatClose,checkCounter]);

  useEffect(() => {
    console.log('arrGalleryImages: ', arrGalleryImages);
    if (arrGalleryImages.length > 0) {
      setShowGallery(true);
    }
  }, [arrGalleryImages]);

  useEffect(() => {
    // if (getActiveDater?.data?.data?.payload?.user?.profile_pic) {
    handleCurrentDater();
    // }
  }, []);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 700);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 700);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  return (
    <>
      <Loader open={loading} />
      {!loading && (
     <Box
     component="main"
     backgroundColor="#000"
     sx={{
       flexGrow: 1,
       pb: 3,
       pt: scaleByRatio(3),
       marginTop: `${isWideScreen ? scaleByRatio(100) : scaleByRatio(70)}px`, // Adjust marginTop based on screen width
     }}
   >
          {!prospectiveDater && (
            <>

            <Grid
            direction={'column'}
            display={'flex'}
            >

           
              <Grid
                container
                direction="row"
                alignItems="flex-end"
                sx={{ minHeight: '50px',
                marginBottom:'-5px',
                 paddingBottom: '10px' }}>
                <Grid item xs={12} justifyContent="center">
                  <Typography
                    textAlign="center"
                    className={styles.header}
                    style={{
                      lineHeight: `${scaleByRatio(29)}px`,
                      color: '#E66433',
                    }}>
                    {/* Chatterbox */}
                    {handleuserType()}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                xs={12}
                direction="row"
                justifyContent="left"
                sx={{
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  msOverflowStyle: 'none',  // IE and Edge
                  scrollbarWidth: 'none',  // Firefox
                }}
                alignItems="flex-end">
                <Grid
                  className={styles.chatterboxHorizontalFriendsScroller}
                  item
                  xs={12}
                  justifyContent="left"
                  // className={styles.imgList}
                  style={{
                    // maxWidth: `83vw`,`1
                    // maxWidth: `calc(100vw - ${APP_DRAWER_WIDTH}px)`,
                   // minHeight: `${scaleByRatio(180)}px`,
                  }}>
                  {!loading && prospectiveDatersDetailsList.length > 0 && (
                    <ImageList
                      style={{
                        gridAutoFlow: 'column',
                        gridTemplateColumns: 'none',
                        // gridAutoColumns: "minmax(180px, 1fr)",
                        marginLeft: `20px`,
                        justifyContent: 'left',
                        overflowY: 'hidden',
                        overflowX: 'auto',
                        '&::-webkit-scrollbar': {
                          display: 'none',
                        },
                        msOverflowStyle: 'none',  // IE and Edge
                        scrollbarWidth: 'none',  // Firefox
                      }}
                      className={styles.chatterboxImgListing}>
                      {prospectiveDatersDetailsList.map((prospectiveDater) => {
                        return (
                          <>
                            <ImageListItem
                              style={{
                                width: `${scaleByRatio(70)}px`,
                                height: `${scaleByRatio(100)}px`,
                                //marginRight: '10px'
                              }}
                              onClick={() => {
                                handleProfileClicked(prospectiveDater);
                              }}>
                              <img
                                src={
                                  prospectiveDater?.user?.profile_pic ||
                                  Profile_Avatar
                                }
                                alt={'Prospective Dater'}
                                style={{
                                  width: `${scaleByRatio(70)}px`,
                                  height: `${scaleByRatio(70)}px`,
                                  minHeight: `${scaleByRatio(70)}px`,
                                  borderRadius: `50%`,
                                  // border: `2px solid ${
                                  //   prospectiveDater?.interest === 'Y'
                                  //     ? '#02E242'
                                  //     : '#F90'
                                  // }`,
                                }}
                              />
                              <ImageListItemBar
                                title={
                                  <span styles={styles.prospectiveDaterName}>
                                  {prospectiveDater?.user?.user?.first_name  } 
                                  </span>                       
                                }
                                subtitle= {
                                  <span styles={styles.prospectiveDaterAge}>
                                  {prospectiveDater?.user?.age}
                                  </span>
                                }
                                className={styles.prospectiveDaterName}
                                sx={{
                                  "& .MuiImageListItemBar-title": { 
                                    color:'white',
                                    fontWeight:600
                                   }, //styles for title
                                  "& .MuiImageListItemBar-subtitle": {  color:'#cccccc',
                                  fontWeight:500}, //styles for subtitle
                                }}
                                position="below"
                              />
                            </ImageListItem>
                          </>
                        );
                      })}
                    </ImageList>
                  )}
                </Grid>
              </Grid>

              <hr style={{ marginLeft: '20px', marginRight: '20px' , border: 'none', height: '0.5px' , marginBottom:'10px', backgroundColor: '#cccccc', width: 'calc(100% - 40px)'}} />

              <Grid
                container
                xs={12}
                direction="row"
                alignContent={'start'}
                style={{
                  flex: 1, 
                  minHeight: 'calc(100vh - 443px)',
                  maxHeight: 'calc(100vh - 443px)',
                  overflowY: 'auto',
                  // marginBottom:'120px',
                  padding: `5px 10px 70px 10px`,
                  flexShrink: 0,
                  display: `flex`,
                }}>
                {messages &&
                  messages.length > 0 &&
                  messages.map((chat) => {
                    return (
                      <Grid container xs={12}>
                        {/* {chat.ownerProfileID !== myProfileID && (
                          <Grid
                            item
                            xs={1}
                            justifyContent="right"
                            alignItems={'flex-end'}
                            style={{
                              display: `flex`,
                              // height: `${scaleByRatio(70)}px`,
                              // padding: `${scaleByRatio(5)}px`,
                            }}>
                            <img
                              src={chat.profile_pic || Profile_Avatar}
                              alt={''}
                              className={styles.profilePic}
                              style={{
                                borderRadius: '4px',
                                border: `2px solid #E5E5E5`,
                                // marginLeft: `${scaleByRatio(-3)}px`,
                              }}
                            />
                          </Grid>
                        )} */}
                        {chat.ownerProfileID === myProfileID && (
                          <Grid
                            item
                            xs={1}
                            justifyContent="left"
                            alignItems={'flex-end'}></Grid>
                        )}
                        <Grid
                          item
                          xs={12}
                          justifyContent={
                            chat.ownerProfileID !== myProfileID
                              ? 'left'
                              : 'right'
                          }
                          alignContent={'start'}
                          style={{
                            display: 'flex',
                          }}>
                          <Typography
                            fullWidth
                            textAlign={
                              chat.ownerProfileID !== myProfileID
                                ? 'left'
                                : 'right'
                            }>
                            <Typography
                              textAlign={
                                chat.ownerProfileID !== myProfileID
                                  ? 'left'
                                  : 'right'
                              }
                              className={styles.message}
                              style={{
                                padding: `${scaleByRatio(15)}px`,
                                paddingRight: `${scaleByRatio(20)}px`,
                                gap: `${scaleByRatio(10)}px`,
                              paddingRight: `${scaleByRatio(30)}px`,
                              paddingLeft: `${scaleByRatio(24)}px`,
                              paddingBottom: `${scaleByRatio(15)}px`,
                              marginBottom: '10px',
                              color:`${
                                chat.ownerProfileID !== myProfileID
                                  ? '#000000'
                                  : '#cccccc'
                              }`,
                                backgroundColor: `${
                                  chat.ownerProfileID !== myProfileID
                                    ? '#CCCCCC'
                                    : '#EB4121'
                                }`,
                                borderRadius: '22px',
                                // minWidth: `${scaleByRatio(300)}px`,
                                textAlign: `${
                                  chat.ownerProfileID !== myProfileID
                                    ? 'left'
                                    : 'right'
                                }`,
                                // color: `${
                                //   chat.ownerProfileID == myProfileID && '#fff'
                                // }`,
                              }}>
                              {chat.file && (
                                <>
                                  <img
                                    src={chat?.file?.url}
                                    alt={''}
                                    width={scaleByRatio(100)}
                                    style={{
                                      cursor: 'pointer',
                                    }}
                                    onClick={() =>
                                      showImagePreview(chat?.file?.url)
                                    }
                                  />
                                </>
                              )}
                              {!chat.file && <>{chat.message}</>}
                            </Typography>
                            <Typography
                              textAlign={
                                chat.ownerProfileID !== myProfileID
                                  ? 'left'
                                  : 'right'
                              }
                              className={styles.time}
                              style={{
                                fontSize: `${scaleByRatio(12)}px`,
                                marginBottom: '10px',
                                marginLeft: '10px',
                                marginRight:'10px',
                                textAlign: `${
                                  chat.ownerProfileID !== myProfileID
                                    ? 'left'
                                    : 'right'
                                }`,
                              }}>
                              {chat.time}
                            </Typography>
                          </Typography>
                        </Grid>
                        {/* {chat.ownerProfileID === myProfileID && (
                          <Grid
                            item
                            xs={1}
                            justifyContent="left"
                            alignItems={'flex-end'}
                            style={{
                              display: `flex`,
                              // height: `${
                              //   chat.file ? scaleByRatio(120) : scaleByRatio(70)
                              // }px`,
                              // padding: `${scaleByRatio(5)}px`,
                            }}>
                            <img
                              src={chat.profile_pic || Profile_Avatar}
                              alt={chat.name}
                              className={styles.profilePic}
                              style={{
                                borderRadius: '4px',
                                border: `2px solid #E5E5E5`,
                                // marginLeft: `${scaleByRatio(-3)}px`,
                              }}
                            />
                          </Grid>
                        )} */}
                        {chat.ownerProfileID !== myProfileID && (
                          <Grid
                            item
                            xs={1}
                            justifyContent="left"
                            alignItems={'flex-end'}></Grid>
                        )}
                      </Grid>
                    );
                  })}
              </Grid>
              <Grid
                container
                xs={12}
                direction="row"
                marginBottom='2px'

                className={styles.msgContainer}>
                <Grid
                  item
                  xs={1}
                  justifyContent="right"
                  alignItems={'center'}
                  style={{
                    display: `flex`,
                    padding: `${scaleByRatio(2)}px`,
                  }}>
                  <img
                    src={ChatAttachment}
                    alt={'send attachment'}
                    style={{
                      width: `${scaleByRatio(40)}px`,
                      height: `${scaleByRatio(40)}px`,
                      paddingRight: `${scaleByRatio(0)}px`,
                      cursor: `pointer`,
                    }}
                    onClick={() => {
                      inputImage.current.click();
                    }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    ref={inputImage}
                    onChange={handleInputImage}
                    onClick={(evt) => {
                      evt.target.value = null;
                    }}
                  />
                  {showImageCropper && (
                    <ImageCropper
                      ImageURL={fileUpload?.imageUrl}
                      callBackImageCropped={callBackImageCropped}
                      callBackImageNotCropped={callBackImageNotCropped}
                      handleCloseModalCallBack={handleCloseModalImageCropper}
                    />
                  )}
                </Grid>
                <Grid
                  item
                  // xs={getwidth > 1000 ? 1 : 2}
                  xs={handleMsgXs()}
                  alignItems={'center'}
                  style={{
                    display: `flex`,
                    padding: `${scaleByRatio(5)}px`,
                    justifyContent: 'center',
                  }}>
                  <Typography
                    textAlign={'center'}
                    className={styles.writeamessage}
                    style={{
                      fontSize: `${scaleByRatio(16)}px`,
                    }}>
                    Write a message
                  </Typography>
                </Grid>
                <Grid
                  xs={getwidth > 1000 ? 8 : 8}
                  item
                  justifyContent="right"
                  alignItems={'center'}
                  style={{
                    display: `flex`,
                    padding: `${scaleByRatio(2)}px`,
                  }}
                  className={styles.msgInput}>
                  <FormControl variant="filled" fullWidth>
                    <InputBase
                      id={'CurrentMessage'}
                      value={currentMessage}
                      onChange={(evt) => handleCurrentMessageChange(evt)}
                      type={'text'}
                      className={`${styles.inputField}`}
                      //style={{minHeight:`${minHeightPX}px`}}
                      multiline={false}
                      rows={1}
                      style={{
                        fontFamily: 'Gill Sans Nova',
                        borderRadius: '70px',
                        padding: `${scaleByRatio(3)}px`,
                        paddingLeft: `${scaleByRatio(20)}px`,
                        height: `${scaleByRatio(36)}px`,
                        fontSize: `${scaleByRatio(16)}px`,
                        // width: `${scaleByRatio(200)}px`,
                      }}
                      onKeyPress={(e) => {
                        if (e.key !== 'Enter') return;
                        sendMessage();
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={1}
                  justifyContent="left"
                  alignItems={'center'}
                  style={{
                    display: `flex`,
                    padding: `${scaleByRatio(2)}px`,
                  }}>
                  <img
                    src={ChatSend}
                    alt={'send attachment'}
                    style={{
                      width: `${scaleByRatio(32)}px`,
                      height: `${scaleByRatio(32)}px`,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      sendMessage();
                    }}
                  />
                </Grid>
              </Grid>

              </Grid>

            </>
          )}
          {prospectiveDater && (
            <ChatterBoxProspectiveDaterProfile
              onBackClicked={onProspectiveDaterProfileBackClicked}
              handleButtonClickedCallback={() => {}}
              dater={prospectiveDater}
              activeDaterID={daterID}
            />
          )}
        </Box>
      )}
      {showGallery && (
        <ImageGallery
          images={arrGalleryImages}
          showGallery={showGallery}
          onCloseCallBack={setShowGallery}
        />
      )}

    </>
  );
};

export default ChatterBox;
