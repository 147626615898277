import React, {useState, useEffect} from 'react'
import styles from './index.module.scss'
import {
    useUpdateMyProfile,
    useGetMyProfile, 
} from '../../services/mutations'
import {localStorageKeys, useLocalStorage} from '../../utils/localStorageItems'
import ProfileDataDOB from '../ProfileDataDOB'
import UploadPhotos from '../UploadPhotos'
import dayjs from 'dayjs';
import Loader from '../../molecule/Loader'
import ProfileDataName from '../../organism/ProfileDataName'


const MyProfileUpdatProfile = ({
    QuestionID,
    identifier,
    allQuestions,
    allAnwers,
    setEditMode,
    userDetails
}) => {
    const [dob, setDob] = useState('')
    const [age, setAge] = useState(0)
    const [error, setError] = useState('')
    const [formattedDob, setFormattedDob] = useState(null)
    const updateMyProfile = useUpdateMyProfile();
    const getMyProfile = useGetMyProfile();
    const localStorageItem = useLocalStorage();
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    function calculate_age(dob) { 
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms); 
      
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }
    const onDateChange = (_date)=>{
        console.log('onDateChange: ', _date)
        console.log('onchange formatted date:', new Date(_date))
        
        setDob(_date)
        setAge(calculate_age(new Date(_date)))
    }
    const onSubmitClickedDOB = () =>{
        if(!dob){
            setError('Please provide your age.')
            return false
        }else if(dob<18){
            setError('Your age should be greater than 18.')
            return false
        }else{
            if(dob){
               
                let payloadData = {}
                payloadData.age = dob
                updateProfile(payloadData)
            }
        }
    }
    const updateProfile = (data) => {
        console.log('updateProfile payloadData: ', data)
        setLoading(true)
        updateMyProfile.mutate(data,{
            onSuccess: response => {
                console.log('updateMyProfile onSuccess: ', response)
                setLoading(false)
                onBackClicked();
            },
            onError: err =>{
                console.log('updateMyProfile onError: ', err)
                setLoading(false)
            }
        })
    }
    const profileImagesUploadSuccess = ()=>{
        console.log('profileImagesUploadSuccess')
        onBackClicked()
    }

    const onBackClicked = ()=>{
        setEditMode(false)
    }

    const onFirstNameChange = (_name)=>{
        setFirstName(_name)
        if(_name === ''){
            setError('Please enter your First Name')
           
        }else{
            setError('')
            
        }
    }

    const onLastNameChange = (_name)=>{
        setError('')
        setLastName(_name)
    
    }
    const onNextNameClicked = ()=>{
        setError('')
        if(firstName.length === 0){
            setError('Please enter your First Name.')
        }else if(lastName.length === 0){
            setError('Please enter your Last Name.')
        }else{
            setError('')
            let payloadData = {
                user:{
                    first_name:firstName,
                    last_name:lastName
                }
            }
            // payloadData.user.first_name = firstName
            // payloadData.user.last_name = lastName
            updateProfile(payloadData)
            
            
        }
    }
    useEffect(()=>{
        if(userDetails?.age){
            console.log('userDetails: ', userDetails)
            setDob(userDetails?.age)
            setAge(calculate_age(new Date(userDetails?.dob)))
            setFirstName(userDetails?.user?.first_name)
            setLastName(userDetails?.user?.last_name)
        }
    },[])

    return ( 
        <>
        <Loader open={loading}/>
            {identifier==='age' && (
                <ProfileDataDOB
                dob={dob}
                onDateChange={onDateChange}
                onNextClicked={onSubmitClickedDOB}
                error={error}
                onBackClicked={onBackClicked}
                age={age}
                submitBtnText={'Save'}
                />
            )}
            {identifier==='photos' && (
                <UploadPhotos
                    setShowLoading={setLoading}
                    onBackClicked={onBackClicked}
                    btnSubmitText={'Save'}
                    onUploadSuccess={profileImagesUploadSuccess}
                    showbackButton={true}
                    
                />
            )}
            {identifier==='name' && (
                <ProfileDataName
                first_name={firstName}
                last_name={lastName}
                onFirstNameChange={onFirstNameChange}
                onLastNameChange={onLastNameChange}
                onNextClicked={onNextNameClicked}
                error={error}
                onBackClicked={onBackClicked}
                submitBtnText={'Save'}
                showAppIcon={false}
                />
            )}
        </>
     );
}
 
export default MyProfileUpdatProfile;