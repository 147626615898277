import styles from './index.module.scss';
import { Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import dater from '../../assets/dater.svg';
import friend from '../../assets/friend.svg';
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout';
import CentralGridLayout from '../../Layout/CentralGridLayout';
import { useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';
import { scaleByRatio } from '../../utils/helper';
import AppLogoLarge from '../../assets/AppLogoLarge.svg';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import { CustomButton } from '../../atoms/CustomButton';

const Join = () => {
  const nav = useNavigate();
  const localStorageItem = useLocalStorage();
  return (
    <Grid className={styles.centralLayout} >
      <CentralContentPageLayout>
        <CentralGridLayout top={'39%'}>
          <Grid container justifyContent={'center'}  direction="column"
           //marginTop='-20px'
           >
            <Grid marginTop={-14} paddingBottom={14} container justifyContent="center"  >
              <img
                src={AppLogoLarge}
                alt={'App icon'}
                className={styles.logo}
                style={{ height: `${scaleByRatio(100)}px` }}
              />
            </Grid>
            <Grid item className={styles.joinHeader}>
              Who you are?
            </Grid>
            <Grid container display={'flex'} justifyContent={'center'} gap={3}>
              <Grid className={styles.joinSubHeader} textAlign={'left'}>LOOKING FOR LOVE?</Grid>
              <Grid className={styles.joinSubHeaderOr}>OR</Grid>
              <Grid className={styles.joinSubHeader} textAlign={'right'}>HERE TO HELP AS A</Grid>
            </Grid>
            <Grid container justifyContent={'center'} gap={2.5} marginTop={4}>
              <Grid>
                <Button
                  className={styles.daterButton}
                  onClick={() => {
                    localStorageItem.setLocalStorageItem(
                      localStorageKeys.userType,
                      localStorageUserType.dater,
                    );
                    nav(routeUrls.start);
                  }}>
                  Dater
                </Button>{' '}
              </Grid>
              <Button
                className={styles.friendButton}
                onClick={() => {
                  localStorageItem.setLocalStorageItem(
                    localStorageKeys.userType,
                    localStorageUserType.friend,
                  );
                  nav(routeUrls.start);
                }}>
                Friend
              </Button>{' '}
            </Grid>
          </Grid>
        </CentralGridLayout>
      </CentralContentPageLayout>
    </Grid>
  );
};

export default Join;
