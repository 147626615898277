import React, { createContext } from 'react';

// Create a new context
export const ReportElbowCounter = createContext();

// create a new context with elbowCounter name
export const ReportConvoCounter = createContext();

//create a new context with ReachOutContext name
export const ReachOutContext = createContext();

//create a new context with activeDaterContext name 
export const ActiveDaterContext = createContext();

//create a new context with FriendDaterId name
export const FriendDaterId = createContext();

//create a new context with DaterProfileBackRoute name
export const DaterProfileBackRoute = createContext();
//Create a new context with ChatClose name
export const ChatClose = createContext();

export const GroupChatCloseContext = createContext();

export const ExploreCloseContext = createContext();