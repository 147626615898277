import React from 'react';
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout';
import CentralGridLayout from '../../Layout/CentralGridLayout';
import { Box, Button, Grid, Typography } from '@mui/material';
import styles from './index.module.scss';
import { scaleByRatio } from '../../utils/helper';
import { CustomButton } from '../../atoms/CustomButton';
import { useNavigate } from 'react-router-dom';
import { HomeMenuConstant, routeUrls } from '../../utils/constants';

import close_icon from '../../assets/close_icon.svg';

const Logout = ({ handleMenuClicked }) => {
  const nav = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    nav(routeUrls.landingPage);
    localStorage?.removeItem('setEdit');
    localStorage.removeItem('questionEdit');
  };

  const handleClose = () => {
    nav(routeUrls.profile);
    window.history.back();
  };

  const handleLeftMenuClicked = (menu) => {
    handleMenuClicked(menu);
    // nav(routeUrls.logout);
  };
  return (
    <Box>
      <Grid
        display={'flex'}
        justifyContent={'flex-end'}
        color={'#fff'}
        marginTop={'110px'}
        marginRight={'20px'}
        style={{ cursor: 'pointer', fontWeight: 'bold' }}
        onClick={handleClose}
        className={styles.closeIcon}>
        <Typography>
          <img src={close_icon} />
        </Typography>
      </Grid>
      <Grid container justifyContent={'center'}>
        <CentralGridLayout>
          <Grid>

          

            <Grid
              item
              xs={12}
              m={2}
              // display={'flex'}
              style={{ alignItems: 'center' }}>


              <Grid
                item
                display={'flex'}
                justifyContent={'center'}
                mt={4}
                marginBottom={2}
                paddingLeft={0}
                style={{ margin: 'auto' }}>
                <Typography
                  textAlign="center"
                  alignItems="center"
                  className={styles.description}
                  style={{
                    fontSize: `${scaleByRatio(32)}px`,
                    //   lineHeight: `${scaleByRatio(38)}px`,
                    alignItems: 'center',
                  }}>
                  Log out
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                textAlign="center"
                alignItems="center"
                m={1}
                className={styles.logoutDesc}
                marginTop="20px">
                <Typography
                  style={{
                    fontWeight: '600',
                    fontSize: '12px',
                    lineHeight: '16px',
                    letterSpacing: '0.1em',
                  }}>
                  <p>Are you sure you want to Logout? </p>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              justifyContent="space-around"
              item
              gap={3}
              // textAlign="center"
              // alignItems="center"
              m={2}
              display={'flex'}
              style={{ alignItems: 'center' }}>
              <Grid
                item
                xs={4}
                // justifyContent="flex-start"
                style={{ cursor: 'pointer' }}
                marginLeft={2}
                onClick={() =>
                  handleLeftMenuClicked(HomeMenuConstant.MyProfile)
                }>
                <Button className={styles.cancelTxt}>Cancel</Button>
              </Grid>
              <Grid item xs={8} paddingLeft={2} display={'flex'}>
                {/* <CustomButton
                  text={' Yes, Logout'}
                  onClick={handleLogout} 
                  style={{
                    fontSize: `${scaleByRatio(22)}px`,
                    fontWeight: '400',
                  }}
                /> */}
                <Button onClick={handleLogout} className={styles.logoutBtn}>
                  Yes, Logout
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </CentralGridLayout>
      </Grid>
    </Box>
  );
};

export default Logout;
