import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import InputField from '../../atoms/InputField';
import { CustomButton } from '../../atoms/CustomButton';
import styles from './index.module.scss';
import { scaleByRatio } from '../../utils/helper';
import {
  useLocalStorage,
  localStorageKeys,
  localStorageUserType,
} from '../../utils/localStorageItems';
import { useGetMyProfile, usePostFeedback } from '../../services/mutations';
import Loader from '../../molecule/Loader';
import CustomAlert from '../../atoms/CustomAlert';
import { routeUrls } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import close_icon from '../../assets/close_icon.svg';

const Support = () => {
  // const localStorageItem = useLocalStorage();
  // const userEmail = localStorageItem.getLocalStorageItem(
  //   localStorageKeys.userEmail,
  // );

  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [alertMsg, setAlertMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const supportApp = usePostFeedback();
  const getMyProfile = useGetMyProfile();
  const nav = useNavigate();
  const onAlertClose = () => {
    setAlertMsg('');
    setError('');
    setMessage('');
  };
  const handleInput = (evt) => {
    console.log('evt.target.value', evt.target.value);
    setMessage(evt.target.value);
  };

  const handleSupportData = () => {
    console.log('message', message);
    if (message.length <= 0) {
      setError('Message is required field!');
    } else {
      setLoading(true);
      let data = {
        message: message,
      };

      supportApp.mutate(data, {
        onSuccess: (response) => {
          setLoading(false);
          setAlertMsg('Your Feedback has been sent successfully.');
          console.log('response', response);
        },
        onError: (error) => {
          setLoading(false);
          setError(error?.error || 'Something went wrong. Please try again.');
        },
      });
    }
  };

  const fetchMyProfileDetails = () => {
    setLoading(true);
    getMyProfile.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log('getMyProfile onSuccess: ', response);
          // setUserDetails(response?.data?.payload);
          setEmail(response?.data?.payload?.user?.email);
          console.log('email', email);
          setLoading(false);
        },
        onError: (err) => {
          console.log('getMyProfile onError: ', err);
          setLoading(false);
        },
      },
    );
  };

  const handleClose = () => {
    nav(routeUrls.profile);
    window.history.back();
  };
  useEffect(() => {
    fetchMyProfileDetails();
  }, []);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 700);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 700);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Loader open={loading} />
      {alertMsg.length > 0 && (
        <CustomAlert
          isOpen={alertMsg.length > 0 ? true : false}
          message={alertMsg}
          onClose={onAlertClose}
        />
      )}

      <Grid container>
        <Grid
          container
          xs={12}
          sx={{
            mt: isWideScreen ? 20 : 14,
            color: '#444444',
            display: 'flex',
            justifyContent: 'center', // Center content within the parent grid
            position: 'relative', // Allows for absolute positioning
          }}>
          <Grid item>
            <Typography className={styles.shareApp}>Support</Typography>
          </Grid>

          <Grid
            item
            sx={{
              position: 'absolute', // Position close icon absolutely
              right: 20, // Align it to the extreme right with some margin
              top: 2,
              cursor: 'pointer',
              fontWeight: 'bold',
            }}
            onClick={handleClose}
            className={styles.closeIcon}>
            <Typography>
              <img src={close_icon} alt="Close" />
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={12} m={1}>
          <hr
            style={{
              marginLeft: '10px',
              marginRight: '10px',
              border: 'none',
              height: '0.5px',
              // marginBottom: '5px',
              backgroundColor: '#cccccc',
            }}
          />
        </Grid>

        <Grid
          item
          m={3}
          sx={{
            marginTop: '10px',
            marginBottom: '10px',
            marginLeft: '30px',
          }}
          className={styles.title_message}>
          <Typography className={styles.title_message}>
            {' '}
            If you have any issues with Introw Dating, <br />
            Please share your feedback.
          </Typography>
        </Grid>

        <Grid item xs={12} m={1}>
          <hr
            style={{
              marginLeft: '10px',
              marginRight: '10px',
              border: 'none',
              height: '0.5px',
              // marginBottom: '5px',
              backgroundColor: '#cccccc',
            }}
          />
        </Grid>

        {/* <Grid item m={1} className={styles.title_message}>
          If you have any issues with Introw Dating, please share your feedback.
        </Grid> */}

        <Grid container m={1}>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: '10px',
              marginBottom: '10px',
              marginLeft: '20px',
            }}
            color={' #444444'}>
            <Typography
              textAlign="left"
              className={styles.description}
              style={{
                fontFamily: 'Gill Sans Nova',
                letterSpacing: '0.03em',
                fontSize: `${scaleByRatio(12)}px`,
              }}>
              Email
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              // marginTop:'10px',
              marginBottom: '10px',
              marginLeft: '10px',
              marginRight: '10px',
            }}>
            <InputField
              class_name={styles.name}
              minHeightPX={'35'}
              id={'inputData'}
              labelId={''}
              type={'email'}
              // id={'inputData'}
              disabled={true}
              value={email}
              width="1000px"
              // handleChange={(event) => handleInput(event)}
              placeholder={'Email (Required)'}
            />
          </Grid>
        </Grid>

        {/* <Grid container m={1} color={' #444444'}>
          <Grid item xs={12}>
            <Typography
              className={styles.description}
              style={{
                fontSize: `${scaleByRatio(24)}px`,
              }}>
              Email
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputField
              id={'inputData'}
              labelId={''}
              type={'email'}
              // id={'inputData'}
              disabled={true}
              value={email}
              width="1000px"
              // handleChange={(event) => handleInput(event)}
              minHeightPX="56"
              placeholder={'Email (Required)'}
            />
          </Grid>
        </Grid> */}

        <Grid container m={1} color={' #444444'}>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: '10px',
              marginBottom: '10px',
              marginLeft: '20px',
            }}
            color={' #444444'}>
            <Typography
              textAlign="left"
              className={styles.description}
              style={{
                fontFamily: 'Gill Sans Nova',
                letterSpacing: '0.03em',
                fontSize: `${scaleByRatio(12)}px`,
              }}>
              Message
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              // marginTop:'10px',
              marginBottom: '10px',
              marginLeft: '10px',
              marginRight: '10px',
            }}>
            <InputField
              class_name={styles.name2}
              name="Message"
              id={'Message'}
              value={message}
              placeholder={'Message (Required)'}
              handleChange={(event) => handleInput(event)}
              type={'text'}
              minHeightPX={`${scaleByRatio(130)}`}
              multiline={true}
              rows={6}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <InputField
              name="Message"
              id={'Message'}
              value={message}
              placeholder={'Message (Required)'}
              handleChange={(event) => handleInput(event)}
              type={'text'}
              class_name={styles.name}
              minHeightPX={`${scaleByRatio(56)}`}
              multiline={true}
              rows={5}
            />
          </Grid> */}
          {error && error?.length > 0 && message.length <= 0 && (
            <Grid
              container
              direction="row"
              justifyContent="left"
              alignItems="flex-end">
              <Typography
                textAlign="left"
                className={styles.error}
                style={{
                  fontSize: `${scaleByRatio(12)}px`,
                  lineHeight: `${scaleByRatio(40)}px`,
                }}>
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          xs={12}
          //   alignContent={'center'}
          mt={4}
          display={'flex'}
          justifyContent={'center'}>
          {/* <CustomButton
            text={'Submit'}
            // style={{ display: 'block', textAlign: 'center' }}
            // type={ButtonType.primary}
            onClick={handleSupportData}
          /> */}

          <Button
            variant="contained"
            onClick={handleSupportData}
            sx={{
              backgroundColor: '#F8A233',
              color: '#FFFFFF',
              fontFamily: 'Gill Sans Nova',
              fontWeight: '900',
              textTransform:'none',
              fontSize: '14px',
              padding: '10px 48px',
              borderRadius: '50px',
              '&:hover': {
                backgroundColor: '#F8A233',
              },
              '&:active': {
                backgroundColor: '#F8A233',
              },
              '&:focusVisible': {
                backgroundColor: '#F8A233',
              },
            }}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Support;
