import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import {
  useGetDatingPreferenceQuestions,
  usePostDatingPreference,
  useGetDatingPreferenceAnswers,
  useGetCustomDatingPreferenceQuestions,
  usePatchCustomDatingPreferenceQuestions,
} from '../../services/mutations';
import {
  AnswerType,
  HomeMenuConstant,
  PreferenceQuestionType,
  routeUrls,
} from '../../utils/constants';
import Box from '@mui/material/Box';
import { scaleByRatio } from '../../utils/helper';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import DatingPreferenceMultipleChoice from '../DatingPreferenceMultipleChoice';
import DatingPreferenceSingleChoice from '../DatingPreferenceSingleChoice';
import RangeSlider from '../RangeSlider';
import DatingPreferenceSelectDropDown from '../DatingPreferenceSelectDropDown';
import close_icon from '../../assets/close_icon.svg';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import Loader from '../../molecule/Loader';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import { useNavigate } from 'react-router-dom';

const DatingPreference = () => {
  const getDatingPreferenceQuestions = useGetDatingPreferenceQuestions();
  const postDatingPreference = usePostDatingPreference();
  const nav = useNavigate();
  const getDatingPreferenceAnswers = useGetDatingPreferenceAnswers();
  const getCustomDatingPreferenceQuestions =
    useGetCustomDatingPreferenceQuestions();
  const localStorage = useLocalStorage();
  const patchCustomDatingPreferenceQuestions =
    usePatchCustomDatingPreferenceQuestions();

  const [preferenceQuestionList, setPreferenceQuestionList] = useState([]);
  const [preferenceAnswerList, setPreferenceAnswerList] = useState([]);
  const [customPreferenceQuestionList, setCustomPreferenceQuestionList] =
    useState(null);
  const [finalPreferenceList, setFinalPreferenceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [SequenceArr, setSequenceArr] = useState([]);

  const fetchQuestions = () => {
    setLoading(true);
    getDatingPreferenceQuestions.mutate(
      {},
      {
        onSuccess: (response) => {
          setLoading(false);
          console.log('getDatingPreferenceQuestions onSuccess: ', response);
          setPreferenceQuestionList(response?.data?.payload?.results || []);
          //fetchAnswers()
        },
        onError: (error) => {
          setLoading(false);
          console.log('getDatingPreferenceQuestions onError: ', error);
        },
      },
    );
  };

  const fetchAnswers = () => {
    setLoading(true);
    getDatingPreferenceAnswers.mutate(
      {},
      {
        onSuccess: (response) => {
          setLoading(false);
          console.log('getDatingPreferenceAnswers onSuccess: ', response);
          setPreferenceAnswerList(response?.data?.payload?.results || []);
          fetchCustomDatingPreference();
        },
        onError: (error) => {
          setLoading(false);
          console.log('getDatingPreferenceAnswers onError: ', error);
        },
      },
    );
  };

  const fetchCustomDatingPreference = () => {
    setLoading(true);
    getCustomDatingPreferenceQuestions.mutate(
      {},
      {
        onSuccess: (response) => {
          setLoading(false);
          console.log('fetchCustomDatingPreference Success: ', response);
          setCustomPreferenceQuestionList(response?.data?.payload);
          fetchQuestions();
        },
        onError: (error) => {
          setLoading(false);
          console.log('fetchCustomDatingPreference error: ', error);
        },
      },
    );
  };

  const toFeet = (n) => {
    // var realFeet = ((n*0.393700) / 12);
    // var feet = Math.floor(realFeet);
    // var inches = Math.round((realFeet - feet) * 12);
    // return feet + "'" + inches + '"';
    let heightin = Math.round(parseInt(n) / 2.54);
    console.log('heightin: ', heightin);
    let heightft = Math.floor(heightin / 12);
    console.log('heightft: ', heightft);
    let remainingInch = heightin - Math.floor(heightft) * 12;
    console.log('remainingInch: ', remainingInch);
    return Math.floor(heightft) + "'" + Math.round(remainingInch) + '"';
  };
  const createFinalPreferenceList = () => {
    let arr = [];
    SequenceArr.forEach((item) => {
      if (item.type === PreferenceQuestionType.Question) {
        let questionArr = preferenceQuestionList.filter(
          (question) => question.identifier === item.identifier,
        );
        if (questionArr.length > 0) {
          item.id = questionArr[0].id;
          item.question_choices = questionArr[0].question_choices;

          let arrSelectedAnswers = [];
          if (preferenceAnswerList.length > 0) {
            let question_choices = questionArr[0].question_choices;
            // question_choices.forEach((choice))
            preferenceAnswerList.forEach((answer) => {
              let choiceIndex = question_choices.findIndex(
                (choice) => choice.id === answer.preference,
              );
              if (choiceIndex > -1) {
                arrSelectedAnswers.push(answer.preference);
              }
            });
            item.selectedValue = arrSelectedAnswers;
          }

          arr.push(item);
        }
      } else {
        if (item.identifier === 'height') {
          item.selectedValue = [
            customPreferenceQuestionList.height_from,
            customPreferenceQuestionList.height_to,
          ];
          item.displayMin = toFeet(
            customPreferenceQuestionList.height_from > 134.62
              ? customPreferenceQuestionList.height_from
              : 137.16,
          );
          item.displayMax = toFeet(
            customPreferenceQuestionList.height_to > 226.05
              ? 226.05
              : customPreferenceQuestionList.height_to,
          );
        } else if (item.identifier === 'age') {
          item.selectedValue = [
            customPreferenceQuestionList.age_from,
            customPreferenceQuestionList.age_to,
          ];
          item.displayMin = customPreferenceQuestionList.age_from;
          item.displayMax = customPreferenceQuestionList.age_to;
        } else if (item.identifier === 'distance') {
          item.selectedValue = customPreferenceQuestionList.distance;
        }
        arr.push(item);
      }
    });
    setFinalPreferenceList(arr);
  };

  const handleOnChangeCallBack = (
    selectedvalue,
    question_id,
    identifier,
    question_type,
    choice_type,
  ) => {
    console.log('handleOnChangeCallBack selectedvalue: ', selectedvalue);
    console.log('handleOnChangeCallBack question_id: ', question_id);
    console.log('handleOnChangeCallBack identifier: ', identifier);
    console.log('handleOnChangeCallBack question_type: ', question_type);
    console.log('handleOnChangeCallBack choice_type: ', choice_type);
    if (question_type === PreferenceQuestionType.Question) {
      let questionIndex = finalPreferenceList.findIndex(
        (question) => question.id === question_id,
      );
      if (questionIndex > -1) {
        finalPreferenceList[questionIndex].selectedValue = selectedvalue;
      }
    } else {
      let questionIndex = finalPreferenceList.findIndex(
        (question) => question.identifier === identifier,
      );
      if (questionIndex > -1) {
        finalPreferenceList[questionIndex].selectedValue = selectedvalue;
      }
    }
  };

  const onSubmitClicked = () => {
    let arrPreferenceAnswer = [];
    let objCustomPreference = {};
    finalPreferenceList.forEach((question) => {
      if (question.type === PreferenceQuestionType.Question) {
        console.log('identifier: ', question.identifier);
        console.log('selectedvalue: ', question?.selectedValue);
        let selectedValue = question?.selectedValue;
        selectedValue.forEach((value) => {
          let obj = {
            active: true,
            preference: value,
          };
          arrPreferenceAnswer.push(obj);
        });
      } else {
        if (question.identifier === 'height') {
          objCustomPreference.height_from =
            question.selectedValue[0] <= 134.62
              ? 134.63
              : question.selectedValue[0];
          objCustomPreference.height_to = question.selectedValue[1];
        } else if (question.identifier === 'age') {
          objCustomPreference.age_from = question.selectedValue[0];
          objCustomPreference.age_to = question.selectedValue[1];
        } else if (question.identifier === 'distance') {
          objCustomPreference.distance = question.selectedValue;
        }
      }
    });
    setLoading(true);
    objCustomPreference.user = localStorage.getLocalStorageItem(
      localStorageKeys.profileId,
    );
    patchCustomDatingPreferenceQuestions.mutate(objCustomPreference, {
      onSuccess: (response) => {
        setLoading(false);
        if (arrPreferenceAnswer.length > 0) {
          setLoading(true);
          postDatingPreference.mutate(arrPreferenceAnswer, {
            onSuccess: (response) => {
              setLoading(false);
              console.log('postDatingPreference onSuccess:', response);
              fetchAnswers();
            },
            onError: (error) => {
              setLoading(false);
              console.log('postDatingPreference error:', error);
            },
          });
        }
      },
      onError: (error) => {
        setLoading(false);
        setError(error?.response?.data?.erro);
        console.log('patchCustomDatingPreferenceQuestions error: ', error);
      },
    });
  };

  const handleClose = () => {
    nav(routeUrls.profile);
    window.history.back();
  };

  useEffect(() => {
    let _SequenceArr = [
      {
        identifier: 16,
        type: PreferenceQuestionType.Question,
        choice_type: AnswerType.MC,
        question: 'Ethnicity',
        question_color: '#00597B',
        selectedValue: [],
      },
      {
        identifier: 'height',
        type: PreferenceQuestionType.Custom,
        choice_type: AnswerType.Range,
        question: 'Height',
        question_color: '#00597B',
        minValue: 134.63,
        maxValue: 226.05,
        displayMin: `4'5"`,
        displayMax: `7'5"`,
        selectedValue: [137.16, 226.05],
      },
      {
        identifier: 28,
        type: PreferenceQuestionType.Question,
        choice_type: AnswerType.MC,
        question: 'Kids?',
        question_color: '#F46B1B',
        selectedValue: [],
      },
      {
        identifier: 19,
        type: PreferenceQuestionType.Question,
        choice_type: AnswerType.SC,
        question: 'What about the future?',
        question_color: '#00597B',
        selectedValue: [],
      },
      {
        identifier: 20,
        type: PreferenceQuestionType.Question,
        choice_type: AnswerType.MC,
        question: 'Religion or Spirituality?',
        question_color: '#00597B',
        selectedValue: [],
      },
      {
        identifier: 29,
        type: PreferenceQuestionType.Question,
        choice_type: AnswerType.MC,
        question: 'Political Beliefs?',
        question_color: '#F46B1B',
        selectedValue: [],
      },
      {
        identifier: 'age',
        type: PreferenceQuestionType.Custom,
        choice_type: AnswerType.Range,
        question: 'Age',
        question_color: '#00597B',
        minValue: 18,
        maxValue: 95,
        displayMin: `18`,
        displayMax: `95`,
        selectedValue: [30, 60],
      },
      {
        identifier: 'distance',
        type: PreferenceQuestionType.Custom,
        choice_type: AnswerType.Select,
        question: 'Distance',
        question_color: '#00597B',
        choice: [
          { id: '1', label: '2 MILES', value: 2 },
          { id: '2', label: '5 MILES', value: 5 },
          { id: '3', label: '10 MILES', value: 10 },
          { id: '6', label: '20 MILES', value: 20 },
          { id: '7', label: '40 MILES', value: 40 },
          { id: '8', label: '60 MILES', value: 60 },
          { id: '9', label: '80 MILES', value: 80 },
          { id: '10', label: '100 MILES', value: 100 },
          { id: '11', label: '5000 MILES', value: 5000 },
        ],
        selectedValue: 10,
      },
    ];
    setSequenceArr(_SequenceArr);
    //fetchAnswers()
  }, []);
  useEffect(() => {
    if (SequenceArr.length > 0) {
      fetchAnswers();
    }
  }, [SequenceArr]);

  useEffect(() => {
    if (preferenceQuestionList.length > 0) {
      // createFinalPreferenceList()
      setFinalPreferenceList([]);
    }
  }, [preferenceQuestionList, preferenceAnswerList]);
  useEffect(() => {
    console.log('finalPreferenceList:>>> ', finalPreferenceList);
    if (finalPreferenceList.length === 0 && preferenceQuestionList.length > 0) {
      createFinalPreferenceList();
    }
  }, [finalPreferenceList, preferenceQuestionList]);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 700);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 700);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Loader open={loading} />
      {!loading && (
      <Box
      component="main"
      sx={{
        flexGrow: 1,
        pb: 10,
        pt: 3,
        pl: '21px',
        pr: '21px',
        marginTop: `${isWideScreen ? scaleByRatio(120) : scaleByRatio(80)}px`,
      }}
    >

          <Grid
            container
            direction="row"
            alignItems="flex-start"
            sx={{
              // background:"#FFFFFF",
              background: '#000',
              minHeight: `${scaleByRatio(40)}px`,
              paddingBottom: `${scaleByRatio(3)}px`,
              paddingTop: `${scaleByRatio(3)}px`,
            }}>
            <Grid item xs={11} justifyContent="center">
              <Typography textAlign="left" className={styles.heading}>
              Profile
              </Typography>
              <Grid
                display={'flex'}
                justifyContent={'flex-end'}
                color={'#fff'}
                marginTop={'-25px'}
                marginRight={'-20px'}
                style={{ cursor: 'pointer', fontWeight: 'bold' }}
                onClick={handleClose}
                className={styles.closeIcon}>
                <Typography>
                  <img src={close_icon}/>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <hr style={{ maxWidth: '100%', marginBottom:'50px', height:'1px' , backgroundColor:'#cccccc' ,  border: 'none'}} />

          {finalPreferenceList.length > 0 &&
            finalPreferenceList.map((preference) => {
              return preference.choice_type === AnswerType.MC ? (
                <DatingPreferenceMultipleChoice
                  question={preference.question}
                  id={preference.id}
                  identifier={preference.identifier}
                  question_choices={preference.question_choices}
                  question_color={preference.question_color}
                  selectedAnswer={preference.selectedValue}
                  handleOnChangeCallBack={handleOnChangeCallBack}
                  question_type={preference.type}
                />
              ) : preference.choice_type === AnswerType.SC ? (
                <DatingPreferenceSingleChoice
                  question={preference.question}
                  id={preference.id}
                  identifier={preference.identifier}
                  question_choices={preference.question_choices}
                  question_color={preference.question_color}
                  selectedAnswer={preference.selectedValue}
                  handleOnChangeCallBack={handleOnChangeCallBack}
                  question_type={preference.type}
                />
              ) : preference.choice_type === AnswerType.Range ? (
                <RangeSlider
                  question={preference.question}
                  id={null}
                  identifier={preference.identifier}
                  minValue={preference.minValue}
                  maxValue={preference.maxValue}
                  //   question_color={preference.question_color}
                  question_color={'#F4AA4D'}
                  displayMin={preference.displayMin}
                  displayMax={preference.displayMax}
                  selectedValue={preference.selectedValue}
                  handleOnChangeCallBack={handleOnChangeCallBack}
                  question_type={preference.type}
                />
              ) : preference.choice_type === AnswerType.Select ? (
                <DatingPreferenceSelectDropDown
                  question={preference.question}
                  //   question_color={preference.question_color}
                  question_color={'#F4AA4D'}
                  id={null}
                  identifier={preference.identifier}
                  type={preference.type}
                  choice={preference.choice}
                  selectedValue={preference.selectedValue || 10}
                  handleOnChangeCallBack={handleOnChangeCallBack}
                  question_type={preference.type}
                />
              ) : null;
            })}
          {error && error?.length > 0 && (
            <Grid item xs={12}>
              <Typography textAlign="left" className={styles.error}>
                {error}
              </Typography>
            </Grid>
          )}
          <Grid
            marginTop={'30px'}
            marginBottom={'110px'}
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-end">
            <Grid item>
              <CustomButton
                text={'Save'}
                variant={ButtonVariant.contained}
                type={ButtonType.primary}
                onClick={onSubmitClicked}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default DatingPreference;
