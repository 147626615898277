import React, {useState, useEffect} from 'react'
import styles from './index.module.scss'
import Modal from '@mui/material/Modal';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const ImageGallery = ({
    showGallery=false,
    images=[],
    onCloseCallBack,
}) => {
    const [open, setOpen] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = images.length;
    const theme = useTheme();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      };
    
      const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };
    
      const handleStepChange = (step) => {
        setActiveStep(step);
      };

    const handleOpen = () =>{
        setOpen(true);
    } 
    const handleClose = () => {
        setOpen(false);
        onCloseCallBack(false)
    }
    useEffect(()=>{
        setOpen(showGallery)
    },[showGallery])
    useEffect(()=>{
        console.log('Image Gallery: ', images)
    },[images])
    return ( 
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ maxWidth: {xs:370, sm:370, borderRadius: '15px',}, flexGrow: 1 }} className={styles.galleryContainer}>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
            >
                {images.map((step, index) => (
                <div key={step.label}>
                    {Math.abs(activeStep - index) <= 2 ? (
                    <Box
                        component="img"
                        sx={{
                            height: {xs: 340, sm:300},
                            display: 'block',
                            maxWidth: {xs: 450, sm:600},
                            overflow: 'hidden',
                            borderRadius: '15px',
                            width: '100%',
                        }}
                        src={step.image}
                        alt={step.label}
                    />
                    ) : null}
                </div>
                ))}
            </AutoPlaySwipeableViews>
            {images.length > 1 && (
            <MobileStepper
            style={{marginTop:'-55%', marginBottom:'30%',color:'#fff'}}
                steps={0}              
                position="static"
                activeStep={activeStep}
                nextButton={
                <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === maxSteps - 1}
                    className={styles.button}
                    style={{marginRight:'-15px'}}
                >
                    {/* Next */}
                    {theme.direction === 'rtl' ? (
                    // <KeyboardArrowLeft htmlColor='white' fontSize='large' />
                    <span style={{color:'#fff',fontSize:'40px'}}>{`<`}</span>
                    ) : (
                    // <KeyboardArrowRight htmlColor='white' fontSize='large'/>
                    <span style={{color:'#fff',fontSize:'40px'}}>{`>`}</span>
                    )}
                </Button>
                }
                backButton={
                <Button 
                size="small" 
                onClick={handleBack}
                disabled={activeStep === 0}
                className={styles.button}
                style={{marginLeft:'-15px'}}
                >
                    {theme.direction === 'rtl' ? (
                    // <KeyboardArrowRight  htmlColor='white' fontSize='large'/>
                    <span style={{color:'#fff',fontSize:'40px'}}>{`>`}</span>
                    ) : (
                    // <KeyboardArrowLeft htmlColor='white' fontSize='large' />
                    <span style={{color:'#fff',fontSize:'40px'}}>{`<`}</span>
                    )}
                    {/* Previous */}
                </Button>
                }
            />
            )}
        </Box>
      </Modal>
     );
}
 
export default ImageGallery;