import React, {useState} from 'react'
import styles from './index.module.scss'
import {  Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
import AppLogoMin from '../../assets/AppLogoMin.svg'
import ArrowLeft from '../../assets/ArrowLeft.svg'
import SingleChoiceSelectionButton from '../../atoms/SingleChoiceSelectionButton';
import {ButtonVariant, ButtonType, CustomButton} from '../../atoms/CustomButton'
import {scaleByRatio} from '../../utils/helper'
import AppLogo from '../../assets/AppLogoMin.svg'
import SingleChoiceSelectionButtonNotification from '../../atoms/SingleChoiceSelectionButtonNotification';

const NotificationPreference = ({
    onBackClicked,
    error,
    onNextClicked,
    isEnabled,
    onClickCallBack
}) => {
    const [enable, setEnable] = useState(isEnabled)
    const handleOnEnableClicked = ()=>{
        let _enable = !enable;
        setEnable(_enable)
        onClickCallBack(_enable)
    }
    return ( 
        <>
        <Grid container direction="row" justifyContent="center" alignItems="flex-end">
            <Grid item className={styles.logoContainer} marginTop={-8}>
                {/* <Typography textAlign='center' className={styles.logoContainer}> */}
                    <img src={AppLogo} alt={'App icon'}
                    style={{
                        width:'90%',
                marginBottom: '20px',

                        height:`${scaleByRatio(110)}px`,
                    }}
                    />
                {/* </Typography> */}
            </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="left" alignItems="flex-end">
            <Grid item className={styles.logoContainer}>
                 <img src={ArrowLeft} alt={'Back icon'}
                 width={scaleByRatio(12)} height={scaleByRatio(12)}
                 className={styles.logo} onClick={onBackClicked}/>
            </Grid>
            <Grid item >
                <Typography textAlign='left' className={styles.back} onClick={onBackClicked}
                style={{
                    fontSize:`${scaleByRatio(12)}px`,
                    paddingLeft: `${scaleByRatio(10)}px`,
                    paddingBottom:`${scaleByRatio(2)}px`,
                }}
                >
                Back
                </Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="left" alignItems="flex-end">
            <Grid item >
                <Typography textAlign='left' className={styles.description}
                style={{
                    fontSize: `${scaleByRatio(22)}px`,
              lineHeight: `${scaleByRatio(28.64)}px`,
              letterSpacing: '0.06em', 
              marginLeft: '15px'
                }}
                >
                We can send you notifications when things are happening. Cool with you?
                </Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" className={styles.notiBtnWrapper} marginTop={'25px'} marginBottom={'30px'}>
            <Grid item justifyContent="left" alignItems="flex-start" marginBottom={'5px'}>
                <SingleChoiceSelectionButtonNotification
                text={'Enable Notifications'}
                isSelected={enable}
                onClick={handleOnEnableClicked}
                />
            </Grid>
            <Grid item justifyContent="right" alignItems="flex-end">
                <SingleChoiceSelectionButtonNotification 
                text={'Disable Notifications'}
                isSelected={!enable}
                onClick={handleOnEnableClicked}
                />
            </Grid>
        </Grid>
        
        {error && error?.length>0 && (
            <Grid container direction="row" justifyContent="left" alignItems="flex-end">
                <Typography textAlign='left' className={styles.error}
                style={{
                    fontSize:`${scaleByRatio(18)}px`,
                    lineHeight: `${scaleByRatio(40)}px`,
                }}>
                {error}
                </Typography>
            </Grid>
        )}
        <Grid container direction="row" justifyContent="end" alignItems="flex-end" marginRight={'-20px'}>
            <Grid item >
                <CustomButton 
                text={'Next'}
                variant={ButtonVariant.contained}
                type={ButtonType.primary}
                customStyle='yes'
                onClick={()=>onNextClicked()}
                />
            </Grid>
        </Grid>
        </>
     );
}
 
export default NotificationPreference;