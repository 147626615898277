import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import styles from './index.module.scss';

import MyProfileAboutUs from '../MyProfileAbouUs';
import MyProfileMyInterests from '../MyProfileMyInterests';
import Loader from '../../molecule/Loader';

import MyProfileSectionView from '../MyProfileSectionView';
import MyProfilePhotos from '../MyProfilePhotos';
import {
  useGetExploreDaterUserProfileIDList,
  useGetUserProfileByID,
  useGetAllQuestions,
  useGetUsersFriendList,
  useGetResponseForAllQuesionsByUser,
  usePostProspectiveDater,
  usePostProspectiveDaterById,
  useGetActiveDater,
  useGetExploreDaterUserProfileIDListByID,
  usePostViewHistory,
  useGetMyProfile,
} from '../../services/mutations';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import { scaleByRatio } from '../../utils/helper';
import {
  ProfilePageType,
  ExploreViewHistoryStatus,
} from '../../utils/constants';
import CustomAlert from '../../atoms/CustomAlert';
import OthersFriendList from '../OthersFriendList';
import MyProfileOthersData from '../MyProfileOthersData';
import { ExploreCloseContext } from '../../utils/Context/reportCounter';

const Explore = () => {
  const localStorageItem = useLocalStorage();
  const getExploreDaterUserProfileIDList =
    useGetExploreDaterUserProfileIDList();
  const getExploreDaterUserProfileIDListByID =
    useGetExploreDaterUserProfileIDListByID();
  const getUserProfileByID = useGetUserProfileByID();
  const getAllQuestions = useGetAllQuestions();
  const getUsersFriendList = useGetUsersFriendList();
  const getResponseForAllQuesionsByUser = useGetResponseForAllQuesionsByUser();
  const postProspectiveDater = usePostProspectiveDater();
  const postProspectiveDaterById = usePostProspectiveDaterById();
  const getActiveDater = useGetActiveDater();
  const postViewHistory = usePostViewHistory();
  const getMyProfile = useGetMyProfile();

  const [activeProfile, setIsActiveProfile] = useState(true);
  const [activeDater, setActiveDater] = useState(null);
  const [allQuestions, setAllQuestions] = useState(null);
  const [userProfileIDList, setUserProfileIDList] = useState([]);
  const [currenUserIndex, setCurrentUserIndex] = useState(0);
  const [nameAgeLocation, setNameAgeLocation] = useState('');
  const [nameAgeLocationArray ,setNameAgeLocationArray ]= useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [profilePic, setProfilePic] = useState(null);
  const [userImages, setUserImages] = useState([]);
  const [allAnswers, setAllAnswers] = useState(null);
  const [friendsList, setFriendsList] = useState([]);
  const [showOtherFriendsList, setShowOtherFriendsList] = useState(false);
  const [exploreClose, setExploreClose] = useContext(ExploreCloseContext);
  const [checkConter,setCheckCounter]=useState(0)
  const [loading, setLoading] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const arrProfileSections = [
    {
      header: 'My Work',
      type: 'Questions',
      identifiers: [22, 23],
    },
    {
      header: 'I dig someone that.',
      type: 'Questions',
      identifiers: [7],
    },
    {
      header: 'My Politics',
      type: 'Questions',
      identifiers: [8],
    },
    {
      header: 'I dream of a person who.',
      type: 'Questions',
      identifiers: [2],
    },
    {
      header: 'Things of spirit.',
      type: 'Questions',
      identifiers: [12],
    },
    {
      header: 'Hobbies and fun.',
      type: 'Questions',
      identifiers: [9],
    },
    {
      header: 'My deepest passion is.',
      type: 'Questions',
      identifiers: [3],
    },
    {
      header: 'Excercise and Sports.',
      type: 'Questions',
      identifiers: [9],
    },
    {
      header: 'Dream Job.',
      type: 'Questions',
      identifiers: [24],
    },
    {
      header: 'Home and habbits.',
      type: 'Questions',
      identifiers: [10],
    },
    {
      header: 'I feel most at home when.',
      type: 'Questions',
      identifiers: [4],
    },
    {
      header: 'Culture and Media.',
      type: 'Questions',
      identifiers: [11],
    },
    {
      header: 'The most fun I remember when is.',
      type: 'Questions',
      identifiers: [6],
    },
    {
      header: 'To me my family is.',
      type: 'Questions',
      identifiers: [5],
    },
  ];

  const fetchActiveDaterOfFriend = () => {
    setLoading(true);
    getActiveDater.mutate(
      {},
      {
        onSuccess: (response) => {
          setLoading(false);
          console.log('getActiveDater onSuccess: ', response);
          setActiveDater(response?.data?.payload?.user?.id);
        },
        onError: (error) => {
          setLoading(false);
          console.log('getActiveDater onError: ', error);
        },
      },
    );
  };

  const fetchDatersUserProfileIDList = () => {
    setLoading(true);
    getExploreDaterUserProfileIDList.mutate(
      {},
      {
        onSuccess: (response) => {
          setLoading(false);
          console.log('test',response?.data?.payload?.results);
          setUserProfileIDList(response?.data?.payload?.results);
        },
        onError: (error) => {
          setLoading(false);
          console.log('getExploreDaterUserProfileIDList error: ', error);
        },
      },
    );
  };
  const fetchDatersUserProfileIDListOfActiveDater = () => {
    setLoading(true);
    let data = {
      id: activeDater,
    };
    getExploreDaterUserProfileIDListByID.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        console.log('test1',response?.data?.payload?.results);
        setUserProfileIDList(response?.data?.payload?.results);
      },
      onError: (error) => {
        setLoading(false);
        console.log('getExploreDaterUserProfileIDListByID error: ', error);
      },
    });
  };
  const fetchUserProfileDetailsById = (user_id) => {
    let data = {
      id: user_id,
    };
    setLoading(true);
    getUserProfileByID.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        console.log('getMyProfile onSuccess: ', response);
        setUserDetails(response?.data?.payload);
        // setNameAgeLocation(
        //   response?.data?.payload?.user?.first_name +
        //     ', ' +
        //     response?.data?.payload?.age +
        //     ' ' +
        //     response?.data?.payload?.city,
        // );
        setNameAgeLocationArray(
          [
            response?.data?.payload?.user?.first_name,
            response?.data?.payload?.age,
            response?.data?.payload?.city
          ]
        );
        setProfilePic(response?.data?.payload?.profile_pic || null);
        setUserImages(response?.data?.payload?.images || []);
        fetchAllQuestions();
      },
      onError: (err) => {
        console.log('getMyProfile onError: ', err);
        setLoading(false);
      },
    });
  };
  const fetchAllQuestions = () => {
    setLoading(true);
    getAllQuestions.mutate(
      {},
      {
        onSuccess: (response) => {
          setLoading(false);
          console.log('getAllQuestions onSuccess: ', response);
          setAllQuestions(response?.data?.payload?.results);
          fetchAllAnswers();
        },
        onError: (error) => {
          setLoading(false);
          console.log('getAllQuestions onError :', error);
          setLoading(false);
        },
      },
    );
  };
  const fetchAllAnswers = () => {
    let answerData = {
      limit: 200,
      offset: 0,
      user: userProfileIDList[currenUserIndex].id,
    };
    setLoading(true);
    getResponseForAllQuesionsByUser.mutate(answerData, {
      onSuccess: (response) => {
        let arrAnswer = response?.data?.payload?.results;
        console.log('arrAnswer: ', arrAnswer);
        setAllAnswers(arrAnswer);
        setLoading(false);
        fetchFriendList();
      },
      onError: (error) => {
        setAllAnswers(null);
        setLoading(false);
      },
    });
  };

  const fetchFriendList = () => {
    setLoading(true);
    let data = {
      dater: userProfileIDList[currenUserIndex].id,
    };
    getUsersFriendList.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        console.log('getUsersFriendList success:', response);
        setFriendsList(response?.data?.payload?.results);
      },
      onError: (error) => {
        setLoading(false);
        console.log('getUsersFriendList error:', error);
        setFriendsList([]);
      },
    });
  };
  const updateCurrentIndex = () => {
    let updatedIndex = currenUserIndex + 1;
    if (updatedIndex >= userProfileIDList.length) {
      console.log('updateUserIndex', updatedIndex);
      updatedIndex = 0;
      if (
        localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
        localStorageUserType.dater
      ) {
        fetchDatersUserProfileIDList();
      }
      // fetchActiveDaterOfFriend();
      if (
        localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
        localStorageUserType.friend
      ) {
        fetchActiveDaterOfFriend();
      }
    }
    setCurrentUserIndex(updatedIndex);
  };
  const onSkipClicked = () => {
    console.log('Explore on skip clicked');
    console.log('currentUserIndex', currenUserIndex);
    updateProspectiveDaterViewHistory(
      ExploreViewHistoryStatus.SKIP,
      userProfileIDList[currenUserIndex].id,
    );
    updateCurrentIndex();
  };
  const onNoClicked = () => {
    console.log('Explore onNoClicked');
    updateProspectiveDaterViewHistory(
      ExploreViewHistoryStatus.NO,
      userProfileIDList[currenUserIndex].id,
    );
    updateCurrentIndex();
  };
  const onYesClicked = () => {
    console.log('Explore onYesClicked');
    updateProspectiveDaterViewHistory(
      ExploreViewHistoryStatus.YES,
      userProfileIDList[currenUserIndex].id,
    );
    if (
      localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
      localStorageUserType.dater
    ) {
      prostectiveDatingRequest('Y');
    } else if (
      localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
      localStorageUserType.friend
    ) {
      prostectiveDatingRequestByID('Y');
    }
  };
  const onMaybeClicked = () => {
    console.log('Explore onMaybeClicked');
    updateProspectiveDaterViewHistory(
      ExploreViewHistoryStatus.MAYBE,
      userProfileIDList[currenUserIndex].id,
    );
    if (
      localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
      localStorageUserType.dater
    ) {
      prostectiveDatingRequest('M');
    } else if (
      localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
      localStorageUserType.friend
    ) {
      prostectiveDatingRequestByID('M');
    }
  };

  const updateProspectiveDaterViewHistory = (status, dataId) => {
    const formData = new FormData();
    formData.append('action', status);
    formData.append('viewed_profile', dataId);
    postViewHistory.mutate(formData, {
      onSuccess: (response) => {
        console.log('updateProspectiveDaterViewHistory success: ', response);
      },
      onError: (error) => {
        console.log('updateProspectiveDaterViewHistory error: ', error);
      },
    });
  };

  const prostectiveDatingRequest = (status) => {
    setLoading(true);
    let data = {
      interest: status,
      user: userProfileIDList[currenUserIndex].id,
    };
    postProspectiveDater.mutate(data, {
      onSuccess: (response) => {
        setLoading(false);
        // setAlertMsg('Request sent successfully!!')
        console.log('error', response, response?.data?.payload?.error);
        if (status == 'Y' || status == 'M')
          setAlertMsg(`You've added this profile to the group chat to discuss`);

        updateCurrentIndex();
      },
      onError: (error) => {
        console.log('error res', error);
        setLoading(false);
        console.log('error', error);
        updateCurrentIndex();
      },
    });
  };
  const prostectiveDatingRequestByID = (status) => {
    setLoading(true);
    let data = {
      interest: status,
      user: userProfileIDList[currenUserIndex].id,
      user_id: activeDater,
    };
    postProspectiveDaterById.mutate(data, {
      onSuccess: (response) => {
        console.log('error success res');
        setLoading(false);
        // setAlertMsg('Elbow sent successfully !!');
        setAlertMsg(`You've added this profile to the group chat to discuss.`);
        console.log('error', response, response?.data?.payload?.error);
      },
      onError: (error) => {
        console.log('error res', error?.response?.data?.payload?.error);
        // setAlertMsg(error?.response?.data?.payload?.error + '!!');
        setLoading(false);
        updateCurrentIndex();
      },
    });
  };
  const friendsClickExploreCallBack = () => {
    setShowOtherFriendsList(true);
  };

  const friendsExploreBackClicked = (check) => {
    setCheckCounter(0)
    setExploreClose(false)
    console.log('backlog',checkConter,exploreClose,check)
    setShowOtherFriendsList(false);
  };
  const onAlertClose = () => {
    setAlertMsg('');
    updateCurrentIndex();
  };

  const fetchMyProfileDetails = () => {
    setLoading(true);
    getMyProfile.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log('getMyProfile onSuccess: ', response);
          // setUserDetails(response?.data?.payload);
          // setEmail(response?.data?.payload?.user?.email);
          setIsActiveProfile(response?.data?.payload?.active);
          console.log('email', response?.data?.payload?.active);
          setLoading(false);
        },
        onError: (err) => {
          console.log('getMyProfile onError: ', err);
          setLoading(false);
        },
      },
    );
  };

  useEffect(() => {
    if (userProfileIDList.length > 0) {
      fetchUserProfileDetailsById(userProfileIDList[currenUserIndex].id);
    }
  }, [userProfileIDList, currenUserIndex]);
  useEffect(() => {
    setExploreClose(false)
    setCheckCounter(1)
    fetchMyProfileDetails();
    if (
      localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
      localStorageUserType.dater
    ) {
      setActiveDater(
        localStorageItem.getLocalStorageItem(localStorageKeys.profileId),
      );
      fetchDatersUserProfileIDList();
    }
    if (
      localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
      localStorageUserType.friend
    ) {
      fetchActiveDaterOfFriend();
    }
  }, []);
  useEffect(() => {
    if (
      activeDater &&
      localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
        localStorageUserType.friend
    ) {
      fetchDatersUserProfileIDListOfActiveDater();
    }
  }, [activeDater]);

  useEffect(() => {
    console.log('##***',exploreClose,checkConter)
    if(exploreClose){
      friendsExploreBackClicked('true')
    }
  }, [exploreClose,checkConter]);

  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 700);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 700);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {alertMsg.length > 0 && (
        <CustomAlert
          isOpen={alertMsg.length > 0 ? true : false}
          message={alertMsg}
          onClose={onAlertClose}
        />
      )}
      <Loader open={loading} />
      {!activeProfile &&
      localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
        localStorageUserType.dater ? (
        <Grid
          item
          xs={12}
          display={'flex'}
          justifyContent="center"
          marginTop={30}>
          <Typography
            textAlign="center"
            style={{
              fontSize: `${scaleByRatio(24)}px`,
              color: '#76d9ff',
            }}>
            Set your account to active to start exploring introw!
          </Typography>
        </Grid>
      ) : (
        <>
          {!loading && userProfileIDList.length > 0 && (
           <Box
           component="main"
           sx={{
             flexGrow: 1,
             pb: 3,
             pt: scaleByRatio(3),
             marginTop: `${isWideScreen ? scaleByRatio(120) : scaleByRatio(75)}px`,
             background: '#000',
           }}
         >
              {!loading && !showOtherFriendsList && (
                <>
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-end"
                    sx={{
                      //   background: '#FFFFFF',
                      background: '#000',
                      minHeight: `${scaleByRatio(50)}px`,
                      paddingBottom: `${scaleByRatio(10)}px`,
                    }}>
                    <Grid item xs={12} justifyContent="center">
                      <Typography
                        textAlign="left"
                        className={styles.nameAddressHeader}>
                        {/* {nameAgeLocation} */}

                        <span className={styles.name}>{nameAgeLocationArray[0]}</span>{' '}
                        <span className={styles.ageAddress}>{nameAgeLocationArray[1]}, {nameAgeLocationArray[2]} </span>
                        
                      </Typography>

                    </Grid>
                  </Grid>
                  <Box
                    component="main"
                    sx={{
                      flexGrow: 1,
                      pb: 9,
                      pt: 3,
                      pl: 3,
                      pr: 3,
                      //   background: '#F3F3F3',
                      background: '#000',
                    }}>
                    <MyProfileAboutUs
                      profilePic={profilePic}
                      allAnswers={allAnswers}
                      userDetails={userDetails}
                      handleChipClick={() => {}}
                      setClickable={() => {}}
                      friendsList={friendsList}
                      handleMenuClicked={() => {}}
                      setShowAddFriend={() => {}}
                      pageType={ProfilePageType.Explore}
                      onNoClicked={onNoClicked}
                      onYesClicked={onYesClicked}
                      onMaybeClicked={onMaybeClicked}
                      onSkipClicked={onSkipClicked}
                      friendsClickExploreCallBack={friendsClickExploreCallBack}
                    />

                    <MyProfileMyInterests
                      allAnswers={allAnswers}
                      handleChipClick={() => {}}
                    />
                    <MyProfilePhotos
                      arrImages={userImages}
                      handleChipClick={() => {}}
                    />
                    {/* <MyProfileSectionView
                      arrProfileSections={arrProfileSections}
                      allAnswers={allAnswers}
                      clickable={false}
                      handleChipClick={() => {}}
                    /> */}
                    <MyProfileOthersData
                      allQuestions={allQuestions}
                      allAnswers={allAnswers}
                      clickable={false}
                      // setEditMode={setEditMode}
                    />
                  </Box>
                </>
              )}
              {!loading && showOtherFriendsList && (
                <OthersFriendList
                  dater_id={userProfileIDList[currenUserIndex].id}
                  dater_name={userDetails?.user?.first_name}
                  onBackClicked={friendsExploreBackClicked}
                  activeDater={activeDater}
                  showReachout={
                    localStorageItem.getLocalStorageItem(
                      localStorageKeys.userType,
                    ) === localStorageUserType.dater
                      ? true
                      : false
                  }
                />
              )}
            </Box>
          )}
          {!loading && userProfileIDList.length === 0 && (
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                pb: 3,
                pt: scaleByRatio(3),
                marginTop: `${scaleByRatio(80)}px`,
                // background: '#FFFFFF',
                background: '#000',
              }}>
              <Grid
                container
                direction="row"
                alignItems="flex-end"
                sx={{
                  //   background: '#FFFFFF',
                  background: '#000',
                  minHeight: `${scaleByRatio(50)}px`,
                  padding: `${scaleByRatio(10)}px`,
                  paddingTop: `${scaleByRatio(200)}px`,
                  paddingBottom: `${scaleByRatio(200)}px`,
                }}>
                <Grid item xs={12} justifyContent="center">
                  <Typography
                    textAlign="center"
                    className={styles.noMatch}
                    style={{
                      fontSize: `${scaleByRatio(24)}px`,
                    }}>
                    {
                      // 'Sorry, no match found. Please try changing your dating preferences, to get more profiles.'
                      localStorageItem.getLocalStorageItem(
                        localStorageKeys.userType,
                      ) === localStorageUserType.dater
                        ? `Looks like there are no current matches for you. Adjust your dating preferences to see additional profiles.`
                        : `Looks like there are no current matches. Please suggest the dater to adjust dating preferences to see additional profiles.`
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Explore;
