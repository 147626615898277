import Radio from '@mui/material/Radio';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import { scaleByRatio } from '../../utils/helper';
import './styles.scss';
import RadioSelected from '../../assets/radio-selected-icon.svg';
import RadioUnselected from '../../assets/radio-unselected-icon.svg';

const ListItemRadio = ({
  value,
  onChange,
  isChecked,
  id,
  displayText,
  listItemClass = '',
  textClass = '',
}) => {
  return (
    <ListItem
      key={value}
      secondaryAction={
        <Radio
          edge="end"
          onChange={() => onChange(value)}
          checked={isChecked}
          icon={<img src={RadioUnselected} alt="RadioUnselected" />}
          checkedIcon={<img src={RadioSelected} alt="RadioSelected" />}
          // sx={{
          //   color: 'white', // Entire circle filled with white when not selected
          //   '&.Mui-checked': {
          //     color: 'white', // Outer ring is white when checked
          //     '& .MuiSvgIcon-root': {
          //       color: 'orange', // Inner dot is orange when checked
          //     },
          //   },
          // }}
          name="RadioButtons"
        />
      }
      sx={{ '&.MuiListItem-root': listItemClass }}>
      <ListItemText id={id} primary={displayText} className="listItemTxt" sx={{ "& .MuiTypography-root": textClass}} />
    </ListItem>
  );
};

export default ListItemRadio;
