import React, { useState, useEffect } from 'react';
import LandingHeader from '../../organism/LandingHeader';
import Footer from '../../organism/Footer';
import styles from './index.module.scss';
import '../InstallPWA/styles.scss';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '../../utils/useWindowSize';
import LandingPageLayout from '../../Layout/LandingPageLayout';
import { Grid } from '@mui/material';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import IphoneShare from '../../assets/IphoneShare.svg';
import pwaInstall1 from '../../assets/pwaInstall1.svg';
import pwaInstall2 from '../../assets/pwaInstall2.svg';
import DownloadCloseIcon from '../../assets/DownloadCloseIcon.svg';
import InstallDownArrow from '../../assets/InstallDownArrow.svg';
import InstallUpArrow from '../../assets/InstallUpArrow.svg';
import { useGetMyProfile } from '../../services/mutations';
import { routeUrls } from '../../utils/constants';
import { scaleByRatio } from '../../utils/helper';
import IconDownloadApp from '../../assets/IconDownloadApp.png';
import landingBannerMobile from '../../assets/landingBannerMobile.svg';
import AppLogo from '../../assets/AppLogo.svg';
import IconDownloadApple from '../../assets/IconDownloadApple.svg';
import IconDownloadGoogle from '../../assets/IconDownloadGoogle.svg';
import landingPageMobile from '../../assets/landingPageMobile.svg';
import DownloadPlusIcon from '../../assets/DownloadPlusIcon.svg';
import MacAddIcon from '../../assets/MacAddIcon.svg';
import landingPageCheckBoxFill from '../../assets/landingPageCheckBoxFill.svg';
import accordionExpand from '../../assets/accordionExpand.svg';
import accordionCollapse from '../../assets/accordionCollapse.svg';
import accordionIcon from '../../assets/accordionIcon.svg';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import InputField from '../../atoms/InputField';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';

import {
  landingPage_how_does_it_work_desc,
  landingPage_capabilities,
  landingPageFAQ,
} from '../../utils/constants';
import DashboardPage from '../DashboardPage';

const LandingPage = () => {
  const nav = useNavigate();
  const [expanded, setExpanded] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [height, width] = useWindowSize();
  const [getwidth, setWidth] = useState(0);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showSplash, setSplash] = useState(true);
  const [showIosDownload, setShowIosDownload] = useState(false);
  const [showIpad, setShowIpad] = useState(false);
  const [showIphone, setShowIphone] = useState(false);
  const [showMac, setShowMac] = useState(false);
  const [isIPad, setIsIPad] = useState(false);
  const [isIPhone, setIsIPhone] = useState(false);
  const [IsMacBook, setIsMacBook] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    setIsIPad(!!userAgent.match(/iPad/i));
    setIsIPhone(!!userAgent.match(/iPhone/i));
    setIsMacBook(!!userAgent.match(/Macintosh/i));
  }, []);

  const handleDownloadAppClick = () => {
    if (isIPhone === true) {
      setShowIphone(true);
      setShowIosDownload(true);
      window.scrollTo(0, 0);
    }
    else if (IsMacBook === true) {
      setShowMac(true);
      setShowIosDownload(true);
      window.scrollTo(0, 0);
    }
    else if (isIPad === true) {
      setIsIPad(true);
      setShowIosDownload(true);
      window.scrollTo(0, 0);
    }
    else {
      if (deferredPrompt) {
        try {
          deferredPrompt.prompt();
          deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
            } else {
              console.log('User dismissed the A2HS prompt');
            }
            setDeferredPrompt(null);
          });
        } catch (error) {
          console.error('Error prompting installation:', error);
        }
      } else {
        toast.error('App already installed');
      }
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setTimeout(() => {
        setSplash(false);
        window.location.href = '/home';
      }, 2000);
    } else {
      setTimeout(() => {
        setSplash(false);
      }, 2000);
    }
  }, []);

  useEffect(() => {
    const beforeInstallPromptHandler = (event) => {
      console.log('beforeinstallprompt event:', event);
      event.preventDefault();
      setDeferredPrompt(event);
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        beforeInstallPromptHandler,
      );
    };
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window?.innerWidth);
    }
  }, [width]);

  console.log('width', window?.innerWidth, width);

  const handleOnSubmitClicked = () => { };

  const handleMessageChange = (evt) => {
    setMessage(evt.target.value);
  };

  const handleEmailChange = (evt) => {
    setEmail(evt.target.value);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    console.log('handleChange: ', isExpanded);
    setExpanded(isExpanded ? panel : '');
  };

  return (
    <>
      {showSplash ? (
        <DashboardPage />
      ) : (
        <LandingPageLayout>
          <Box
            component="main"
            style={{ marginTop: `${scaleByRatio(100)}px`, background: '#000' }}>
            <Grid
              container
              direction="row"
              className={`${styles.banner} ${styles.landingHeader}`}
              sx={{
                padding: '100px',
                display: 'flex',
              }}>
              <Grid
                item
                alignItems="center"
                justifyItems={'right'}
                style={{
                  display: `flex`,
                  // paddingRight:`${scaleByRatio(61)}px`,
                  width: '40%',
                }}
                className={styles.firstHeaderGrid}>
                <Grid
                  container
                  direction="column"
                  style={{
                    gap: `${scaleByRatio(32)}px`,
                    // width: `${scaleByRatio(512)}px`
                    width: '100%',
                  }}>
                  <Grid
                    item
                    xs={12}
                    marginTop={'20px'}
                    marginBottom={'30px'}

                    justifyItems={'center'}
                    style={{
                      display: `flex`,
                      width: '100%',
                    }}>
                    <Typography
                      className={`${styles.bannerHeading}`}
                      style={{
                        fontSize: `${scaleByRatio(40)}px`,
                        width: '100%',
                      }}>
                      Let your friends <br/> help you find love, <br/> never swipe again!
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    marginBottom={'40px'}
                    justifyItems={'center'}
                    style={{
                      display: `flex`,
                      width: '100%',
                    }}>
                    <Typography
                      className={styles.bannerDescription}
                      style={{
                        fonstSize: `${scaleByRatio(24)}px`,
                        width: '100%',
                        // lineHeight:`${scaleByRatio(24)}px`,
                      }}>
                      After you create your profile, just add one or two trusted
                      friends to help you out. <br/><br/> Meadow is the positive, friendly
                      place to find love. <br/><br/> We offer a more natural way to meet
                      online. <br/><br/>
                      <span style={{
                        color:'#F8A233'
                      }}>
                         Things are less awkward when you’ve got friends to
                      help you out and it reflects how people meet in the real
                      world.
                      </span>
                    </Typography>
                  </Grid>

                  <Grid
                   border="1px solid #cccccc"
                   borderRadius="25px"
                   marginLeft={'-10px'}
                   marginRight={'-10px'}
                   paddingTop={'18px'}
                   paddingBottom={'13px'}
                  justifyContent='center'
                  >
                  <Grid
                    item
                    xs={12}
                    justifyContent={'center'}
                    style={{
                      display: `flex`,
                      width: '100%',
                    }}>
                    <Typography
                      className={styles.downloadApp}
                      style={{
                        fonstSize: `${scaleByRatio(25)}px`,
                        lineHeight: `${scaleByRatio(37.5)}px`,
                      }}>
                      Download The Meadow Network
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    justifyContent={'center'}
                    style={{
                      display: `flex`,
                      width: '100%',
                    }}>
                    <Typography
                    onClick={() => {
                          handleDownloadAppClick();
                        }}
                      className={styles.downloadAppInstall}
                      style={{
                        fonstSize: `${scaleByRatio(25)}px`,
                        lineHeight: `${scaleByRatio(37.5)}px`,
                      }}>
                      Download and install
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    justifyItems={'center'}
                    style={{
                      display: `flex`,
                      gap: '5px',
                      justifyContent: 'center',
                    }}>
                    <Typography
                      style={{
                        float: `left`,
                      }}
                      className={styles.appStoreImg}>
                      <img
                        src={AppLogo}
                        alt="appstore link"
                        style={{
                          height: `${scaleByRatio(57)}px`,
                        }}
                        onClick={() => {
                          handleDownloadAppClick();
                        }}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              
              </Grid>

              <Grid
                item
                justifyContent="right"
                alignItems={'center'}
                style={{
                  display: `flex`,
                  width: '55%',
                }}
                className={styles.secHeaderGrid}>
                <Typography textAlign={'right'}>
                  <img
                    src={landingBannerMobile}
                    alt={'mobile'}
                    style={{
                      // height: `${scaleByRatio(512)}px`,
                      // width: `${scaleByRatio(681)}px`
                      width: '100%',
                    }}
                  />
                </Typography>
              </Grid>
              

              <>
                {getwidth < 600 && (
                  <Button
                    variant="outlined"
                    className={styles.btnJoin}
                    onClick={() => nav(routeUrls.join)}
                    style={{
                      background: '#FFF',
                      fontFamily:'Gill Sans Nova',
                      // width: `${scaleByRatio(101)}px`,
                      fontSize: `${scaleByRatio(20)}px`,
                      lineHeight: `${scaleByRatio(24)}px`,
                      marginLeft: '10px',
                      minHeight: '56px',
                      fontWeight: 600,
                      borderRadius: '10px',
                    }}>
                    Sign in/Sign up
                  </Button>
                )}
              </>
            </Grid>
            <Grid
              container
              direction="column"
              className={styles.mobPadding}
              style={{
                padding: '10px 100px',
                backgroundColor: '#000000',
                color: '#FFFFFF',
              }}>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems={'center'}
                style={{
                  display: `flex`,
                  backgroundColor: '#000',
                  color: '#fff',
                }}>
                <Typography
                  textAlign={'center'}
                  className={styles.howDoesItWork}
                  sx={{
                    fontSize: `${scaleByRatio(32)}px`,
                    lineHeight: `${scaleByRatio(38.4)}px`,
                    padding: `${scaleByRatio(20)}px ${scaleByRatio(20)}px`,
                  }}>
                  How does it work?
                </Typography>
              </Grid>
              {landingPage_how_does_it_work_desc.map((desc, i) => {
                return (
                  <React.Fragment key={i}>
                    <Grid
                      item
                      xs={12}
                      justifyContent="center"
                      alignItems={'center'}
                      style={{
                        display: `flex`,
                        color: '#FFFFFF',
                        gap: `${scaleByRatio(20)}px`,
                        marginBottom: `${scaleByRatio(20)}px`,
                      }}>
                      <Typography
                        textAlign={'center'}
                        className={styles.howDoesItWorkDesc}
                        sx={{
                          fontSize: `${scaleByRatio(18)}px`,
                          lineHeight: `${scaleByRatio(24)}px`,
                        }}>
                        {desc}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                );
              })}
            </Grid>
            <Grid
              container
              fullWidth
              direction="row"
              sx={{
                padding: '10px 100px',
                backgroundColor: 'black',
                color: 'white',
              }}
              className={`${styles.chatterboxTxtWrapper} ${styles.mobPadding}`}
            // className='chatterboxTxtWrapper'
            >
              <Grid
                item
                xs={6}
                alignItems="center"
                justifyItems={'center'}
                style={{
                  display: `flex`,
                  // paddingRight:`${scaleByRatio(61)}px`,
                }}
                className={styles.chatterBoxImg}>
                <Typography textAlign={'center'}>
                  <img
                    src={landingPageMobile}
                    alt={'mobile'}
                    style={
                      {
                        // height: `${scaleByRatio(678)}px`,
                        // width: `${scaleByRatio(670)}px`
                      }
                    }
                  />
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                alignItems="center"
                style={{
                  display: `flex`,
                  paddingleft: `${scaleByRatio(10)}px`,
                }}
                className={styles.chatterBoxImgTxt}>
                <Grid container direction="column">
                  {landingPage_capabilities.map((capability, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Grid
                          container
                          direction="row"
                          style={{
                            marginTop: `${scaleByRatio(20)}px`,
                          }}>
                          <Grid
                            item
                            xs={1}
                            justifyItems={'center'}
                            style={{
                              display: `flex`,
                            }}>
                            <Typography>
                              <img
                                src={landingPageCheckBoxFill}
                                alt="checked"
                                style={{
                                  width: `${scaleByRatio(24)}px`,
                                  height: `${scaleByRatio(24)}px`,
                                }}
                                className={styles.checkBoxFontSize}
                              />
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={11}
                            justifyItems={'left'}
                            style={{
                              display: `flex`,
                            }}>
                            <Typography
                              style={{
                                fontSize: `${scaleByRatio(32)}px`,
                                lineHeight: `${scaleByRatio(38)}px`,
                              }}
                              className={styles.capabilityHeading}>
                              {capability.heading}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          style={{
                            marginBottom: `${scaleByRatio(20)}px`,
                            marginTop: `${scaleByRatio(20)}px`,
                          }}>
                          <Grid
                            item
                            xs={1}
                            justifyItems={'center'}
                            style={{
                              display: `flex`,
                            }}>
                            <Typography></Typography>
                          </Grid>
                          <Grid
                            item
                            xs={11}
                            justifyItems={'left'}
                            style={{
                              display: `flex`,
                            }}>
                            <Typography
                              className={styles.capabilitiesDescription}>
                              {capability.description}
                            </Typography>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              sx={{
                padding: '10px 100px',
                display: { xs: `flex`, sm: `none` },
              }}
              className={styles.mobPadding}>
              <Grid
                item
                xs={12}
                alignItems="center"
                justifyItems={'center'}
                style={{
                  display: `flex`,
                  // paddingRight:`${scaleByRatio(61)}px`,
                }}>
                <Typography textAlign={'center'}>
                  <img
                    src={landingPageMobile}
                    alt={'mobile'}
                    style={
                      {
                        // height: `${scaleByRatio(678)}px`,
                        // width: `${scaleByRatio(670)}px`
                      }
                    }
                    className={styles.landing2Img}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              className={styles.faqLandingWrapper}>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems={'center'}
                style={{
                  display: `flex`,
                  backgroundColor: '#000',
                  color: '#fff',
                }}>
                <Typography
                  textAlign={'center'}
                  className={styles.howDoesItWork}
                  sx={{
                    fontSize: `${scaleByRatio(32)}px`,
                    lineHeight: `${scaleByRatio(38.4)}px`,
                    padding: `${scaleByRatio(20)}px ${scaleByRatio(20)}px`,
                  }}>
                  FAQ
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems={'center'}
                style={{
                  display: `flex`,
                  gap: `${scaleByRatio(20)}px`,
                  marginBottom: `${scaleByRatio(20)}px`,
                  backgroundColor: '#000',
                  color: '#fff',
                }}>
                <Typography
                  textAlign={'center'}
                  className={styles.howDoesItWorkDesc}
                  sx={{
                    fontSize: `${scaleByRatio(16)}px`,
                    lineHeight: `${scaleByRatio(24)}px`,
                  }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Viverra nunc ante velit vitae. Est tellus vitae, nullam
                  lobortis enim. Faucibus amet etiam tincidunt rhoncus,
                  ullamcorper velit. Ullamcorper risus tempor, ac nunc libero
                  urna, feugiat.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              style={{
                backgroundColor: '#000',
                color: '#fff',
              }}
              sx={{
                paddingLeft: '100px',
                paddingRight: '100px',
                // paddingBottom: `${scaleByRatio(89)}px`,
              }}
              className={styles.mobPadding}>
              {landingPageFAQ.map((faq, i) => {
                return (
                  <Accordion
                    expanded={expanded === `panel${faq.id}`}
                    onChange={handleChange(`panel${faq.id}`)}
                    style={{
                      backgroundColor: '#000',
                      marginBottom: `${scaleByRatio(20)}px`,
                      //   border: `1px solid rgba(5, 35, 46, 0.10)`,
                      //   boxShadow: `0 0 0 0`,
                      backgroundColor: '#000',
                      color: '#fff',
                    }}
                    sx={{
                      '&.MuiAccordion-root:before': {
                        // backgroundColor: `rgba(0, 0, 0, 0.0)`,
                        backgroundColor: '#000',
                        color: '#fff',
                      },
                    }}>
                    <AccordionSummary
                      expandIcon={
                        expanded === `panel${faq.id}` ? (
                          <img
                            src={accordionExpand}
                            alt="expanded"
                            style={{
                              width: `${scaleByRatio(24)}px`,
                              height: `${scaleByRatio(24)}px`,
                              backgroundColor: '#000',
                              color: '#fff',
                            }}
                          />
                        ) : (
                          <img
                            src={accordionCollapse}
                            alt="collapse"
                            style={{
                              width: `${scaleByRatio(24)}px`,
                              height: `${scaleByRatio(24)}px`,
                            }}
                          />
                        )
                      }
                      aria-controls={`panel${faq.id}a-content`}
                      id={`panel${faq.id}a-header`}>
                      <Typography
                        style={{
                          float: 'left',
                          marginRight: `${scaleByRatio(15)}px`,
                        }}>
                        <img
                          src={accordionIcon}
                          alt="icon"
                          style={{
                            width: `${scaleByRatio(24)}px`,
                            height: `${scaleByRatio(24)}px`,
                          }}
                        />
                      </Typography>
                      <Typography
                        className={styles.accordionSummary}
                        style={{
                          fontSize: `${scaleByRatio(24)}px`,
                          lineHeight: `${scaleByRatio(28.8)}px`,
                          backgroundColor: '#000',
                          color: '#fff',
                        }}>
                        {faq.question}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        backgroundColor: '#000',
                        color: '#fff',
                      }}>
                      <Typography
                        className={styles.accordionDescription}
                        style={{
                          fontSize: `${scaleByRatio(18)}px`,
                          // lineHeight: `${scaleByRatio(24)}px`
                        }}>
                        {faq.answer}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
            <Grid
              container
              fullWidth
              direction="column"
              sx={{
                padding: '40px 100px',
                backgroundColor: '#000',
                color: '#fff',
              }}
              className={`${styles.landingContactWrapper} ${styles.mobPadding}`}>
              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems={'center'}
                style={{
                  display: `flex`,
                }}>
                <Typography
                  textAlign={'center'}
                  className={styles.howDoesItWork}
                  sx={{
                    fontSize: `${scaleByRatio(32)}px`,
                    lineHeight: `${scaleByRatio(38.4)}px`,
                    padding: `${scaleByRatio(20)}px ${scaleByRatio(20)}px`,
                  }}>
                  Contact US
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems={'center'}
                style={{
                  display: `flex`,
                  gap: `${scaleByRatio(20)}px`,
                  marginBottom: `${scaleByRatio(20)}px`,
                }}>
                <Typography
                  textAlign={'center'}
                  className={styles.howDoesItWorkDescHead}
                  sx={{
                    fontSize: `${scaleByRatio(16)}px`,
                    lineHeight: `${scaleByRatio(24)}px`,
                  }}>
                  Feel free to reach out to us with any questions or comments,
                  we love to get feedback and to help you in anyone we can. All
                  enquiries are answered in 24 hours.
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="left"
                alignItems={'center'}
                style={{
                  display: `flex`,
                  gap: `${scaleByRatio(20)}px`,
                  marginBottom: `${scaleByRatio(20)}px`,
                }}>
                <Typography
                  textAlign={'left'}
                  className={styles.howDoesItWorkDesc}
                  sx={{
                    fontSize: `${scaleByRatio(24)}px`,
                    lineHeight: `${scaleByRatio(29)}px`,
                  }}>
                  Email
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="left"
                alignItems={'center'}
                style={{
                  display: `flex`,
                  gap: `${scaleByRatio(20)}px`,
                  marginBottom: `${scaleByRatio(20)}px`,
                }}>
                <InputField
                  name="Email"
                  id={'Email'}
                  value={email}
                  placeholder={'Email (Required)'}
                  handleChange={(evt) => handleEmailChange(evt)}
                  type={'text'}
                  class_name={styles.name}
                  minHeightPX={`${scaleByRatio(40)}`}
                />
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="left"
                alignItems={'center'}
                style={{
                  display: `flex`,
                  gap: `${scaleByRatio(20)}px`,
                  marginBottom: `${scaleByRatio(20)}px`,
                }}>
                <Typography
                  textAlign={'left'}
                  className={styles.howDoesItWorkDesc}
                  sx={{
                    fontSize: `${scaleByRatio(24)}px`,
                    lineHeight: `${scaleByRatio(29)}px`,
                  }}>
                  Message
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                justifyContent="left"
                alignItems={'center'}
                style={{
                  display: `flex`,
                  gap: `${scaleByRatio(20)}px`,
                  marginBottom: `${scaleByRatio(20)}px`,
                }}>
                <InputField
                  name="Message"
                  id={'Message'}
                  value={message}
                  placeholder={'Message (Required)'}
                  handleChange={(evt) => handleMessageChange(evt)}
                  type={'text'}
                  class_name={styles.message}
                  minHeightPX={`${scaleByRatio(70)}`}
                  multiline={true}
                  rows={5}
                />
              </Grid>

              <Grid
                item
                xs={12}
                justifyContent="center"
                alignItems={'center'}
                style={{
                  display: `flex`,
                  gap: `${scaleByRatio(20)}px`,
                  marginBottom: `${scaleByRatio(20)}px`,
                }}
                className={styles.submitBtn}>
                <CustomButton
                  text={'Submit'}
                  variant={ButtonVariant.contained}
                  type={ButtonType.primary}
                  onClick={handleOnSubmitClicked}
                />
              </Grid>
            </Grid>
          </Box>
          {/* )} */}
        </LandingPageLayout>
      )}
      <>
        {
          showIosDownload === true &&
          <>
            {showIphone ? (
              <Modal
                open={showIosDownload}
                onClose={() => setShowIosDownload(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box
                  sx={{
                    position: 'absolute',
                    top: showIphone ? '65%' : showMac ? '20%' : '15%',
                    left: showMac || showIpad ? '70%' : '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '300px',
                    maxWidth: 400,
                    bgcolor: '#181B20',
                    boxShadow: 24,
                    p: 3,
                    borderRadius: '8px',
                  }}>
                  <img
                    src={AppLogo}
                    style={{ width: '108px', height: '32px' }}></img>
                  <IconButton
                    aria-label="close"
                    onClick={() => setShowIosDownload(false)}
                    sx={{
                      position: 'absolute',
                      top: 25,
                      right: 15,
                      color: '#22A887',
                      // borderRadius: '50%',
                      // border: '2px solid #22A887',
                      padding: '1px',
                    }}>
                    {/* <CloseIcon /> */}
                    <img src={DownloadCloseIcon} alt="icon" />
                  </IconButton>

                  <p style={{ color: '#BCBCBC' }}>
                    To install meadow on your phone, just follow these easy steps
                  </p>
                  <div className='phone-step-1'>
                    <div className='phone-step-1-head'>Step 1</div>
                    <div className='phone-step-1-desc'>Click on “ Share “  icon below</div>
                  </div>
                  {
                    showIphone &&
                    <div className='install-img-1'>
                      <img src={pwaInstall1} className='pwaInstall1 w-100' alt="imageNp" />
                    </div>
                  }
                  {
                    showMac &&
                    <div
                      className="d-flex"
                      style={{
                        marginBottom: '-55px',
                        marginLeft: '-5px',
                        display: 'flex',
                        marginRight: '20px',
                      }}>
                      <img
                        src={InstallUpArrow}
                        alt="img np"
                        style={{ marginRight: '7px' }}
                      />
                      <img src={MacAddIcon} alt="img np" />
                    </div>
                  }

                  {
                    isIPhone &&
                    <>
                      <div className='iphone-lower-div'>
                        <div className='div-1'>
                          <div className='div-1-parent'>
                            <div className='phone-step-1-head'>Step 2</div>
                            <div className='div-11'>Then scroll down and click on</div>
                          </div>
                          <div className='div-12'>
                            <div className='div-122'>“Add to Home Screen”</div>
                            <img
                              src={DownloadPlusIcon}
                              alt="download"
                            // style={{ marginBottom: '-5px' }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className='div-3'>
                        <img src={pwaInstall2} className='pwa-install-2' alt="" />
                      </div>
                    </>
                  }

                  {
                    showMac &&
                    <>
                      <ol style={{ color: 'white', marginLeft: '20px' }}>
                        <li style={{ listStyle: 'none' }}>
                          {
                            isIPhone ? 'Then Scroll down and click on' : 'Then click on ,'
                          }
                        </li>
                        {
                          showMac &&
                          <li style={{ listStyle: 'none' }}>
                            <span style={{ color: '#22A887' }}>
                              {showMac ? 'Install' : 'Add to Home Screen'}
                            </span>
                            {!showMac && (
                              <span>
                                <img
                                  src={DownloadPlusIcon}
                                  alt="download"
                                  style={{ marginBottom: '-5px' }}
                                />
                              </span>
                            )}
                          </li>
                        }

                        {
                          isIPhone &&

                          <li style={{ listStyle: 'none' }}>
                            <span style={{ color: '#FE0000' }}>
                              "Add to Home Screen"
                            </span>
                          </li>
                        }
                      </ol>
                      <Button
                        onClick={() => setShowIosDownload(false)}
                        variant="contained"
                        color="primary"
                        sx={{
                          mt: 2,
                          color: '#22A887',
                          // borderRadius: '10%',
                          border: '2px solid #22A887',
                          backgroundColor: 'transparent',
                          width: '300px',
                          height: '46px',
                        }}>
                        Close
                      </Button>
                    </>
                  }
                </Box>
              </Modal>
            ) : (
              <Modal
                open={showIosDownload}
                onClose={() => setShowIosDownload(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box
                  sx={{
                    position: 'absolute',
                    top: showIphone ? '65%' : showMac ? '20%' : '15%',
                    left: showMac || showIpad ? '70%' : '60%',
                    transform: 'translate(-50%, -50%)',
                    width: '300px',
                    maxWidth: 400,
                    bgcolor: '#181B20',
                    boxShadow: 24,
                    p: 3,
                    borderRadius: '8px',
                  }}>
                  <img
                    src={AppLogo}
                    style={{ width: '108px', height: '32px' }}></img>
                  <IconButton
                    aria-label="close"
                    onClick={() => setShowIosDownload(false)}
                    sx={{
                      position: 'absolute',
                      top: 25,
                      right: 15,
                      color: '#22A887',
                      padding: '1px',
                    }}>
                    <img src={DownloadCloseIcon} alt="icon" />
                  </IconButton>

                  <p style={{ color: 'white' }}>
                    {`To install meadow on your device, just click on the “
                      ${showMac ? `Install` : showIpad ? 'above Share' : ''
                      } ” icon`}
                  </p>
                  <div
                    className="d-flex"
                    style={{
                      marginBottom: '-55px',
                      marginLeft: '-5px',
                      display: 'flex',
                      marginRight: '20px',
                    }}>
                    <img
                      src={
                        showIphone ? InstallDownArrow : InstallUpArrow
                      }
                      style={{ marginRight: '7px' }}
                      alt=""
                    />
                    <img src={showMac ? MacAddIcon : IphoneShare} alt="" />
                  </div>
                  <ol style={{ color: 'white', marginLeft: '20px' }}>
                    <li style={{ listStyle: 'none' }}>
                      Then click on ,
                    </li>
                    <li style={{ listStyle: 'none' }}>
                      <span style={{ color: '#22A887' }}>
                        {showMac ? 'Install' : 'Add to Home Screen'}
                      </span>
                      {!showMac && (
                        <span>
                          <img
                            src={DownloadPlusIcon}
                            alt="download"
                            style={{ marginBottom: '-5px' }}
                          />
                        </span>
                      )}
                    </li>
                  </ol>

                  <Button
                    onClick={() => setShowIosDownload(false)}
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 2,
                      color: '#22A887',
                      border: '2px solid #22A887',
                      backgroundColor: 'transparent',
                      width: '300px',
                      height: '46px',
                    }}>
                    Close
                  </Button>
                </Box>
              </Modal>
            )}
          </>
        }
      </>
    </>
  );
};

export default LandingPage;
