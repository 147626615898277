import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { scaleByRatio } from "../../utils/helper";
import DownArrow from '../../assets/DownArrow.svg';
import UpArrow from '../../assets/UpArrow.svg';
import React, { useState, useEffect } from 'react';



const SelectDropDown = ({
    margin,
    id,
    value,
    handleChange,
    disabled = false,
    labelId="",
    menuItems=[]
}) => {


    const [openFlag, setOpenFlag] = useState(false);

    const handleFlagOpen = () => {
        setOpenFlag(true);
      };
    
      const handleFlagClose = () => {
        setOpenFlag(false);
      };


    return ( 
        <>
        <FormControl variant='standard' fullWidth margin={margin}>
            <Select
            labelId={labelId}
            id={id}
            value={value}
            onChange={handleChange}
            variant="filled"
            disabled={disabled}
            onClose={handleFlagClose}
            onOpen={handleFlagOpen}
            IconComponent={() => (
                openFlag? 
               <img
                src={UpArrow}
                alt="Up Arrow"
                style={{
                  height: `${scaleByRatio(15)}px`,
                  width: `${scaleByRatio(15)}px`,
                marginLeft: `${scaleByRatio(-40)}px`,
                marginRight:'35px',
                }}
                />:
                <img
                  src={DownArrow}
                  alt="Down Arrow"
                  style={{
                    height: `${scaleByRatio(15)}px`,
                    width: `${scaleByRatio(15)}px`,
                    marginLeft: `${scaleByRatio(-40)}px`,
                    marginRight:'35px',

                  }}
                  />
              )}
            sx={{
                "& .MuiSelect-filled":{
                    backgroundColor:'#F1F6F8',
                    fontFamily: 'Gill Sans Nova',
                    fontSize: `${scaleByRatio(14)}px`,
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    height: `${scaleByRatio(20)}px`,
                    padding: `${scaleByRatio(10)}px`,
                    paddingLeft: `${scaleByRatio(30)}px`,
                    borderBottom: `1px solid #C4C4C4`,
                    borderRadius: `${scaleByRatio(50)}px`,
                    color: '#9D9FA2',
                }
            }}
            >
                {menuItems.map((menu)=>{
                    return (
                        <MenuItem value={menu.value} style={{
                            padding: `${scaleByRatio(32)}px`
                        }}>{menu.displayText}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
        </>
     );


}
 
export default SelectDropDown;