import React, { useState, useEffect, useContext } from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Profile_Avatar from '../../assets/Profile_Avatar.png';
import dayjs from 'dayjs';
import { scaleByRatio } from '../../utils/helper';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import { ReportConvoCounter } from '../../utils/Context/reportCounter';
import { useGetReportUser } from '../../services/mutations';

const ConvosListItemFriend = ({
  isConvo = false,
  convo,
  handleProfileClicked,
  handleAcceptRejectCallback,
  setDirectChatChannel,
  setShowDirectMessage,
  setDirectChatConvo,
  setDirectChatMyProfile,
  setDirectChatOtherProfile,
  lastMessage,
}) => {
  const localStorage = useLocalStorage();
  const myProfileID = localStorage.getLocalStorageItem(
    localStorageKeys.profileId,
  );
  const [myProfile, setMyProfile] = useState(null);
  const [convoProfile, setConvoProfile] = useState(null);
  console.log('daterConvo', convo);
  const [otherProfile, setOtherProfile] = useState(null);
  const [showAcceptDeclineButtons, setShowAcceptDeclineButtons] =
    useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [introwStatus, setIntrowStatus] = useState(''); //'A', 'P', 'D'
  const [showLastMessage, setShowLastMessage] = useState(false);

  const [isDirectMessageEnabled, setIsDirectMessageEnabled] = useState(false);
  //const [showDirectMessage, setShowDirectMessage] = useState(false);
  const reportCounterData = useGetReportUser();

  const [convoCount, setConvoCount] = useContext(ReportConvoCounter);
  const onYesClicked = () => {
    console.log('Yes clicked');
    convoCount > 0 && setConvoCount(convoCount - 1);
    handleAcceptRejectCallback('A', myProfile?.id);
  };
  const onNoClicked = () => {
    console.log('No clicked');
    convoCount > 0 && setConvoCount(convoCount - 1);
    handleAcceptRejectCallback('D', myProfile?.id);
  };

  useEffect(() => {
    convo.convoSessionMembers.map((item) => {
      if (item.user.id !== myProfileID) {
        setConvoProfile(item.user);
      }
    });
    // if (convoProfile !== null) {
    //   console.log('convoProfile check', convoProfile);
    // }
  }, []);

  if (convoProfile == null) {
    setConvoProfile(convo?.prospective_dater);
  }

  console.log('myProfile', convoProfile);

  const startDirectMessaging = () => {
    console.log('startDirectMessaging :', isDirectMessageEnabled);
    if (isDirectMessageEnabled) {
      // console.log('start direct messaging');
      // setDirectChatChannel(convo?.id);
      // setShowDirectMessage(true);
      // setDirectChatMyProfile(myProfile);
      // // setDirectChatOtherProfile(otherProfile);
      console.log('start direct messaging');
      console.log('convoOtherProfile:', convo);
      setDirectChatChannel(convo?.id);
      setShowDirectMessage(true);
      setDirectChatMyProfile(myProfile);
      setDirectChatOtherProfile(otherProfile);
      setDirectChatConvo(convo);
    }
  };

  useEffect(() => {
    let showButtons = false;
    let message = '';
    let introw_status = '';
    let show_Last_Message = showLastMessage;
    if (otherProfile?.status === 'D') {
      message = 'Declined by ' + otherProfile?.user?.name;
      introw_status = 'D';
    } else if (myProfile?.status === 'A' && otherProfile?.status === 'P') {
      showButtons = false;
      introw_status = 'P';
      message = 'Approval pending.';
    } else if (myProfile?.status === 'A' && otherProfile?.status === 'A') {
      showButtons = false;
      introw_status = 'A';
      message = 'Show last message.';
      show_Last_Message = true;
    } else if (myProfile?.status === 'P') {
      showButtons = true;
      introw_status = 'P';
      message = '';
    } else if (myProfile?.status === 'D') {
      showButtons = false;
      introw_status = 'D';
      message = 'Declined by you.';
    }
    setShowAcceptDeclineButtons(showButtons);
    // setStatusMessage(message);
    setIntrowStatus(introw_status);
    setShowLastMessage(show_Last_Message);
  }, [myProfile, otherProfile]);
  useEffect(() => {
    let allowDirectMessage = true;
    console.log('last message:', lastMessage);
    convo?.convoSessionMembers?.forEach((sessionMember) => {
      if (sessionMember?.user?.id === myProfileID) {
        setMyProfile(sessionMember);
      } else {
        setOtherProfile(sessionMember);
      }
      if (sessionMember.status !== 'A') {
        allowDirectMessage = false;
      }
    });
    setIsDirectMessageEnabled(allowDirectMessage);
  }, []);

  const reportCount = () => {
    reportCounterData.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log('reportCounterData success:', response);
          console.log(
            'convo count',
            response?.data?.payload?.pending_introw_request,
          );

          setConvoCount(response?.data?.payload?.pending_introw_request);
          console.log(
            'elbow count',
            response?.data?.payload?.pending_introw_request,
          );
        },
      },
      // onError: (err) => {
      //   console.log('getMyProfile onError: ', err);
      //   setLoading(false);
      // }
    );
  };

  const handleDaterProfile = (e) => {
    e.stopPropagation();
    handleProfileClicked(convo);
  };

  // const handleProfile = () => {
  //   e.stopPropagation();
  //   if (
  //     convo?.elbow.request_status === 'A' ||
  //     convo?.elbow.request_status === 'P'
  //   ) {
  //     if (convoProfile?.id === convo?.prospective_dater?.id)
  //       handleProfileClicked(convo);
  //   }
  // };

  return (
    <>
      <Grid
        width= 'auto'  
        container
        direction="row"
        columnSpacing={0.5}
        className={styles.root}>
        <div className={styles.firstDiv}>
          <Grid
          marginLeft={'20px'}
          marginRight={'20px'}
          alignItems={'center'}
          justifyContent="center"
          >
          <Grid
            item
            alignItems={'center'}
            justifyContent="left"
            style={{ paddingTop: `${scaleByRatio(5)}px` }}>
            <Typography textAlign="left">
              <img
                src={
                  // convo?.elbow?.request_to_friend?.profile_pic
                  //   ? convo?.elbow?.request_to_friend?.profile_pic
                  //   :
                  convo?.prospective_dater?.profile_pic ||
                  // convo?.elbow?.request_to_friend ||
                  Profile_Avatar
                }
                alt="profile"
                width={scaleByRatio(72)}
                height={scaleByRatio(72)}
                style={{
                  borderRadius: `50%`,
                  // border: `2px solid ${
                  //   convo?.elbow?.interest_type === 'Y' ? '#02E242' : '#F90'
                  // }`,

                  cursor: `${
                    convo?.elbow.request_status === 'A' ? 'pointer' : 'default'
                  }`,
                }}
                onClick={() => {
                  // if (
                  //   convo?.elbow.request_status === 'A' ||
                  //   convo?.elbow.request_status === 'P'
                  // ) {
                  //   if (convoProfile?.id === convo?.prospective_dater?.id)
                  // handleProfileClicked(convo);
                  // }
                }}
              />
            </Typography>
          </Grid>

          <Grid
                item
                xs={12}
                alignItems={'center'}
                justifyContent='center'
                style={{ display: 'flex' }}>
                <Typography
                  fullwidth
                  textAlign="center"
                  className={styles.profileName}>
                  {`${convo?.prospective_dater?.user?.first_name}`} 
                  {/* ,${convo?.prospective_dater?.age} */}

                  {/* {convo?.prospective_dater?.age}  */}
                  {/* {
                    // convo?.elbow?.request_to_friend?.first_name
                    //   ? convo?.elbow?.request_to_friend?.first_name
                    //   :

                    convoProfile?.name
                      ? convoProfile?.name
                      : // `${convoProfile?.name}
                        //  ${
                        //     convoProfile?.age != null ? ',' : ''
                        //   }

                        // ${convoProfile?.age != null ? convoProfile?.age : ''}

                        `${convoProfile?.user?.first_name} `
                    // ${
                    //     convoProfile?.user?.last_name
                    //   }
                    //  ${convoProfile?.age != null ? ',' : ''} ${
                    //   convoProfile?.age != null ? convoProfile?.age : ''
                    // }`
                  } */}
                </Typography>
              </Grid>

              </Grid>
              {!isConvo &&
          <Grid
            item
            justifyContent="left"
            alignItems="start"
            style={{
              paddingTop: `${scaleByRatio(5)}px`,
              display: 'flex',
              cursor: `${isDirectMessageEnabled ? 'pointer' : 'default'}`,
            }}
            onClick={() => {
              showLastMessage
                ? startDirectMessaging()
                : console.log('On Click');
            }}>
            <Grid container direction="column" justifyContent="left" gap={1}>
              {/* <Stack direction="column" xs={12} spacing={scaleByRatio(1.8)} className={styles.stackClass} flexWrap="wrap" style={{display:"flex"}}> */}
             

              {!showAcceptDeclineButtons &&
                !showLastMessage &&
                convo?.session_type === 'DIC' && (
                  <Grid
                    container
                    xs={12}
                    alignItems="center"
                    justifyContent="left">
                    <Grid
                      item
                      xs={12}
                      alignItems={'start'}
                      style={{ display: 'flex', color: '#fff' }}>
                      <Typography
                        fullwidth
                        textAlign="left"
                        className={styles.introwStatus}
                        style={{
                          lineHeight: `${scaleByRatio(24)}px`,
                          float: `left`,
                          // color: '#fff',
                          color: '#76d9ff',
                          textWrap: 'nowrap',
                        }}>
                        {/* Chatting with dater */}
                      </Typography>
                      <Typography
                        fullwidth
                        textAlign="left"
                        className={styles.introwStatus}
                        style={{
                          lineHeight: `${scaleByRatio(24)}px`,
                          color: `${
                            // introwStatus === 'P' ? '#1BA6F4' : '#FE0000'
                            introwStatus === 'P' ? '#fff' : '#fff'
                          }`,
                        }}>
                        {statusMessage}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              {/* {showAcceptDeclineButtons && (
                <>
                  <Grid
                    container
                    xs={12}
                    alignItems="center"
                    justifyContent="left"
                    className={styles.accptDeclineBtns}>
                    <Grid item alignItems={'start'} style={{ display: 'flex' }}>
                      <Typography
                        textAlign="left"
                        className={styles.introwStatus}
                        style={{
                          lineHeight: `${scaleByRatio(24)}px`,
                          float: `left`,
                          color: '#76d9ff',
                        }}>
                        Introw Requested.
                      </Typography>
                    </Grid>
                    <Grid item alignItems={'start'} style={{ display: 'flex' }}>
                      <Grid item justifyContent="left">
                        <Typography
                          textAlign="left"
                          sx={{ marginRight: `8px` }}>
                          <CustomButton
                            text={'Decline'}
                            variant={ButtonVariant.contained}
                            type={ButtonType.no}
                            onClick={onNoClicked}
                          />
                        </Typography>
                      </Grid>
                      <Grid item justifyContent="left">
                        <Typography
                          textAlign="left"
                          sx={{ marginRight: `8px` }}>
                          <CustomButton
                            text={'Accept'}
                            variant={ButtonVariant.contained}
                            type={ButtonType.yes}
                            onClick={onYesClicked}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )} */}

              {/* {convoProfile?.id !== convo?.prospective_dater?.id && (
                <Grid
                  container
                  xs={12}
                  alignItems="center"
                  justifyContent="left">
                  <Grid
                    item
                    xs={12}
                    alignItems={'start'}
                    style={{ display: 'flex', color: '#fff' }}>
                    <Typography
                      fullwidth
                      textAlign="left"
                      className={styles.introwStatus}
                      style={{
                        lineHeight: `${scaleByRatio(24)}px`,
                        float: `left`,
                        // color: '#fff',
                        marginTop: `${scaleByRatio(30)}px`,
                        color: '#76d9ff',
                        textWrap: 'nowrap',
                      }}>
                      Chatting with
                    </Typography>
                  </Grid>
                </Grid>
              )} */}

              {showLastMessage && lastMessage?.file && (
                <Grid

                  container
                  xs={12}
                  alignItems="center"
                  justifyContent="left">
                  <Grid
                    item
                    xs={12}
                    alignItems={'start'}
                    style={{ display: 'flex' }}>
                    <Typography
                      fullwidth
                      textAlign="left"
                      className={styles.introwStatus}
                      style={{
                        lineHeight: `${scaleByRatio(24)}px`,
                        float: `left`,
                      }}>
                      <img
                        src={lastMessage?.file?.url}
                        alt={''}
                        width={scaleByRatio(50)}
                      />
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {showLastMessage && !lastMessage?.file && (
                <Grid 
                  container
                  xs={12}
                  alignItems="center"
                  justifyContent="left">
                  {/* <Grid color={'#76d9ff'} style={{ textWrap: 'nowrap' }}>
                    {convo?.session_type === 'DIC' && `Chatting with Dater`}
                  </Grid> */}
                  <Grid
                    item
                    xs={12}
                    alignItems={'start'}
                    style={{ display: 'flex' }}>
                    <Typography
                      fullwidth
                      textAlign="left"
                      className={styles.introwStatus}
                      style={{
                        lineHeight: `${scaleByRatio(24)}px`,
                        float: `left`,
                        color: '#fff',
                        width: '100px',
                        overflow: 'hidden',
                        textWrap: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}>
                      {lastMessage?.message}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {/* </Stack> */}
            </Grid>
          </Grid> }
        </div>
        {!isConvo &&
        <div className={styles.secDiv}>
          <Grid
            item
            
            justifyContent="right"
            alignItems="start"
            style={{
              paddingTop: `${scaleByRatio(5)}px`,
              display: 'flex',
              cursor: `${isDirectMessageEnabled ? 'pointer' : 'default'}`,
            }}
            onClick={() => {
              showLastMessage
                ? startDirectMessaging()
                : console.log('On Click');
            }}>
            <Typography textAlign="left" className={styles.date}>
              {lastMessage
                ? lastMessage?.time
                : dayjs(convo?.created_on).format('DD/MM/YY HH:mm')}
            </Typography>
          </Grid>
          {/* {(convoProfile?.id !== convo?.prospective_dater?.id ||
            (!showAcceptDeclineButtons &&
              !showLastMessage &&
              convo?.elbow?.request_to_friend != null)) && (
            <Grid>
              <Grid
                item
                display={'flex'}
                justifyContent={'center'}
                // style={{ marginLeft: `${scaleByRatio(30)}px` }}
                onClick={handleDaterProfile}>
                <Grid
                  item
                  xs={2}
                  sm={1.2}
                  alignItems={'center'}
                  justifyContent="right"
                  style={{ paddingTop: `${scaleByRatio(10)}px` }}>
                  <Typography textAlign="left">
                    <img
                      src={
                        convo?.prospective_dater?.profile_pic || Profile_Avatar
                      }
                      alt="profile"
                      width={scaleByRatio(60)}
                      height={scaleByRatio(70)}
                      // style={{
                      //   borderRadius: `8px`,
                      //   border: `2px solid ${
                      //     convo?.elbow?.interest_type === 'Y'
                      //       ? '#02E242'
                      //       : '#F90'
                      //   }`,

                      //   // cursor:`${(convo?.elbow.request_status === 'A')?'pointer':'default'}`
                      // }}
                      onClick={() => {
                        // if(convo?.elbow.request_status === 'A'){
                        //     handleProfileClicked(convo)
                        // }
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
              {(convoProfile?.id !== convo?.prospective_dater?.id ||
                (!showAcceptDeclineButtons &&
                  !showLastMessage &&
                  convo?.elbow?.request_to_friend != null)) && (
                <Grid
                  color={'#fff'}
                  display={'flex'}
                  style={{
                    width: 'auto',
                    alignItems: 'center',
                    float: 'right',
                    gap: '5px',
                  }}>
                  <Grid className={styles.friendOf}>Friend of </Grid>

                  <Grid
                    item
                    onClick={handleDaterProfile}
                    className={styles.dater_profileName}
                    // style={{ marginLeft: `${scaleByRatio(15)}px` }}
                  >
                    {`${
                      convo?.prospective_dater?.user?.first_name?.split(' ')[0]
                    }`}
                  </Grid>
                </Grid>
              )}
            </Grid>
          )} */}
        </div>
      }
      </Grid>

      {/* {showDirectMessage && (
            <DirectChat
            myProfile={myProfile}
            otherProfile={otherProfile}
            channel={convo?.id}
            onBackClicked={onDirectChatBackClicked}
            />
        )} */}
    </>
  );
};

export default ConvosListItemFriend;
