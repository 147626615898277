import styles from './index.module.scss';
import { Grid, Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout';
import CentralGridLayout from '../../Layout/CentralGridLayout';
import AppLogoWithTagLine from '../../assets/AppLogoWithTagLine.svg';
import AppLogo from '../../assets/AppLogoLarge.svg';
import AppLogoSmall from '../../assets/AppLogoMin.svg';
import { Link } from 'react-router-dom';
import { routeUrls, startPageFlow } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import { scaleByRatio } from '../../utils/helper';

const Start = () => {
  const nav = useNavigate();
  const localStorageItem = useLocalStorage();

  return (
    <CentralContentPageLayout>
      <CentralGridLayout>
        {/* <Grid container direction="row" justifyContent="center" alignItems="flex-end"> */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end"
          // marginBottom={2}
        >
          <Grid item className={styles.logoContainer} marginTop={-4}>
            {localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
              localStorageUserType.dater && (
              <img
                src={AppLogo}
                alt={'App icon'}
                className={styles.logo}
                style={{
                  height: `${scaleByRatio(90)}px`,
                  marginBottom: `${scaleByRatio(56)}px`,
                }}
              />
            )}

            {localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
              localStorageUserType.friend && (
              <img
                src={AppLogo}
                alt={'App icon'}
                className={styles.logo}
                style={{
                  height: `${scaleByRatio(116)}px`,
                  marginBottom: `${scaleByRatio(56)}px`,
                }}
              />
            )}
            {/* </Typography> */}
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-start"
          width={'90%'}
          marginBottom={'30px'}>
          <Grid item>
            <Typography
              // textAlign="center"
              className={styles.startHeader}
              style={
                {
                  // fontSize: `${scaleByRatio(32)}px`,
                  // lineHeight: `${scaleByRatio(40)}px`,
                }
              }>
              Let your friends help you find love, never swipe again!
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer}>
            {/* <Typography textAlign='center' className={styles.logoContainer}> */}
            {/* <img
              src={AppLogoWithTagLine}
              alt={'App icon'}
              className={styles.logo}
              style={{ height: `${scaleByRatio(135)}px` }}
            /> */}
            {/* </Typography> */}
          </Grid>
        </Grid>
        {localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
          localStorageUserType.dater && (
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                className={styles.btnCreateAccount}
                onClick={() => {
                  nav(routeUrls.login, {
                    state: { currentFlow: startPageFlow.signup },
                  });
                }}
                style={{
                  padding: `${scaleByRatio(12)}px ${scaleByRatio(32)}px`,
                  fontSize: `${scaleByRatio(16)}px`,
                  lineHeight: `${scaleByRatio(18)}px`,
                  color: '#E66433',
                }}>
                Create an Account
              </Button>
            </Grid>

          </Grid>
          
        )}


        {localStorageItem.getLocalStorageItem(localStorageKeys.userType) ===
          localStorageUserType.dater && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-end">
            <Grid item width={'329px'}>
              <Typography
                textAlign="center"
                className={styles.complianceText}
                style={{
                  fontSize: `${scaleByRatio(12)}px`,
                  lineHeight: `${scaleByRatio(16)}px`,
                }}>
                By signing up for Meadow you agree to our{' '}
                <Link
                  to={routeUrls.termsandconditions}
                  style={{ textDecoration: 'none' }}>
                  Terms of Services
                </Link>
                . Learn how we process your data in our{' '}
                <Link to={routeUrls.privacy} style={{ textDecoration: 'none' }}>
                  Privacy Policy
                </Link>
                .
              </Typography>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          justifyContent="left"
          marginTop={10}
          alignItems="flex-end">
          <Grid item>
            <Button
              variant="contained"
              className={styles.btnSignin}
              onClick={() => {
                nav(routeUrls.login, {
                  state: { currentFlow: startPageFlow.login },
                });
              }}
              style={{
                padding: `${scaleByRatio(12)}px ${scaleByRatio(35)}px`,
                fontSize: `${scaleByRatio(16)}px`,
                lineHeight: `${scaleByRatio(24)}px`,
              }}>
              Sign in
            </Button>
          </Grid>
        </Grid>
        {/* </Grid> */}
      </CentralGridLayout>
    </CentralContentPageLayout>
  );
};

export default Start;
