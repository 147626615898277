import { useMutation } from 'react-query';
import API from './request';
import { API_PATH } from '../utils/config';

export const useSendOTP = () => {
  return useMutation((data) => API.post(API_PATH.SEND_OTP, data));
};

export const useVerifyOTP = () => {
  return useMutation((data) => API.post(API_PATH.VERIFY_OTP, data));
};

export const useVerifyOTPSignUp = () => {
  return useMutation((data) => API.post(API_PATH.VERIFY_OTP_SIGNUP, data));
};

export const useSendOTPInviteFriend = () => {
  return useMutation((data) =>
    API.post(API_PATH.SEND_OTP_INVITE_FRIEND(data), data),
  );
};

export const useVerifyOTPInviteFriend = () => {
  return useMutation((data) =>
    API.post(API_PATH.VERIFY_OTP_INVITE_FRIEND(data), data),
  );
};

export const useGetProfileDetailsByUID = () => {
  return useMutation((data) =>
    API.get(API_PATH.SEND_OTP_INVITE_FRIEND(data), data),
  );
};

export const useGetMyProfile = () => {
  return useMutation((data) => API.get(API_PATH.GET_MY_PROFILE, data));
};

export const useDeleteMyProfile = () => {
  return useMutation((data) => API.delete(API_PATH.GET_MY_PROFILE, data));
};

export const useGetListOfPendingQuestions = () => {
  return useMutation((data) => API.get(API_PATH.GET_PENDING_QUESTIONS, data));
};

export const useGetQuestionDetailsById = () => {
  return useMutation((data) =>
    API.get(API_PATH.GET_QUESTION_DETAILS_BY_ID(data), data),
  );
};

export const usePostMultiChoiceAnswer = () => {
  return useMutation((data) =>
    API.post(
      API_PATH.POST_MULTICHOICE_ANSWER(data.id),
      data.multiChoiceAnswerData,
    ),
  );
};

export const usePatchMultiChoiceAnswer = () => {
  return useMutation((data) =>
    API.patch(
      API_PATH.POST_MULTICHOICE_ANSWER(data.id),
      data.multiChoiceAnswerData,
    ),
  );
};

export const usePatchotherSingleChoiceAnswer = () => {
  return useMutation((data) =>
    API.patch(API_PATH.PATCH_SINGLECHOICE_ANSWER(data.id), data),
  );
};
export const usePatchSingleChoiceAnswer = () => {
  return useMutation((data) =>
    API.patch(API_PATH.PATCH_SINGLECHOICE_ANSWER(data.id), data.answer),
  );
};

export const usePostSingleChoiceAnswer = () => {
  return useMutation((data) =>
    API.post(API_PATH.POST_SINGLECHOICE_ANSWER, data),
  );
};

export const useGetQuestionResponse = () => {
  return useMutation((data) =>
    API.get(API_PATH.GET_QUESTION_RESPONSE(data.id), data),
  );
};

export const useGetResponseForAllQuesionsByUser = () => {
  // THIS WILL FETCH ALL THE ANSWERS PROVIDED BY THE USER
  return useMutation((data) =>
    API.get(API_PATH.GET_ALL_QUESTION_RESPONSE_BY_USER(data.user), data),
  );
};

export const useUpdateMyProfile = () => {
  return useMutation((data) => API.patch(API_PATH.GET_MY_PROFILE, data));
};

export const usePostOtherImages = () => {
  return useMutation((data) => API.post(API_PATH.POST_OTHER_IMAGES, data));
};

export const useDeleteImages = () => {
  return useMutation((data) =>
    API.delete(API_PATH.DELETE_IMAGE(data.id), data),
  );
};

export const useGetAllQuestions = () => {
  return useMutation((data) => API.get(API_PATH.GET_ALL_QUESTIONS, data));
};

export const useGetUsersFriendList = () => {
  return useMutation((data) =>
    API.get(API_PATH.GET_ALL_FRIENDS(data.dater), data),
  );
};

export const usePostAddFriend = () => {
  return useMutation((data) => API.post(API_PATH.ADD_FRIEND, data));
};

export const useDeleteFriend = () => {
  return useMutation((data) =>
    API.delete(API_PATH.DELETE_FRIEND(data.id), data),
  );
};

export const usePatchEditFriend = () => {
  return useMutation((data) =>
    API.patch(API_PATH.EDIT_FRIEND(data.id), data.data),
  );
};

export const useGetMyDaters = () => {
  return useMutation((data) => API.get(API_PATH.GET_MY_DATERS, data));
};

export const useGetActiveDater = () => {
  return useMutation((data) => API.get(API_PATH.GET_ACTIVE_DATER, data));
};

export const usePostActiveDate = () => {
  return useMutation((data) =>
    API.post(API_PATH.POST_ACTIVE_DATER(data.friend_id), data),
  );
};

export const useGetDatingPreferenceQuestions = () => {
  return useMutation((data) =>
    API.get(API_PATH.DATING_PREFERENCE_QUESTION_CHOICES, data),
  );
};

export const useGetCustomDatingPreferenceQuestions = () => {
  return useMutation((data) =>
    API.get(API_PATH.CUSTOM_DATING_PREFERENCE, data),
  );
};

export const usePatchCustomDatingPreferenceQuestions = () => {
  return useMutation((data) =>
    API.patch(API_PATH.CUSTOM_DATING_PREFERENCE, data),
  );
};

export const usePostDatingPreference = () => {
  //post dating preference answers single choice and multiple choice
  return useMutation((data) => API.post(API_PATH.DATING_PREFERENCE, data));
};

export const useGetDatingPreferenceAnswers = () => {
  //post dating preference answers single choice and multiple choice
  return useMutation((data) => API.get(API_PATH.DATING_PREFERENCE, data));
};

//--- explore dater

export const useGetExploreDaterUserProfileIDList = () => {
  return useMutation((data) =>
    API.get(API_PATH.EXPLORE_DATER_PROFILE_LIST, data),
  );
};
export const useGetUserProfileByID = () => {
  return useMutation((data) =>
    API.get(API_PATH.USER_PROFILE_BY_ID(data), data),
  );
};
export const usePostProspectiveDater = () => {
  return useMutation((data) => API.post(API_PATH.ADD_PROSPECIVE_DATER, data));
};

export const usePostViewHistory = () => {
  return useMutation((data) =>
    API.post(API_PATH.PROSPECTIVE_DATER_VIEW_HISTORY, data),
  );
};

export const useGetExploreDaterUserProfileIDListByID = () => {
  return useMutation((data) =>
    API.get(API_PATH.EXPLORE_DATER_PROFILE_LIST_BY_ID(data), data),
  );
};
export const usePostProspectiveDaterById = () => {
  return useMutation((data) =>
    API.post(API_PATH.ADD_PROSPECTIVE_DATER_BY_ID(data), data),
  );
};
export const useGetOthersFriendList = () => {
  return useMutation((data) =>
    API.get(API_PATH.GET_OTHER_FRIENDS_LIST(data), data),
  );
};

export const usePostReachOutForElbow = () => {
  return useMutation((data) => API.post(API_PATH.REACH_OUT_FOR_ELBOW, data));
};

export const useGetElbowList = () => {
  return useMutation((data) => API.get(API_PATH.GET_ELBOW_LIST, data));
};

export const usePatchElbowStatus = () => {
  return useMutation((data) => API.patch(API_PATH.ELBOW_STATUS(data), data));
};

//----- CONVOS
export const useGetConvosList = () => {
  return useMutation((data) => API.get(API_PATH.GET_CONVOS_LIST(data), data));
};

export const usePostMakeIntrow = () => {
  return useMutation((data) => API.post(API_PATH.MAKE_INTROW(data), data));
};

export const usePatchDeclineIntrow = () => {
  return useMutation((data) => API.patch(API_PATH.DECLINE_INTROW(data), data));
};

export const usePatchAcceptDeclineConvo = () => {
  return useMutation((data) =>
    API.patch(API_PATH.CONVO_ACCEPT_DECLINE(data), data),
  );
};
//--chatter box
export const useGetPropectiveDater = () => {
  return useMutation((data) =>
    API.get(API_PATH.CHATTER_BOX_PROSPECTIVE_DATER(data), data),
  );
};

export const useGetChatterBoxDetailsByProfileID = () => {
  return useMutation((data) =>
    API.get(API_PATH.GET_CHATTERBOX_DETAILS_BY_USER_PROFILE_ID(data), data),
  );
};

export const usePatchChatterBoxProspectiveDaterAcceptanceStatus = () => {
  return useMutation((data) =>
    API.patch(API_PATH.PROSPECTIVE_DATER_ACCEPTANCE_STATUS(data), data),
  );
};

export const usePostBlockUser = () => {
  return useMutation((data) => API.post(API_PATH.POST_BLOCK_USER(data), data));
};

export const useGetBlockUserStatus = () => {
  return useMutation((data) => API.get(API_PATH.BLOCK_USER_STATUS(data), data));
};

export const usePatchEndIntrow = () => {
  return useMutation((data) => API.patch(API_PATH.END_INTROW(data), data));
};

export const useGetNotificationList = () => {
  return useMutation((data) => API.get(API_PATH.GET_NOTIFCATION_LIST, data));
};

export const usePatchNotification = () => {
  return useMutation((data) =>
    API.patch(API_PATH.PATCH_NOTIFICATION(data), data),
  );
};

export const usePostReportUser = () => {
  return useMutation((data) => API.post(API_PATH.REPORT_USER, data));
};
export const useGetReportUser = () => {
  return useMutation((data) => API.get(API_PATH.REPORT_USER, data));
};

export const usePostFeedback = () => {
  return useMutation((data) => API.post(API_PATH.FEEDBACK, data));
};

export const useGetFeedback = () => {
  return useMutation((data) => API.get(API_PATH.FEEDBACK, data));
};

export const usePostRequestCount = () => {
  return useMutation((data) => API.post(API_PATH.REQUEST_COUNT, data));
};

export const useGetRequestCount = () => {
  return useMutation((data) => API.get(API_PATH.REQUEST_COUNT, data));
};

export const usePostShareApp = () => {
  return useMutation((data) => API.post(API_PATH.SHARE_APP, data));
};

export const useGetFriendActivity = () => {
  return useMutation((data) =>
    API.get(API_PATH.GET_FRIEND_ACTIVITY(data), data),
  );
};

//FRIEND_CONVO
export const useGetFriendConvo = () => {
  return useMutation((data) => API.get(API_PATH.FRIEND_CONVO(data), data));
};

//DATER_REQUEST_INTROW
export const usePostDaterRequestIntrow = () => {
  return useMutation((data) =>
    API.post(API_PATH.DATER_REQUEST_INTROW(data), data),
  );
};

//CLEAR_REQUEST_COUNT
export const usePatchClearRequestCount = () => {
  return useMutation((data) =>
    API.patch(API_PATH.CLEAR_REQUEST_COUNT(data), data),
  );
};