import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout';
import CentralGridLayout from '../../Layout/CentralGridLayout';
import Loader from '../../molecule/Loader';
import {
  useGetListOfPendingQuestions,
  useGetQuestionDetailsById,
  usePostMultiChoiceAnswer,
  usePatchMultiChoiceAnswer,
  usePatchSingleChoiceAnswer,
  usePostSingleChoiceAnswer,
  useGetQuestionResponse,
  useGetResponseForAllQuesionsByUser,
} from '../../services/mutations';
import {
  localStorageKeys,
  useLocalStorage,
} from '../../utils/localStorageItems';
import {
  MyProfileQuestionsSequence,
  AnswerType,
  routeUrls,
} from '../../utils/constants';
import QuestionGender from '../../organism/QuestionGender';
import Questionare from '../../organism/Questionaire';
import QuestionHeight from '../../organism/QuestionHeight';
import KidsPreference from '../../organism/KidsPreference';
import UploadPhotos from '../../organism/UploadPhotos';
import Location from '../../organism/Location';
import ProfileWorkData from '../../organism/ProfileWorkData';
import ProfilePetData from '../../organism/ProfilePetData';

const MyProfileQuestions = () => {
  const localStorageItem = useLocalStorage();
  const nav = useNavigate();

  const getListOfPendingQuestions = useGetListOfPendingQuestions();
  const getQuestionDetailsById = useGetQuestionDetailsById();
  const patchSingleChoiceAnswer = usePatchSingleChoiceAnswer();
  const postSingleChoiceAnswer = usePostSingleChoiceAnswer();
  const postMultiChoiceAnswer = usePostMultiChoiceAnswer();
  const patchMultiChoiceAnswer = usePatchMultiChoiceAnswer();
  const getQuestionResponse = useGetQuestionResponse();
  const getResponseForAllQuesionsByUser = useGetResponseForAllQuesionsByUser();

  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(null);
  const [error, setError] = useState('');
  const [pendingQuestionList, setPendingQuestionList] = useState([]);
  const [currentSequenceofQuestions, setCurrentSequenceOfQuestions] = useState(
    [],
  );
  const [question, setQuestion] = useState(null);
  const [questionDetails, setQuestionDetails] = useState(null);
  const [storedAnswerDetails, setStoredAnswerDetails] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [initialActiveStep, setInitialActiveState] = useState(1);
  const [followUpQuestions, setFollowUpQuestions] = useState([]);
  const [isVisibleInProfile, setIsVisibleInProfile] = useState(false);

  const getPendingQuestionList = () => {
    setLoading(true);
    getListOfPendingQuestions.mutate(
      {},
      {
        onSuccess: (response) => {
          console.log('getListOfPendingQuestions onSuccess: ', response);
          let _questionList = response?.data?.payload?.results || null;
          if (_questionList && _questionList.length > 0) {
            setPendingQuestionList(_questionList);
            setLoading(false);
          } else {
            setLoading(false);
          }
        },
        onError: (err) => {
          setLoading(false);
          console.log('getPendingQuestionByIdentifier error:', err);
        },
      },
    );
  };
  const prepareCurrentSequence = () => {
    //let seledtedItem = null;
    let sequence = [];
    MyProfileQuestionsSequence?.forEach((item) => {
      let _question = null;
      if (Array.isArray(item.identifier) && item.identifier.length > 0) {
        _question = pendingQuestionList.find((question) => {
          return question.identifier === item.identifier[0];
        });
      } else {
        _question = pendingQuestionList.find((question) => {
          return question.identifier === item.identifier;
        });
      }

      if (_question) {
        // seledtedItem = item;
        sequence.push(item);
      }
    });
    setCurrentSequenceOfQuestions(MyProfileQuestionsSequence);
    if (sequence.length > 0) {
      setActiveStep(sequence[0].step);
      setInitialActiveState(sequence[0].step);
    } else {
      console.log('prepareCurrentSequence: No questions found');
      setLoading(true);
      setActiveStep(8); // upload photo
    }
  };

  const getQuestionDetailsByIdentifier = (_identifier) => {
    setLoading(true);
    let _question = pendingQuestionList.find((question) => {
      return question.identifier === _identifier;
    });
    setQuestion(_question);
    let qData = {
      id: _question?.id,
    };
    getQuestionDetailsById.mutate(qData, {
      onSuccess: (response) => {
        let _questionDetails = response?.data?.payload;
        setQuestionDetails(_questionDetails);
        console.log('question details: ', response?.data?.payload);
        //get answers

        if (_questionDetails?.choice_type === AnswerType.SC) {
          let answerData = {
            id: _question?.patchId,
          };
          getQuestionResponse.mutate(answerData, {
            onSuccess: (response) => {
              console.log('getQuestionResponse onSuccess : ', response);
              if (_questionDetails?.choice_type === AnswerType.SC) {
                let arrAnswerID = [];
                if (response?.data?.payload?.answer?.id) {
                  arrAnswerID.push(response?.data?.payload?.answer?.id);
                }
                setStoredAnswerDetails(arrAnswerID);
                setIsVisibleInProfile(
                  response?.data?.payload?.visible || false,
                );
              }
              setLoading(false);
            },
            onError: (error) => {
              console.log('getQuestionResponse onError: ', error);
              setLoading(false);
              setStoredAnswerDetails(null);
            },
          });
        }
        if (_questionDetails?.choice_type === AnswerType.MC) {
          let answerData = {
            limit: 200,
            offset: 0,
            user: localStorageItem.getLocalStorageItem(
              localStorageKeys.profileId,
            ),
          };
          getResponseForAllQuesionsByUser.mutate(answerData, {
            onSuccess: (response) => {
              let identifier = _question?.identifier;
              let arrAnswer = response?.data?.payload?.results;
              let arrSelectedAnswer = [];
              if (arrAnswer.length > 0) {
                arrAnswer.forEach((answerItem) => {
                  if (answerItem?.answer?.question?.identifier === identifier) {
                    arrSelectedAnswer.push(answerItem?.answer?.id);
                    setIsVisibleInProfile(answerItem?.visible || false);
                  }
                });
              }

              if (arrSelectedAnswer.length > 0) {
                setStoredAnswerDetails(arrSelectedAnswer);
              } else {
                setStoredAnswerDetails(null);
              }
              setLoading(false);
            },
            onError: (error) => {
              setLoading(false);
              setStoredAnswerDetails(null);
            },
          });
        }
        if (_questionDetails?.choice_type === AnswerType.TF) {
          let answerData = {
            limit: 200,
            offset: 0,
            user: localStorageItem.getLocalStorageItem(
              localStorageKeys.profileId,
            ),
          };
          getResponseForAllQuesionsByUser.mutate(answerData, {
            onSuccess: (response) => {
              let identifier = _question?.identifier;
              let arrAnswer = response?.data?.payload?.results;
              let selectedAnswer = '';
              if (arrAnswer.length > 0) {
                arrAnswer.forEach((answerItem) => {
                  if (answerItem?.answer?.question?.identifier === identifier) {
                    selectedAnswer = answerItem?.value;
                    setIsVisibleInProfile(answerItem?.visible || false);
                  }
                });
              }

              // if(selectedAnswer.length > 0){
              //     setStoredAnswerDetails(selectedAnswer)
              // }else{
              //     setStoredAnswerDetails(null)
              // }
              setStoredAnswerDetails(selectedAnswer);
              setLoading(false);
            },
            onError: (error) => {
              setLoading(false);
              setStoredAnswerDetails(null);
            },
          });
        }
      },
      onError: (error) => {
        setLoading(false);
        console.log('getQuestionDetailsById error: ', error);
      },
    });
  };
  const getQuestionDetailsByIdentifiers = async (_identifierArr) => {
    setLoading(true);
    let followUpQuestionDetails = [];
    for (let i = 0; i < _identifierArr.length; i++) {
      let _identifier = _identifierArr[i];

      let _question = pendingQuestionList.find((question) => {
        return question.identifier === _identifier;
      });
      let qData = {
        id: _question?.id,
      };
      let _questionDetails = {};
      await getQuestionDetailsById.mutateAsync(qData, {
        onSuccess: (response) => {
          // let _questionDetails = response?.data?.payload
          _questionDetails = response?.data?.payload;
          // setQuestionDetails(_questionDetails)
          console.log('question details: ', response?.data?.payload);
          //get answers
          if (
            _questionDetails?.choice_type === AnswerType.SC ||
            _questionDetails?.choice_type === AnswerType.MC
          ) {
            _questionDetails.setStoredAnswerDetails = [];
          } else {
            _questionDetails.setStoredAnswerDetails = '';
          }

          if (
            _questionDetails?.choice_type === AnswerType.SC &&
            _question?.patchId
          ) {
            let answerData = {
              id: _question?.patchId,
            };

            getQuestionResponse.mutateAsync(answerData, {
              onSuccess: (response) => {
                console.log('getQuestionResponse onSuccess : ', response);
                if (_questionDetails?.choice_type === AnswerType.SC) {
                  let arrAnswerID = [];
                  if (response?.data?.payload?.answer?.id) {
                    arrAnswerID.push(response?.data?.payload?.answer?.id);
                  }
                  // setStoredAnswerDetails(arrAnswerID)
                  _questionDetails.setStoredAnswerDetails = arrAnswerID;
                }
                // setLoading(false)
              },
              onError: (error) => {
                console.log('getQuestionResponse onError: ', error);
                //setLoading(false)
                //setStoredAnswerDetails(null)
                _questionDetails.setStoredAnswerDetails = null;
              },
              onSettled: (settled) => {
                console.log('settled: ', settled);
              },
            });
          }
          if (_questionDetails?.choice_type === AnswerType.MC) {
            let answerData = {
              limit: 200,
              offset: 0,
              user: localStorageItem.getLocalStorageItem(
                localStorageKeys.profileId,
              ),
            };
            getResponseForAllQuesionsByUser.mutateAsync(answerData, {
              onSuccess: (response) => {
                let identifier = _question?.identifier;
                let arrAnswer = response?.data?.payload?.results;
                let arrSelectedAnswer = [];
                if (arrAnswer.length > 0) {
                  arrAnswer.forEach((answerItem) => {
                    if (
                      answerItem?.answer?.question?.identifier === identifier
                    ) {
                      arrSelectedAnswer.push(answerItem?.answer?.id);
                    }
                  });
                }
                if (arrSelectedAnswer.length > 0) {
                  //setStoredAnswerDetails(arrSelectedAnswer)
                  _questionDetails.setStoredAnswerDetails = arrSelectedAnswer;
                } else {
                  // setStoredAnswerDetails(null)
                  _questionDetails.setStoredAnswerDetails = null;
                }
                // setLoading(false)
              },
              onError: (error) => {
                // setLoading(false)
                // setStoredAnswerDetails(null)
                _questionDetails.setStoredAnswerDetails = null;
              },
              onSettled: (settled) => {
                console.log('settled: ', settled);
              },
            });
          }
        },
        onError: (error) => {
          // setLoading(false)
          console.log('getQuestionDetailsById error: ', error);
        },
      });
      followUpQuestionDetails.push(_questionDetails);
    }
    setFollowUpQuestions(followUpQuestionDetails);
    setLoading(false);
    console.log(
      '>>>>>>>>>>>>>>>>>>>>> followUpQuestionDetails:',
      followUpQuestionDetails,
    );
  };

  const onClickCallBackGender = (selectedID) => {
    console.log('onClickCallBackGender: ', selectedID);
    // setSelectedGender(selectedID)
    let arr = [];
    arr.push(selectedID);

    setStoredAnswerDetails(arr);
  };
  const post_patch_singleChoice_answer = (answer) => {
    postSingleChoiceAnswer.mutate(answer, {
      onSuccess: (response) => {
        // set the patch id for question on successful posting
        let questionIndex = pendingQuestionList.findIndex(
          (questionItem) => questionItem.id === question.id,
        );
        if (questionIndex !== -1) {
          pendingQuestionList[questionIndex].patchId =
            response?.data?.payload?.id || null;
          setPendingQuestionList(pendingQuestionList);
        }

        setLoading(false);
        setActiveStep(activeStep + 1);
      },
      onError: (error) => {
        console.log('postSingleChoiceAnswer: ', error);
        if (error?.response?.status === 400) {
          let data = {
            id: question?.patchId,
            answer: answer,
          };
          patchSingleChoiceAnswer.mutate(data, {
            onSuccess: (response) => {
              setLoading(false);
              setActiveStep(activeStep + 1);
            },
            onError: (error) => {
              setLoading(false);
              setError('Something went wrong, please try again');
            },
          });
        } else {
          setLoading(false);
          setError('Something went wrong, please try again');
        }
      },
    });
  };

  const post_patch_multiple_choice_answer = (answer) => {
    setLoading(true);
    //call api to store answers

    postMultiChoiceAnswer.mutate(answer, {
      onSuccess: (response) => {
        let questionIndex = pendingQuestionList.findIndex(
          (questionItem) => questionItem.id === question.id,
        );
        if (questionIndex !== -1) {
          pendingQuestionList[questionIndex].patchId =
            response?.data?.payload?.id || null;
          setPendingQuestionList(pendingQuestionList);
        }

        setLoading(false);
        setActiveStep(activeStep + 1);
      },
      onError: (err) => {
        if (err?.response?.status === 400) {
          //answer.id = question?.patchId;
          patchMultiChoiceAnswer.mutate(answer, {
            onSuccess: (response) => {
              setLoading(false);
              setActiveStep(activeStep + 1);
            },
            onError: (error) => {
              setLoading(false);
              setError('Something went wrong, please try again');
            },
          });
        } else {
          setLoading(false);
          setError('Something went wrong, please try again');
        }
      },
    });
  };

  const postPatchFollowupAnswers = async (questionAnswerArr) => {
    setLoading(true);
    for (let i = 0; i < questionAnswerArr.length; i++) {
      let question = questionAnswerArr[i];
      if (question.choice_type === AnswerType.SC) {
        let selectedAnswerObj = question?.question_choices?.find((choice) => {
          return choice?.id === question.setStoredAnswerDetails[0];
        });
        let answer = {
          value: selectedAnswerObj?.value,
          visible: true,
          answer: selectedAnswerObj?.id,
        };
        await postSingleChoiceAnswer.mutate(answer, {
          onSuccess: (response) => {
            // set the patch id for question on successful posting
            let questionIndex = pendingQuestionList.findIndex(
              (questionItem) => questionItem.id === question.id,
            );
            if (questionIndex !== -1) {
              pendingQuestionList[questionIndex].patchId =
                response?.data?.payload?.id || null;
              setPendingQuestionList(pendingQuestionList);
            }
            setLoading(false);
            setActiveStep(activeStep + 1);
          },
          onError: (error) => {
            console.log('postSingleChoiceAnswer: ', error);
            if (error?.response?.status === 400) {
              let questionIndex = pendingQuestionList.findIndex(
                (questionItem) => questionItem.id === question.id,
              );
              let data = {
                id: pendingQuestionList[questionIndex].patchId,
                answer: answer,
              };
              patchSingleChoiceAnswer.mutate(data, {
                onSuccess: (response) => {
                  console.log('patchSingleChoiceAnswer success: ', response);
                  setLoading(false);
                  setActiveStep(activeStep + 1);
                },
                onError: (error) => {
                  console.log(error);
                  setError('Something went wrong, please try again');
                  setLoading(false);
                },
              });
            } else {
              setError('Something went wrong, please try again');
              setLoading(false);
            }
          },
        });
      }
      if (question.choice_type === AnswerType.MC) {
        let multiChoiceAnswerData = [];
        question.setStoredAnswerDetails.forEach((element) => {
          let answerObj = {
            value: '',
            visible: true,
            answer: element,
          };
          //find value
          let selectedAnswerObj = question?.question_choices.find((choice) => {
            return choice.id === element;
          });
          answerObj.value = selectedAnswerObj.value;
          multiChoiceAnswerData.push(answerObj);
        });
        let data = {
          id: question.id,
          multiChoiceAnswerData: multiChoiceAnswerData,
        };

        await postMultiChoiceAnswer.mutate(data, {
          onSuccess: (response) => {
            let questionIndex = pendingQuestionList.findIndex(
              (questionItem) => questionItem.id === question.id,
            );
            if (questionIndex !== -1) {
              pendingQuestionList[questionIndex].patchId =
                response?.data?.payload?.id || null;
              setPendingQuestionList(pendingQuestionList);
            }
            setLoading(false);
            setActiveStep(activeStep + 1);
          },
          onError: (err) => {
            if (error?.response?.status === 400) {
              patchMultiChoiceAnswer.mutate(data, {
                onSuccess: (response) => {
                  setLoading(false);
                  setActiveStep(activeStep + 1);
                },
                onError: (error) => {
                  setLoading(false);
                  setError('Something went wrong, please try again');
                },
              });
            } else {
              console.log('postMultiChoiceAnswer onError: ', err);
              setError('Something went wrong, please try again');
              setLoading(false);
            }
          },
        });
      }
    }
    // setLoading(false);
    // if(!error){
    //     setActiveStep(activeStep+1)
    // }
  };

  const onNextClickedGender = () => {
    console.log('onNextClickedGender: ', selectedGender);
    if (!storedAnswerDetails) {
      setError('Please select your gender. ');
    } else {
      //call update api for gender
      setError('');
      let selectedAnswerObj = questionDetails?.question_choices?.find(
        (choice) => {
          return choice?.id === storedAnswerDetails[0];
        },
      );
      let answer = {
        value: selectedAnswerObj?.value,
        visible: isVisibleInProfile,
        answer: selectedAnswerObj?.id,
      };

      post_patch_singleChoice_answer(answer);
    }
  };

  const onNextClickedSexuality = (answers, answerType) => {
    if (!answers || answers.length < 1) {
      setError('Please select your sexuality. ');
    } else {
      setError('');
      //call api to store answers
      let selectedAnswerObj = questionDetails?.question_choices?.find(
        (choice) => {
          return choice?.id === answers[0];
        },
      );
      let answer = {
        value: selectedAnswerObj?.value,
        visible: true,
        answer: selectedAnswerObj?.id,
      };
      post_patch_singleChoice_answer(answer);
    }
  };

  const onNextClickedEthnicity = (answers, answerType) => {
    if (answers && answers.length > 0) {
      let multiChoiceAnswerData = [];
      answers.forEach((element) => {
        let answerObj = {
          value: '',
          visible: isVisibleInProfile,
          answer: element,
        };
        //find value
        let selectedAnswerObj = questionDetails?.question_choices.find(
          (choice) => {
            return choice.id === element;
          },
        );
        answerObj.value = selectedAnswerObj.value;
        multiChoiceAnswerData.push(answerObj);
      });
      let data = {
        id: question.id,
        multiChoiceAnswerData: multiChoiceAnswerData,
      };
      post_patch_multiple_choice_answer(data);
    } else {
      setError('Please select your ethinicity.');
    }
  };

  const onNextClickedSpirituality = (answers, answerType) => {
    if (answers && answers.length > 0) {
      let multiChoiceAnswerData = [];
      answers.forEach((element) => {
        let answerObj = {
          value: '',
          visible: true,
          answer: element,
        };
        //find value
        let selectedAnswerObj = questionDetails?.question_choices.find(
          (choice) => {
            return choice.id === element;
          },
        );
        answerObj.value = selectedAnswerObj.value;
        multiChoiceAnswerData.push(answerObj);
      });
      let data = {
        id: question.id,
        multiChoiceAnswerData: multiChoiceAnswerData,
      };
      post_patch_multiple_choice_answer(data);
    } else {
      setError('Please select your spirituality.');
    }
  };
  const onNextClickedPloticalViews = (answers, answerType) => {
    if (!answers || answers.length < 1) {
      setError('Please select your political views. ');
    } else {
      setError('');
      //call api to store answers
      let selectedAnswerObj = questionDetails?.question_choices?.find(
        (choice) => {
          return choice?.id === answers[0];
        },
      );
      let answer = {
        value: selectedAnswerObj?.value,
        visible: true,
        answer: selectedAnswerObj?.id,
      };
      post_patch_singleChoice_answer(answer);
    }
  };

  const onNextClickedHeight = (val) => {
    // implement api , after making its anwer type text
    console.log('onNextClickedHeight: ', val);
    setActiveStep(activeStep + 1);
  };

  const onNextClickedKidsPreference = (questionAnswerArr) => {
    console.log('onNextClickedKidsPreference :', questionAnswerArr);
    postPatchFollowupAnswers(questionAnswerArr);
  };

  const onNextClickedPetsPreference = (questionAnswerArr) => {
    console.log('onNextClickedPetsPreference :', questionAnswerArr);
    postPatchFollowupAnswers(questionAnswerArr);
  };

  const onBackClicked = () => {
    console.log('onBackClicked');
    if (activeStep > initialActiveStep) {
      setActiveStep(activeStep - 1);
    }
  };
  const onNextHeightClicked = (heightInCm) => {
    console.log('onNextHeightClicked: ', heightInCm);
    console.log('questionDetails: ', questionDetails);

    let answer = {
      answer: questionDetails?.question_choices[0].id,
      visible: true,
      value: heightInCm,
    };

    post_patch_singleChoice_answer(answer);
  };
  const uploadPhotoSuccess = () => {
    setActiveStep(activeStep + 1);
    console.log(activeStep);
  };

  const onNextWorkDataClicked = (workData) => {
    console.log('onNextWorkDataClicked: ', workData);
    console.log('questionDetails: ', questionDetails);

    let answer = {
      answer: questionDetails?.question_choices[0].id,
      visible: true,
      value: workData,
    };
    console.log('ans...', answer);
    post_patch_singleChoice_answer(answer);
  };

  // const onNextPetDataClicked = (petData) => {
  //   console.log('onNextWorkDataClicked: ', petData);
  //   const answers = petData.map(({ id, value }) => ({ answer: id, value }));
  //   console.log('answers', answers);
  //   answers.forEach((answer) => post_patch_singleChoice_answer(answer));
  // };
  const locationCaptureSuccess = () => {
    console.log('location captured successfully');
    nav(routeUrls.home);
  };

  const uploadHeightSuccess = () => {
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    getPendingQuestionList();
  }, []);
  useEffect(() => {
    if (pendingQuestionList && pendingQuestionList.length > 0) {
      prepareCurrentSequence();
    } else {
      console.log('No pending questions');
    }
  }, [pendingQuestionList]);

  useEffect(() => {
    setIsVisibleInProfile(false);
    console.log('active state ', activeStep, currentSequenceofQuestions);
    // setFollowUpQuestions([])
    if (activeStep && activeStep > 0) {
      if (activeStep < 8) {
        if (activeStep === 5) {
          getQuestionDetailsByIdentifiers(
            currentSequenceofQuestions[activeStep - 1].identifier,
          );
        } else {
          console.log(
            'identifer ',
            currentSequenceofQuestions[activeStep - 1].identifier,
          );
          getQuestionDetailsByIdentifier(
            currentSequenceofQuestions[activeStep - 1].identifier,
          );
        }

        //getQuestionDetailsByIdentifier(currentSequenceofQuestions[activeStep-1].identifier)
      } else if (activeStep === 8) {
        setLoading(false); // for upload photos
      } else if (activeStep === 9) {
        getQuestionDetailsByIdentifier(
          currentSequenceofQuestions[activeStep - 1].identifier,
        );
        console.log(
          'identifer S-9 ',
          currentSequenceofQuestions[activeStep - 1].identifier,
        );
      } else if (activeStep === 10) {
        getQuestionDetailsByIdentifiers(
          currentSequenceofQuestions[activeStep - 1].identifier,
        );
        console.log(
          'identifer S-10 ',
          currentSequenceofQuestions[activeStep - 1].identifier,
        );
      }
      // else if(activeStep === (MyProfileQuestionsSequence[MyProfileQuestionsSequence.length-1].step+1)){
      //     nav(routeUrls.uploadPhotos)
      // }
    }
  }, [activeStep]);
  const onVisibleClick = (val) => {
    console.log('onVisibleClick: ', val);
    setIsVisibleInProfile(val);
  };
  return (
    <>
      <Loader open={loading} />
      <CentralContentPageLayout>
        {activeStep === 1 && !loading && (
          <CentralGridLayout>
            <QuestionGender
              displayText={question?.name}
              onBackClicked={onBackClicked}
              error={error}
              onNextClicked={onNextClickedGender}
              answerChoices={questionDetails?.question_choices}
              selectedValue={
                storedAnswerDetails && storedAnswerDetails.length > 0
                  ? storedAnswerDetails[0]
                  : null
              }
              onClickCallBack={onClickCallBackGender}
              showVisibility={true}
              isVisible={isVisibleInProfile}
              onVisibleClick={onVisibleClick}
              checkBoxLabel={'Visible on profile'}
            />
          </CentralGridLayout>
        )}
        {activeStep === 2 && !loading && (
          <CentralGridLayout>
            <Questionare
              questionText={question?.name}
              answerType={questionDetails?.choice_type}
              onBackClicked={onBackClicked}
              error={error}
              handleOnNextClicked={onNextClickedSexuality}
              answerOptions={questionDetails?.question_choices}
              showBackButton={true}
              selectedAnswer={storedAnswerDetails || []}
            />
          </CentralGridLayout>
        )}
        {activeStep === 3 && !loading && (
          <CentralGridLayout>
            <Questionare
              questionText={question?.name}
              answerType={questionDetails?.choice_type}
              onBackClicked={onBackClicked}
              error={error}
              handleOnNextClicked={onNextClickedEthnicity}
              answerOptions={questionDetails?.question_choices}
              showBackButton={true}
              questionColor={'#00597B'}
              selectedAnswer={storedAnswerDetails || []}
              showVisibility={true}
              isVisible={isVisibleInProfile}
              onVisibleClick={onVisibleClick}
              checkBoxLabel={'Visible on profile'}
            />
          </CentralGridLayout>
        )}
        {activeStep === 4 && !loading && (
          <CentralGridLayout>
            <QuestionHeight
              questionText={question?.name}
              answerType={AnswerType.TF}
              onBackClicked={onBackClicked}
              handleOnNextClicked={onNextHeightClicked}
              error={error}
              answer={storedAnswerDetails}
              showBackButton={true}
              btnSubmitText={'Next'}
            />
          </CentralGridLayout>
        )}
        {activeStep === 5 && !loading && followUpQuestions.length > 0 && (
          <CentralGridLayout>
            <KidsPreference
              arrQuestion={followUpQuestions}
              onBackClicked={onBackClicked}
              error={error}
              onNextClicked={onNextClickedKidsPreference}
              showBackButton={true}
            />
          </CentralGridLayout>
        )}
        {activeStep === 6 && !loading && (
          <CentralGridLayout>
            <Questionare
              questionText={question?.name}
              answerType={questionDetails?.choice_type}
              onBackClicked={onBackClicked}
              error={error}
              handleOnNextClicked={onNextClickedSpirituality}
              answerOptions={questionDetails?.question_choices}
              showBackButton={true}
              questionColor={'#00597B'}
              selectedAnswer={storedAnswerDetails || []}
            />
          </CentralGridLayout>
        )}
        {activeStep === 7 && !loading && (
          <CentralGridLayout>
            <Questionare
              questionText={question?.name}
              answerType={questionDetails?.choice_type}
              onBackClicked={onBackClicked}
              error={error}
              handleOnNextClicked={onNextClickedPloticalViews}
              answerOptions={questionDetails?.question_choices}
              showBackButton={true}
              questionColor={'#00597B'}
              selectedAnswer={storedAnswerDetails || []}
            />
          </CentralGridLayout>
        )}
        {activeStep === 8 && !loading && (
          <CentralGridLayout>
            <UploadPhotos
              showbackButton={true}
              setShowLoading={setLoading}
              onBackClicked={onBackClicked}
              onUploadSuccess={uploadPhotoSuccess}
              btnSubmitText={'Next'}
            />
          </CentralGridLayout>
        )}
        {activeStep === 9 && !loading && (
          <CentralGridLayout>
            <ProfileWorkData
              questionText={question?.name}
              answerType={AnswerType.TF}
              onBackClicked={onBackClicked}
              handleOnNextClicked={onNextWorkDataClicked}
              error={error}
              answer={storedAnswerDetails}
              showBackButton={true}
              btnSubmitText={'Next'}
            />
          </CentralGridLayout>
        )}

        {/* {activeStep === 10 && !loading && (
          <CentralGridLayout>
            <ProfilePetData
              arrQuestion={followUpQuestions}
              onBackClicked={onBackClicked}
              error={error}
              onNextClicked={onNextPetDataClicked}
              showBackButton={true}
              selectedValue={
                followUpQuestions && followUpQuestions.length > 0
                  ? followUpQuestions[0]
                  : null
              }
            />
          </CentralGridLayout>
        )} */}

        {activeStep === 10 && !loading && (
          <CentralGridLayout>
            <Location
              questionText={'Zip Code'}
              answerType={AnswerType.TF}
              onBackClicked={onBackClicked}
              handleOnNextClicked={() => console.log('location')}
              error={error}
              answer={''}
              onSuccess={locationCaptureSuccess}
            />
          </CentralGridLayout>
        )}
      </CentralContentPageLayout>
    </>
  );
};

export default MyProfileQuestions;
