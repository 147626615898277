import React, {useState} from 'react'
import styles from './index.module.scss'
import Loader from '../../molecule/Loader'
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout'
import CentralGridLayout from '../../Layout/CentralGridLayout'
import UploadPhotos from '../../organism/UploadPhotos'

const UploadPhotoPage = () => {
    const [loading, setLoading] = useState(false)
    return ( 
        <>
        <Loader open={loading}/>
        <CentralContentPageLayout>
            <CentralGridLayout>
                <UploadPhotos
                showbackButton={false}
                setShowLoading={setLoading}
                onBackClicked={()=>console.log('back')}
                onUploadSuccess={()=>console.log('upload success')}
                btnSubmitText={'Next'}
            />
            </CentralGridLayout>
        </CentralContentPageLayout>
        </>
     );
}
 
export default UploadPhotoPage;