import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import { Box, IconButton } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import styles from './index.module.scss';
import {
  landingHeaderMenu,
  DrawerWidth,
  routeUrls,
} from '../../utils/constants';
import { scaleByRatio } from '../../utils/helper';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import AppLogo from '../../assets/AppLogo.svg';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

const drawerWidth = DrawerWidth;

const Header = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const nav = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleMenuItemClick = (linkPath) => {
    console.log(linkPath);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <img
          src={AppLogo}
          alt="App Icon"
          className={styles.leftMenuAppIcon}
          onClick={() => nav(routeUrls.landingPage)}
        />
      </Typography>
      <Divider />
      <List>
        {landingHeaderMenu.map((page) => (
          <ListItem key={page.label} disablePadding>
            <ListItemButton
              onClick={() => handleMenuItemClick(page.path)}
              sx={{ textAlign: 'center' }}>
              {/* <ListItemText className={styles.menuItem} primary={page.label} /> */}
              <Typography textAlign="left" className={styles.menuItem}>
                {page.label}
              </Typography>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <AppBar className={styles.header} component="nav">
        {/* <Toolbar className={styles.headerBox}>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, paddingLeft: '0px' }}>
            <img
              src={AppLogo}
              alt="App Icon"
              className={styles.appIcon}
              onClick={() => nav(routeUrls.landingPage)}
              style={{
                height: `${scaleByRatio(73)}px`,
                paddingTop: `${scaleByRatio(24)}px`,
                paddingBottom: `${scaleByRatio(10)}px`,
              }}
            />
          </Typography>
          <Box
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}
            className={styles.menuContainer}>
            {landingHeaderMenu.map((page) => (
              <MenuItem
                key={page.label}
                onClick={() => handleMenuItemClick(page.path)}>
                <Typography
                  textAlign="center"
                  className={styles.menuItem}
                  style={{
                    fontSize: `${scaleByRatio(20)}px`,
                    lineHeight: `${scaleByRatio(24)}px`,
                  }}>
                  {page.label}
                </Typography>
              </MenuItem>
            ))}
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              color: 'orange',
              display: { xs: 'block', sm: 'none' },
            }}>
            <MoreVertOutlinedIcon />
          </IconButton>
        </Toolbar> */}
      </AppBar>
      {/* <nav>
        <Drawer
          anchor={'right'}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: '#000',
            },
          }}>
          {drawer}
        </Drawer>
      </nav> */}
    </Box>
  );
};

export default Header;
