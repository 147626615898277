import React, { useState, useEffect, useRef } from 'react'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import styles from './index.module.scss'
import { scaleByRatio } from '../../utils/helper'

const DirectMessageMenu = ({
    menu_id="positioned-menu",
    menuAnchorEL,
    position="left",
    arrMenuItem=[],//[{name:'Block', id:'1'}]
    callbackFunction
}) => {
    useState(()=>{
        console.log('menuAnchorEL:: ', menuAnchorEL)
    },[menuAnchorEL])
    const [anchorEl, setAnchorEl] = useState(menuAnchorEL);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (menu_id) => {
        callbackFunction(menu_id)
        setAnchorEl(null);
    };
    return ( 
        <Menu
        id={menu_id}
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: position,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {arrMenuItem && arrMenuItem.length>0 && arrMenuItem.map((menu)=>{
            return (
                <MenuItem className={styles.menuitem} style={{
                    fontSize: `${scaleByRatio(16)}px`
                }} onClick={()=>handleClose(menu?.id)}>{menu.name}</MenuItem>
            )
        })}
        
      </Menu>
     );
}
 
export default DirectMessageMenu;