import React from 'react';
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout';
import CentralGridLayout from '../../Layout/CentralGridLayout';
import { Grid, Typography } from '@mui/material';
import { scaleByRatio } from '../../utils/helper';

const ActivitiesPause = () => {
  return (
    <>
      <>
        <Grid container>
          <Grid
            item
            direction={'row'}
            xs={12}
            display={'inline'}
            textAlign={'center'}
            mt={2}
            paddingTop={0}>
            <Typography
              style={{
                fontSize: '18px',
                fontWeight: '600',
                color: '#444444',
              }}>
              Activities Paused
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            marginTop={'20%'}
            // marginLeft={'40%'}
            color={'#444444'}
            fontSize={'20px'}
            display={'inline'}
            textAlign={'center'}>
            <Typography>Sophia has paused your activities.</Typography>
          </Grid>
        </Grid>
      </>
    </>
  );
};

export default ActivitiesPause;
