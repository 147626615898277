import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import AppLogoMin from '../../assets/AppLogoMin.svg';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import SingleChoiceSelectionButton from '../../atoms/SingleChoiceSelectionButton';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import CheckBoxWithLabel from '../../atoms/CheckBoxWithLabel';
import { scaleByRatio } from '../../utils/helper';
import CentralContentPageLayout from '../../Layout/CentralContentPageLayout';
import CentralGridLayout from '../../Layout/CentralGridLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnswerType, routeUrls } from '../../utils/constants';
import {
  useGetAllQuestions,
  useGetQuestionDetailsById,
  usePatchMultiChoiceAnswer,
  usePatchSingleChoiceAnswer,
  usePostMultiChoiceAnswer,
  usePostSingleChoiceAnswer,
} from '../../services/mutations';
import Loader from '../../molecule/Loader';

const DaterProfileSCQuestions = () => {
  const nav = useNavigate();
  const location = useLocation();
  // const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const getQuestionDetailsById = useGetQuestionDetailsById();
  const [questionDetails, setQuestionDetails] = useState({});
  const [error, setError] = useState();
  const [answerChoices, setAnswerChoices] = useState([]);
  const [question, setQuestion] = useState(null);
  const patchSingleChoiceAnswer = usePatchSingleChoiceAnswer();
  const postSingleChoiceAnswer = usePostSingleChoiceAnswer();
  const [selected, setSelected] = useState();
  const [pendingQuestionList, setPendingQuestionList] = useState([]);
  const [answers, setAnswers] = useState([]);

  console.log('qs', questionDetails);
  console.log('qc', answerChoices);

  console.log(
    'data..',
    location?.state?.questionId,
    location?.state?.choice_type,
  );

  const postPatchSingleChoiceAnswer = (answer) => {
    postSingleChoiceAnswer.mutate(answer, {
      onSuccess: (response) => {
        // set the patch id for question on successful posting
        let questionIndex = pendingQuestionList.findIndex(
          (questionItem) => questionItem.id === question.id,
        );
        if (questionIndex !== -1) {
          pendingQuestionList[questionIndex].patchId =
            response?.data?.payload?.id || null;
          setPendingQuestionList(pendingQuestionList);
        }

        console.log('ansSuccess', answer);

        nav(routeUrls.home, {
                state: {
                  showEdit: true,
                }
              });
      },
      onError: (error) => {
        console.log('postSingleChoiceAnswer: ', error);
        if (error?.response?.status === 400) {
          let data = {
            id: question?.patchId,
            answer: answer,
          };
          patchSingleChoiceAnswer.mutate(data, {
            onSuccess: (response) => {
              //   setLoading(false);
              //   setActiveStep(activeStep + 1);
            },
            onError: (error) => {
              //   setLoading(false);
              //   setError('Something went wrong, please try again');
            },
          });
        } else {
          //   setLoading(false);
          //   setError('Something went wrong, please try again');
        }
      },
    });
  };

  const getQuestionDetailsByIdentifier = (questionId) => {
    let qData = {
      id: questionId,
    };
    getQuestionDetailsById.mutate(qData, {
      onSuccess: (response) => {
        let _questionDetails = response?.data?.payload;
        setQuestionDetails(_questionDetails);
        setAnswerChoices(_questionDetails?.question_choices);
        console.log('question details: ', response?.data?.payload);
        return;
      },
      onError: (error) => {
        console.log('getQuestionDetailsById error: ', error);
      },
    });
  };

  useEffect(() => {
    if (location?.state?.questionId) {
      getQuestionDetailsByIdentifier(location?.state?.questionId);
    }
  }, [location?.state?.questionId]);

  //   const handleClick = () => {
  //     // if (!answers || answers.length < 1) {
  //     //   setError('Please select your political views. ');
  //     // } else {
  //     //   setError('');
  //     //call api to store answers
  //     let selectedAnswerObj = questionDetails?.question_choices?.find(
  //       (choice) => {
  //         return choice?.id === answers[0];
  //       },
  //     );
  //     let answer = {
  //       value: selectedAnswerObj?.value,
  //       visible: true,
  //       answer: selectedAnswerObj?.id,
  //     };
  //     postPatchSingleChoiceAnswer(answer);
  //     // }
  //   };

  const handleClick = (e) => {
    setSelected(e);
    console.log('ssdd', e);
    if (e) {
      setAnswers([...answers, e]);
    }
    console.log('e..', answers);
  };

  const handleSave = () => {
    if (!answers || answers.length < 1) {
      setError('Please provide some inputs.');
    } else {
      //   setError('');
      //call api to store answers
      let selectedAnswerObj = questionDetails?.question_choices?.find(
        (choice) => {
          return choice?.id === answers[0];
        },
      );
      let answer = {
        value: selectedAnswerObj?.value,
        visible: true,
        answer: selectedAnswerObj?.id,
      };
      postPatchSingleChoiceAnswer(answer);
    }
    // const allAnswers = answers.map(({ id, value }) => ({ answer: id, value }));
    // console.log('allAnswers', allAnswers);
    // answers.forEach((answer) => postPatchSingleChoiceAnswer(allAnswers));
  };
  useEffect(() => {
    if (answers) {
      setError('');
    }
  }, [answers]);

  return (
    <CentralContentPageLayout>
      <CentralGridLayout>
        {/* <Loader open={loading} /> */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer}>
            <img
              src={AppLogoMin}
              alt={'App icon'}
              className={styles.logo}
              style={{
                height: `${scaleByRatio(126)}px`,
                width: `${scaleByRatio(56)}px`,
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer}>
            <img
              src={ArrowLeft}
              alt={'Back icon'}
              className={styles.logoback}
              onClick={() => nav(routeUrls.home, {
                state: {
                  showEdit: true,
                }
              })}
            />
          </Grid>
          <Grid item>
            <Typography
              textAlign="left"
              className={styles.back}
              onClick={() => nav(routeUrls.home, {
                state: {
                  showEdit: true,
                }
              })}
              style={{
                fontSize: `${scaleByRatio(12)}px`,
                paddingLeft: `${scaleByRatio(10)}px`,
                paddingBottom: `${scaleByRatio(5)}px`,
              }}>
              Back
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item>
            <Typography
              textAlign="left"
              className={styles.description}
              style={{ fontSize: `${scaleByRatio(32)}px` }}>
              {location?.state?.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row">
          {answerChoices &&
            answerChoices.map((choice) => {
              return (
                <Grid
                  item
                  m={0.5}
                  justifyContent="left"
                  alignItems="flex-start"
                  style={{ paddingRight: '15px' }}>
                  <SingleChoiceSelectionButton
                    text={choice.value}
                    // onChange={setSelected(choice.id)}
                    id={choice.id}
                    isSelected={selected == choice.id}
                    onClick={() =>
                      handleClick(choice.id)
                    }></SingleChoiceSelectionButton>
                </Grid>
              );
            })}
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end"></Grid>
        {/* {showVisibility && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-end">
            <CheckBoxWithLabel
              isChecked={isVisible}
              labelText={checkBoxLabel}
              handleClick={onVisibleClick}
            />
          </Grid>
        )} */}

        {error && error?.length > 0 && (
          <Grid
            container
            direction="row"
            justifyContent="left"
            alignItems="flex-end">
            <Typography
              textAlign="left"
              className={styles.error}
              style={{
                fontSize: `${scaleByRatio(12)}px`,
                lineHeight: `${scaleByRatio(40)}px`,
              }}>
              {error}
            </Typography>
          </Grid>
        )}
        <Grid
          container
          direction="row"
          justifyContent="end"
          alignItems="flex-end">
          <Grid item>
            <CustomButton
              text={'Save'}
              variant={ButtonVariant.contained}
              type={ButtonType.primary}
              onClick={handleSave}
            />
          </Grid>
        </Grid>
      </CentralGridLayout>
    </CentralContentPageLayout>
  );
};

export default DaterProfileSCQuestions;
