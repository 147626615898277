import { Grid } from '@mui/material'
import styles from './index.module.scss'

const MyProfileUpdateLayout = (props) => {
    return ( 
        <>
            <Grid container 
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={styles.main}
            xs={12}
            >
                <Grid container xs={10} sm={10} md={6} lg={6} className={styles.container}>
                    {props.children}
                </Grid>
            </Grid>
        </>
     );
}
 
export default MyProfileUpdateLayout;