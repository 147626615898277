import React, {useState} from 'react'
import styles from './index.module.scss'
import {  Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
import AppLogoMin from '../../assets/AppLogoMin.svg'
import ArrowLeft from '../../assets/ArrowLeft.svg'
import InputField from '../../atoms/InputField';
import {ButtonVariant, ButtonType, CustomButton} from '../../atoms/CustomButton'
import {scaleByRatio} from '../../utils/helper'

const ProfileDataEmail = ({email, onEmailChange, onNextClicked, error, onBackClicked}) => {
    const handleEmailChange = (evt)=>{
        onEmailChange(evt.target.value)
    }
    
    const handleOnNextClicked = (evt) =>{
        onNextClicked();
    }
    return ( 
        <>
        <Grid container direction="row" justifyContent="center" alignItems="flex-end">
            <Grid item className={styles.logoContainer} marginTop={-8}>
                {/* <Typography textAlign='center' className={styles.logoContainer}> */}
                    <img src={AppLogoMin} alt={'App icon'} className={styles.logo} 
                    style={{
                        width:`90%`,
                        marginBottom: '50px',
                        marginTop: '-40px',
                        height:`${scaleByRatio(110)}px`,
                    }}
                    />
                {/* </Typography> */}
            </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="left" alignItems="flex-end">
            <Grid item className={styles.logoContainer}>
                <img src={ArrowLeft} alt={'Back icon'} 
                className={styles.logoBack} 
                onClick={onBackClicked}
                width={scaleByRatio(24)} height={scaleByRatio(24)}
                />
            </Grid>
            <Grid item >
                <Typography textAlign='left' className={styles.back} onClick={onBackClicked}
                style={{
                    fontSize:`${scaleByRatio(12)}px`,
                    paddingLeft: `${scaleByRatio(10)}px`,
                    paddingBottom:`${scaleByRatio(2)}px`,
                }}
                >
                Back
                </Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="left" alignItems="flex-end">
            <Grid item >
                <Typography textAlign='left' className={styles.description}
                style={{
                    fontSize: `${scaleByRatio(22)}px`,
              lineHeight: `${scaleByRatio(28.64)}px`,
              letterSpacing: '0.06em', 
              marginLeft: '15px'
                }}
                >
                What's your email?
                </Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="left" alignItems="flex-end">
            <InputField
            name='Email'
            id={'Email'}
            value={email}
            placeholder={''}
            handleChange={(evt)=>handleEmailChange(evt)}
            type={'text'}
            class_name={styles.name}
            />
                        
        </Grid>
        
        {error && error?.length>0 && (
            <Grid container direction="row" justifyContent="left" alignItems="flex-end">
                <Typography textAlign='left' className={styles.error}
                style={{
                    fontSize:`${scaleByRatio(12)}px`,
                    lineHeight: `${scaleByRatio(40)}px`,
                }}
                >
                {error}
                </Typography>
            </Grid>
        )}
        <Grid container direction="row" justifyContent="end" alignItems="flex-end">
            <Grid item style={{
                marginTop: `${scaleByRatio(10)}px`
            }}>
                <CustomButton 
                text={'Next'}
                variant={ButtonVariant.contained}
                type={ButtonType.primary}
                customStyle='yes'
                onClick={handleOnNextClicked}
                />
            </Grid>
        </Grid>
        </>
    )
}
 
export default ProfileDataEmail;