import LandingHeader from '../../organism/LandingHeader';
import Footer from '../../organism/Footer';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import styles from './index.module.scss';

import { Link } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';

const LandingPageLayout = (props) => {
  return (
    <div className="landing-page-layout-wrapper">
      <LandingHeader />
      <Box component="main" className={styles.main} sx={{ flexGrow: 1 }}>
        {props.children}
      </Box>
      {/* <Grid container 
            direction="row"
            className={styles.main}
            >
                {props.children}
            </Grid> */}
      {/* <Footer /> */}
      <Box
        component="footer"
        style={{
          backgroundColor: '#000',
          color: '#fff',
        }}>
        <Typography
          variant="body2"
          align="center"
          className={styles.footer}
          color={'#fff'}>
          <Link
            to={routeUrls.termsandconditions}
            className={styles.footerLink}
            style={{ color: '#fff' }}>
            Terms & Conditions
          </Link>
          {' | '}
          <Link
            to={routeUrls.privacy}
            className={styles.footerLink}
            style={{ color: '#fff' }}>
            Privacy Policy
          </Link>
          {' | '}
          <Link
            to={routeUrls.contactus}
            className={styles.footerLink}
            style={{ color: '#fff' }}>
            Contact Us
          </Link>
        </Typography>
      </Box>
    </div>
  );
};

export default LandingPageLayout;
