import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
// import CentralContentPageLayout from '../../Layout/CentralContentPageLayout'
// import CentralGridLayout from '../../Layout/CentralGridLayout'
import InputField from '../../atoms/InputField';
import {
  ButtonVariant,
  ButtonType,
  CustomButton,
} from '../../atoms/CustomButton';
import {
  routeUrls,
  ISD_CODE,
  errorMsg,
  startPageFlow,
} from '../../utils/constants';
import Loader from '../../molecule/Loader';
import ArrowLeft from '../../assets/ArrowLeft.svg';
import UploadPhotoBlock from '../UploadPhotoBlock';
import {
  useGetMyProfile,
  useUpdateMyProfile,
  usePostOtherImages,
  useDeleteImages,
} from '../../services/mutations';
import {
  localStorageKeys,
  useLocalStorage,
} from '../../utils/localStorageItems';
import { scaleByRatio } from '../../utils/helper';
import AppLogoMin from '../../assets/AppLogoMin.svg';

const UploadPhotos = ({
  setShowLoading,
  onBackClicked,
  onUploadSuccess,
  showbackButton = true,
  btnSubmitText = 'Next',
  showSteps = false,
  currentStep,
  totalSteps,
  showAppIcon = true,
}) => {
  const getMyProfile = useGetMyProfile();
  const updateMyProfile = useUpdateMyProfile();
  const postOtherImages = usePostOtherImages();
  const deleteImages = useDeleteImages();
  const localStorageItem = useLocalStorage();

  const [profilePicture, setProfilePicture] = useState(null);
  const [otherPictures, setOtherPictures] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [error, setError] = useState('');
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const maxCount = 6;
  let count = 0;

  const handleLoading = (loading) => {
    //setShowLoading(loading)
    setLoading(loading);
  };

  const handleSelectedProfileImage = (pic) => {
    setProfilePicture(pic);
  };

  const handleSelectedOtherImages = (pic, index) => {
    let arrImage = otherPictures;
    arrImage[index] = pic;
    setOtherPictures(arrImage);
  };

  const handleOnNextClicked = () => {
    count = 0;
    console.log('handleOnNextClicked');

    if (profilePicture && userDetails?.profile_pic !== profilePicture) {
      uploadProfilePicture();
    } else {
      uploadOtherPictures(count);
    }

    console.log('otherpictures: ', otherPictures);
  };

  const uploadProfilePicture = () => {
    let form = new FormData();
    // form.append("user.first_name", userDetails?.user?.first_name)
    // form.append("user.last_name", userDetails?.user?.last_name)
    // form.append("user.email", userDetails?.user?.email)
    // form.append("dob", userDetails?.dob)
    form.append('profile_pic', profilePicture);
    handleLoading(true);
    updateMyProfile.mutate(form, {
      onSuccess: (response) => {
        console.log('updateMyProfile onSuccess: ', response);
        localStorage.setItem(
          'profileImage',
          response?.data?.payload?.profile_pic,
        );
        let _payload = response?.data?.payload;
        let finalPayload = {
          user: {
            first_name: _payload?.user?.first_name,
            last_name: _payload?.user?.last_name,
            email: _payload?.user?.email,
          },
          dob: _payload?.dob,
          profile_pic: _payload?.profile_pic,
          is_dater: _payload?.is_dater,
          push_notification_enabled: _payload?.push_notification_enabled,
          email_notification_enabled: _payload?.email_notification_enabled,
          sms_notification_enabled: _payload?.sms_notification_enabled,
          images: _payload?.images,
        };
        setUserDetails(finalPayload);

        handleLoading(false);
        setProfilePicture(null);
        // upload other pictures
        uploadOtherPictures(count);
      },
      onError: (err) => {
        console.log('updateMyProfile onError: ', err);
        handleLoading(false);
      },
    });
  };

  const uploadOtherPictures = (index) => {
    if (index < maxCount) {
      // upload other images

      let form = new FormData();
      form.append('content_type', 'userprofile');
      form.append('image', otherPictures[index] || '');
      form.append(
        'object_id',
        localStorageItem.getLocalStorageItem(localStorageKeys.profileId),
      );

      if (otherPictures[index]) {
        handleLoading(true);
        if (userDetails?.images[index]?.id) {
          //delete and post
          let data = {
            id: userDetails?.images[index]?.id,
          };
          deleteImages.mutate(data, {
            onSuccess: (response) => {
              console.log('deleteImages onSuccess: ', response);
              handleLoading(false);
              postImage(index);
            },
            onError: (error) => {
              handleLoading(false);
              console.log('deleteImages error: ', error);
            },
          });
        } else {
          //only post
          postImage(index);
        }
      } else {
        count++;
        uploadOtherPictures(count);
      }
    } else {
      handleLoading(false);
      count = 0;
      setOtherPictures([null, null, null, null, null, null]);
      getMyProfileData();
      onUploadSuccess();
    }
  };

  const postImage = (index) => {
    handleLoading(true);
    // upload other images

    let form = new FormData();
    form.append('content_type', 'userprofile');
    form.append('image', otherPictures[index] || '');
    form.append(
      'object_id',
      localStorageItem.getLocalStorageItem(localStorageKeys.profileId),
    );

    postOtherImages.mutate(form, {
      onSuccess: (response) => {
        console.log('uploadOtherPictures onSuccess: ', response);
        handleLoading(false);
        count++;
        uploadOtherPictures(count);
      },
      onError: (err) => {
        console.log('uploadOtherPictures onError: ', err);
        handleLoading(false);
        count++;
        uploadOtherPictures(count);
      },
    });
  };

  const getMyProfileData = () => {
    //  handleLoading(true)
    let data = {};
    getMyProfile.mutate(data, {
      onSuccess: (response) => {
        console.log('getMyProfile onSuccess photos: ', response);
        let _payload = response?.data?.payload;
        localStorage.setItem('profileImage', _payload?.profile_pic);
        let finalPayload = {
          user: {
            first_name: _payload?.user?.first_name || '',
            last_name: _payload?.user?.last_name || '',
            email: _payload?.user?.email || '',
          },
          dob: _payload?.dob,
          profile_pic: _payload?.profile_pic,
          is_dater: _payload?.is_dater,
          push_notification_enabled: _payload?.push_notification_enabled,
          email_notification_enabled: _payload?.email_notification_enabled,
          sms_notification_enabled: _payload?.sms_notification_enabled,
          images: _payload?.images,
        };
        setUserDetails(finalPayload);
        handleLoading(false);
      },
      onError: (err) => {
        console.log('getMyProfile onError: ', err);
        handleLoading(false);
      },
    });
  };

  const handleDeleteImage = (imageIndex) => {
    if (userDetails?.images[imageIndex]?.id) {
      handleLoading(true);
      let data = {
        id: userDetails?.images[imageIndex]?.id,
      };
      deleteImages.mutate(data, {
        onSuccess: (response) => {
          handleLoading(false);
          console.log('deleteImages onSuccess: ', response);
          getMyProfileData();
        },
        onError: (error) => {
          handleLoading(false);
          console.log('deleteImages error: ', error);
        },
        onSettled: () => {
          handleLoading(false);
          console.log('deleteImages onSettled: ');
          getMyProfileData();
        },
      });
    }
  };

  //   const handleDeleteProfileImage = () => {
  //     if (userDetails?.profile_pic) {
  //       handleLoading(true);
  //       let data = {
  //         id: userDetails?.id,
  //       };
  //       deleteImages.mutate(data, {
  //         onSuccess: (response) => {
  //           handleLoading(false);
  //           console.log('deleteImages onSuccess: ', response);
  //           getMyProfileData();
  //         },
  //         onError: (error) => {
  //           handleLoading(false);
  //           console.log('deleteImages error: ', error);
  //         },
  //         onSettled: () => {
  //           handleLoading(false);
  //           console.log('deleteImages onSettled: ');
  //           getMyProfileData();
  //         },
  //       });
  //     }
  //   };

  const handleDeleteProfileImage = () => {
    // if(userDetails?.profile_pic){

    // }
    setProfilePicture('');
  };

  useEffect(() => {
    getMyProfileData();
  }, []);
  return (
    <>
      <Loader open={loading} />
      {/* <CentralGridLayout> */}
      {showAppIcon && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer} marginTop={3}>
            {/* <Typography textAlign='center' className={styles.logoContainer}> */}
            <img
              src={AppLogoMin}
              alt={'App icon'}
              className={styles.logo}
              style={{
                width: `90%`,
                height: `${scaleByRatio(110)}px`,
              }}
            />
            {/* </Typography> */}
          </Grid>
        </Grid>
      )}
      {showbackButton && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Grid item className={styles.logoContainer}>
            <img
              src={ArrowLeft}
              alt={'Back icon'}
              width={scaleByRatio(12)}
              height={scaleByRatio(12)}
              className={styles.logoback}
              onClick={onBackClicked}
            />
          </Grid>
          <Grid item>
            <Typography
              textAlign="left"
              className={styles.back}
              onClick={onBackClicked}
              style={{
                fontSize: `${scaleByRatio(12)}px`,
                paddingLeft: `${scaleByRatio(10)}px`,
                paddingBottom: `${scaleByRatio(2)}px`,
              }}>
              Back
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Grid item>
          <Typography
            textAlign="left"
            className={styles.description}
            style={{
              fontSize: `${scaleByRatio(22)}px`,
              lineHeight: `${scaleByRatio(28.64)}px`,
              letterSpacing: '0.06em', 
              marginLeft: '15px'
            }}>
            Add some photos
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-end">
        <Grid item>
          <UploadPhotoBlock
            size="360"
            selectedImage={userDetails?.profile_pic}
            selectedImageCallBack={handleSelectedProfileImage}
            showDeleteButton={true}
            onDeleteCallBack={handleDeleteProfileImage}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            textAlign='left'
            className={styles.profileDescription}
            style={{
              fontSize: `${scaleByRatio(15)}px`,
              letterSpacing : '0.1em',

            }}>
            Add profile photo
          </Typography>
        </Grid>
      </Grid>
      <Grid
       marginTop={'-30px'}

        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end">
        <Grid item>
          <Typography
            textAlign="left"
            className={styles.description2}
            style={{
              fontSize: `${scaleByRatio(20)}px`,
              letterSpacing : '0.1em',
              textTransform:'uppercase'
            }}>
            MORE PHOTOS (OPTIONAL)
          </Typography>
        </Grid>
      </Grid>
      {!loading && (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          >
          <Grid item style={{ marginRight: `${scaleByRatio(13)}px`,borderRadius:'40%',marginLeft:`1px` }}>
            <UploadPhotoBlock
              size="70"
              border_radius={true}
              selectedImage={userDetails?.images[0]?.image || null}
              selectedImageCallBack={(pic) => handleSelectedOtherImages(pic, 0)}
              showDeleteButton={true}
              onDeleteCallBack={() => handleDeleteImage(0)}
            />
          </Grid>
          <Grid item style={{ marginRight: `${scaleByRatio(13)}px` }}>
            <UploadPhotoBlock
              size="70"
              border_radius={true}
              selectedImage={userDetails?.images[1]?.image || null}
              selectedImageCallBack={(pic) => handleSelectedOtherImages(pic, 1)}
              showDeleteButton={true}
              onDeleteCallBack={() => handleDeleteImage(1)}
            />
          </Grid>
          <Grid item style={{ marginRight: '13px' }}>
            <UploadPhotoBlock
              size="70"
              border_radius={true}
              selectedImage={userDetails?.images[2]?.image || null}
              selectedImageCallBack={(pic) => handleSelectedOtherImages(pic, 2)}
              showDeleteButton={true}
              onDeleteCallBack={() => handleDeleteImage(2)}
            />
          </Grid>
          <Grid item style={{ marginRight: `${scaleByRatio(2)}px` }}>
            <UploadPhotoBlock
              size="70"
              border_radius={true}
              selectedImage={userDetails?.images[3]?.image || null}
              selectedImageCallBack={(pic) => handleSelectedOtherImages(pic, 3)}
              showDeleteButton={true}
              onDeleteCallBack={() => handleDeleteImage(3)}
            />
          </Grid>
        </Grid>
      )}
      {/* {!loading && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end">
          <Grid item style={{ marginRight: `${scaleByRatio(10)}px` }}>
            <UploadPhotoBlock
              size="96"
              selectedImage={userDetails?.images[3]?.image || null}
              selectedImageCallBack={(pic) => handleSelectedOtherImages(pic, 3)}
              showDeleteButton={true}
              onDeleteCallBack={() => handleDeleteImage(3)}
            />
          </Grid>
          <Grid item style={{ marginRight: `${scaleByRatio(10)}px` }}>
            <UploadPhotoBlock
              size="96"
              selectedImage={userDetails?.images[4]?.image || null}
              selectedImageCallBack={(pic) => handleSelectedOtherImages(pic, 4)}
              showDeleteButton={true}
              onDeleteCallBack={() => handleDeleteImage(4)}
            />
          </Grid>
          <Grid item style={{ marginRight: `${scaleByRatio(10)}px` }}>
            <UploadPhotoBlock
              size="96"
              selectedImage={userDetails?.images[5]?.image || null}
              selectedImageCallBack={(pic) => handleSelectedOtherImages(pic, 5)}
              showDeleteButton={true}
              onDeleteCallBack={() => handleDeleteImage(5)}
            />
          </Grid>
        </Grid>
      )} */}
      {error && error?.length > 0 && (
        <Grid
          container
          direction="row"
          justifyContent="left"
          alignItems="flex-end">
          <Typography
            textAlign="left"
            className={styles.error}
            style={{
              fontSize: `${scaleByRatio(12)}px`,
              lineHeight: `${scaleByRatio(40)}px`,
            }}>
            {error}
          </Typography>
        </Grid>
      )}
      <Grid
        container
        direction="row"
        justifyContent="end"
        alignItems="flex-end">
        <Grid item xs={6}>
          {showSteps && (
            <Typography
              textAlign={'left'}
              className={styles.pagination}
              style={{
                padding: `${scaleByRatio(12)}px ${scaleByRatio(32)}px`,
                fontSize: `${scaleByRatio(25)}px`,
                lineHeight: `${scaleByRatio(34)}px`,
              }}>
              {currentStep}/{totalSteps}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          justifyContent="right"
          style={{
            display: 'flex',
          }}>
          <CustomButton
            text={btnSubmitText}
            variant={ButtonVariant.contained}
            type={ButtonType.primary}
            onClick={handleOnNextClicked}
            customStyle={`yes`}
          />
        </Grid>
      </Grid>

      {/* </CentralGridLayout> */}
    </>
  );
};

export default UploadPhotos;
