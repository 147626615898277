import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import ProfilePicDemo from '../../assets/ProfilePicDemo.png';
import CustomChip from '../../atoms/CustomChip';
import Stack from '@mui/material/Stack';
import Friend from '../../assets/Friend.png';
import AddFriend from '../../assets/AddFriend.svg';
import SwitchEyeFill from '../../assets/SwitchEyeFill.svg';
import MyProfileSection from '../MyProfileSection';
import { AnswerType, ProfilePageType } from '../../utils/constants';
import Skip from '../../assets/Skip.svg';
import ArrowRight from '../../assets/ArrowRight.svg';

import CustomSwitch from '../../atoms/CustomSwitch';
import Profile_Avatar from '../../assets/Profile_Avatar.png';
import { HomeMenuConstant } from '../../utils/constants';
import { scaleByRatio } from '../../utils/helper';
import { Button } from '@mui/material';
import { useWindowSize } from '../../utils/useWindowSize';
import {
  localStorageKeys,
  localStorageUserType,
  useLocalStorage,
} from '../../utils/localStorageItems';
import { routeUrls } from '../../utils/constants';
import { useLocation, useNavigate } from 'react-router-dom';

const MyProfileAboutUs = ({
  profilePic,
  handleActiveChange,
  friendDescription,
  allAnswers = [],
  userDetails,
  clickable = false,
  isActiveProfileProps,
  handleSetActiveBtnState,
  showActiveBtn,
  handleChipClick,
  setClickable,
  friendsList = [],
  handleMenuClicked,
  setShowAddFriend,
  pageType = ProfilePageType.MyProfile,
  showChatterBoxAcceptanceButtons = false,
  elbow_request_status,
  onNoClicked,
  onSkipClicked,
  onYesClicked,
  onMaybeClicked,
  friendsClickExploreCallBack,
  convos_direct_session_introw_status = null,
}) => {
  const nav = useNavigate();
  MyProfileAboutUs.defaultProps = { onSkipClicked: () => {} };
  const localStorageItem = useLocalStorage();
  let identifiers = [
    [
      {
        type: 'Profile',
        identifier: 'age',
      },
      {
        type: 'Questions',
        identifier: 14,
      },
      {
        type: 'Questions',
        identifier: 13,
      },
      {
        type: 'Questions',
        identifier: 17,
      },
    ],
    [
      {
        type: 'Questions',
        identifier: 16,
      },
      {
        type: 'Questions',
        identifier: 18,
      },
      {
        type: 'Questions',
        identifier: 19,
      },
      {
        type: 'Questions',
        identifier: 20,
      },
      {
        type: 'Questions',
        identifier: 21,
      },
    ],
  ];
  const [stackChips, setStackChips] = useState([]);
  const [height, width] = useWindowSize();
  const [getwidth, setWidth] = useState(0);

  const heightcm_to_ftin = (cm) => {
    let ft_height = '';
    if (parseInt(cm) > 0) {
      let heightin = Math.round(parseInt(cm) / 2.54);
      let heightft = Math.floor(heightin / 12);
      ft_height = Math.floor(heightft) + "' ";
      let remainingInch = heightin - Math.floor(heightft) * 12;
      if (Math.round(remainingInch) > 0) {
        ft_height += Math.round(remainingInch) + '"';
      }
    }
    return ft_height;
  };

  const getDetailsByIdentifiers = () => {
    let arrStack = [];
    for (let i = 0; i < identifiers.length; i++) {
      let arrChips = [];
      let stack = identifiers[i];
      for (let j = 0; j < stack.length; j++) {
        let identifier = stack[j];
        let chipsObj = null;
        if (identifier.type === 'Questions') {
          allAnswers.forEach((answerItem) => {
            if (
              answerItem?.answer?.question?.identifier === identifier.identifier
            ) {
              chipsObj = {};
              chipsObj.label = answerItem?.answer?.value;
              if (identifier.identifier === 17) {
                chipsObj.label = answerItem?.value;
                chipsObj.label = 'Height ' + heightcm_to_ftin(chipsObj.label);
              }
              chipsObj.QuestionID = answerItem?.answer?.question?.id;
              chipsObj.identifier = identifier.identifier;
              chipsObj.clickable = clickable;
              chipsObj.type = identifier.type;
              arrChips.push(chipsObj);
            }
          });
        } else if (identifier.type === 'Profile') {
          chipsObj = {};
          chipsObj.label = userDetails[identifier.identifier];
          if (identifier.identifier === 'age') {
            chipsObj.label = 'Age ' + userDetails[identifier.identifier];
          }
          chipsObj.QuestionID = null;
          chipsObj.identifier = identifier.identifier;
          chipsObj.clickable = clickable;
          chipsObj.type = identifier.type;
          arrChips.push(chipsObj);
        }
      }
      arrStack.push(arrChips);
    }
    setStackChips(arrStack);
  };

  const handleFriendClickedExplore = () => {
    friendsClickExploreCallBack();
  };

  const handleEditChange = (_editable) => {
    if (_editable === true) {
      localStorage.setItem('questionEdit', true);
    } else {
      localStorage.removeItem('questionEdit');
    }
    setClickable(_editable);
    nav(routeUrls.home, {
      state: {
        showEdit: false,
      },
    });
    localStorage.removeItem('setEdit');
  };

  useEffect(() => {
    if (allAnswers && allAnswers?.length > 0) {
      getDetailsByIdentifiers();
    }
  }, [allAnswers]);

  useEffect(() => {
    console.log('Profile about us loaded:', clickable);
  }, [clickable]);
  console.log('Profile about us loaded:', window?.innerWidth);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWidth(window?.innerWidth);
      console.log('Profile about us loaded:', window?.innerWidth);
    }
  }, [width]);

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="flex-start"
        bgcolor={'#000'}
        columnSpacing={0.5}
        className={styles.myprofileImgAbtWrapper}>
        <Grid
          item
          justifyContent="right"
          style={{ position: 'relative' }}
          className={`${styles.profilepicContainer} ${styles.myProfileImg}`}>
          <Typography
            sx={{
              position: 'absolute',
              bottom: `${scaleByRatio(45)}px`,
              left: `${scaleByRatio(30)}px`,
              marginBottom: '20px',
              // display: {
              //   xs: 'none',
              //   sm: `${
              //     pageType === ProfilePageType.MyProfile ? 'block' : 'none'
              //   }`,
              // },
              display: `${
                pageType === ProfilePageType.MyProfile ? 'block' : 'none'
              }`,
            }}>
            <CustomSwitch
              txtSwitchOn={'Edit On'}
              txtSwitchOff={'Edit Off'}
              switchWidth={130}
              checked={clickable}
              handleChange={handleEditChange}
            />
          </Typography>
          <Typography
           onClick={() => onSkipClicked()}
           
            sx={{
              position: 'absolute',
              // bottom: `${scaleByRatio(45)}px`,
              bottom: `${scaleByRatio(35)}px`,
              // left: `${scaleByRatio(30)}px`,
              left: `${scaleByRatio(20)}px`,
              display: `${
                pageType === ProfilePageType.Explore ? 'flex' : 'none'
              }`,
              fontFamily: 'Gill Sans Nova',
              transform: 'uppercase',
              color: '#CCCCCC',
              fontSize: '12px',
              marginLeft: '20px',
              cursor: 'pointer',
              letterSpacing:'0.08em',
            
            }}>
            SKIP
            <img
              alt="Skip"
              style={{
                cursor: 'pointer',
              }}
              onClick={() => onSkipClicked()}
              src={ArrowRight}
            />
          </Typography>

          <Typography
            textAlign="left"
            // sx={{ display: { xs: 'none', sm: 'block' } }}
            style={{
              cursor: `${clickable ? 'pointer' : 'auto'}`,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
            className={styles.myProfileImgWrapper}
            onClick={() => {
              if (clickable) {
                handleChipClick({
                  label: 'Profile Pic',
                  QuestionID: null,
                  identifier: 'photos',
                  clickable: true,
                  type: 'Profile',
                });
              }
            }}>
            <img
              src={profilePic ? profilePic : Profile_Avatar}
              alt="profile"
              className={styles.myprofileabtusProfile}
              // width={scaleByRatio(477)}
              // height={scaleByRatio(400)}
              // height={'100%'}
            />
          </Typography>

          {showActiveBtn && (
            <Grid
              item
              xs={getwidth > 1200 && 1.5}
              justifyContent="right"
              style={{
                marginTop: `${scaleByRatio(8)}px`,
                marginBottom: `${scaleByRatio(3)}px`,
                // marginLeft: '-15px',
                display: 'flex',
                float: 'right',
              }}
              className={styles.activeBtn}>
              <CustomSwitch
                txtSwitchOn={'Active'}
                txtSwitchOff={'Inactive'}
                switchWidth={120}
                checked={isActiveProfileProps}
                handleChange={handleActiveChange}
                iconThumb={SwitchEyeFill}
              />
            </Grid>
          )}
        </Grid>

        <Grid item className={styles.myProfileAbtUs}>
          <Grid container direction="column" justifyContent="left">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="flex-start">
                <Grid item xs={12} alignItems="flex-start">
                  <Typography
                    fullwidth
                    // textAlign="left"
                    className={styles.subHeader}
                    textAlign={'center'}
                    style={{
                      fontSize: `${scaleByRatio(16)}px`,
                    }}>
                    About Me
                  </Typography>
                </Grid>
                <hr style={{ width: '100%', border: 'none', height: '0.5px' ,  backgroundColor: '#cccccc'}} />


                <Grid
                  item
                  xs={12}
                  direction="row"
                  justifyContent="left"
                  alignItems="flex-start">
                  {stackChips &&
                    stackChips.length > 0 &&
                    stackChips.map((stack) => {
                      return (
                        <MyProfileSection
                          heading={''}
                          description=""
                          arrChips={stack}
                          handleChipClick={handleChipClick}
                        />
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
            {getwidth > 1100 && (
              <Grid
                item
                alignItems="flex-end"
                style={{ height: `10px` }}></Grid>
            )}

            {pageType === ProfilePageType.ChatterBox && (
              // <>
              //   <Grid
              //     item
              //     alignItems="flex-end"
              //     className={styles.myProfileAboutMe}>
              //     <Grid container direction="row" alignItems="flex-start">
              //       <Grid
              //         item
              //         alignItems="flex-start"
              //         justifyContent="center"
              //         className={styles.myProfileAboutFirstSection}>
              //         <Grid container direction="column">
              //           <Grid item>
              //             <Typography
              //               fullwidth
              //               justifyContent="center"
              //               className={styles.friendName}
              //               style={{
              //                 cursor: 'pointer',
              //                 fontSize: `${scaleByRatio(24)}px`,
              //                 lineHeight: `${scaleByRatio(29)}px`,
              //               }}>
              //               <img
              //                 src={
              //                   friendsList[0]?.profile_pic || Profile_Avatar
              //                 }
              //                 // width={scaleByRatio(200)}
              //                 // height={scaleByRatio(200)}
              //                 width={'100%'}
              //                 height={'100%'}
              //                 alt={'friend'}
              //                 onClick={() => handleFriendClickedExplore()}
              //               />
              //             </Typography>
              //           </Grid>
              //           <Grid item>
              //             {friendsList && friendsList.length > 0 && (
              //               <Typography
              //                 fullwidth
              //                 justifyContent="center"
              //                 className={styles.friendName}
              //                 style={{
              //                   cursor: 'pointer',
              //                   fontSize: `${scaleByRatio(24)}px`,
              //                   lineHeight: `${scaleByRatio(29)}px`,
              //                 }}
              //                 onClick={() => handleFriendClickedExplore()}>
              //                 {friendsList[0].first_name}
              //               </Typography>
              //             )}
              //           </Grid>
              //         </Grid>
              //       </Grid>
              //       <Grid
              //         item
              //         alignItems="flex-start"
              //         className={styles.myProfileAboutSecSection}>
              //         <Grid container direction="column">
              //           <Grid item xs={12}>
              //             <Typography
              //               fullwidth
              //               justifyContent="center"
              //               className={styles.myFriends}
              //               style={
              //                 {
              //                   // lineHeight: `${scaleByRatio(40)}px`,
              //                 }
              //               }>
              //               My
              //             </Typography>
              //           </Grid>
              //           <Grid item xs={12}>
              //             <Typography
              //               fullwidth
              //               justifyContent="center"
              //               className={styles.myFriends}
              //               style={
              //                 {
              //                   // lineHeight: `${scaleByRatio(40)}px`,
              //                 }
              //               }>
              //               Friends
              //             </Typography>
              //           </Grid>
              //           <Grid item xs={12}>
              //             <Typography
              //               fullwidth
              //               justifyContent="center"
              //               className={styles.reachOut}>
              //               reach out to us
              //             </Typography>
              //           </Grid>
              //         </Grid>
              //       </Grid>
              //       <Grid
              //         item
              //         alignItems="flex-start"
              //         justifyContent="center"
              //         className={styles.myProfileAboutThirdSection}>
              //         <Grid container direction="column">
              //           <Grid item xs={12}>
              //             <Typography
              //               fullwidth
              //               justifyContent="center"
              //               className={styles.friendName}
              //               style={{
              //                 cursor: 'pointer',
              //                 fontSize: `${scaleByRatio(24)}px`,
              //                 lineHeight: `${scaleByRatio(29)}px`,
              //               }}>
              //               <img
              //                 src={
              //                   friendsList[1]?.profile_pic || Profile_Avatar
              //                 }
              //                 // width={scaleByRatio(200)}
              //                 // height={scaleByRatio(200)}
              //                 width={'100%'}
              //                 height={'100%'}
              //                 alt={'friend'}
              //                 onClick={() => handleFriendClickedExplore()}
              //               />
              //             </Typography>
              //           </Grid>
              //           <Grid item xs={12}>
              //             {friendsList && friendsList.length > 1 && (
              //               <Typography
              //                 fullwidth
              //                 justifyContent="center"
              //                 className={styles.friendName}
              //                 style={{
              //                   cursor: 'pointer',
              //                   fontSize: `${scaleByRatio(24)}px`,
              //                   lineHeight: `${scaleByRatio(29)}px`,
              //                 }}
              //                 onClick={() =>
              //                   handleMenuClicked(HomeMenuConstant.MyFriends)
              //                 }>
              //                 {friendsList[1].first_name}
              //               </Typography>
              //             )}
              //           </Grid>
              //         </Grid>
              //       </Grid>
              //     </Grid>
              //     {/* {localStorageItem.getLocalStorageItem(
              //       localStorageKeys.userType,
              //     ) === localStorageUserType.friend &&
              //       showChatterBoxAcceptanceButtons && ( */}
              //     <Grid
              //       container
              //       direction="row"
              //       alignItems="flex-start"
              //       style={{
              //         marginBottom: `${scaleByRatio(10)}px`,
              //         display: 'flex',
              //         gap: '10px',
              //         justifyContent: 'center',
              //       }}>
              //       {
              //         //   localStorageItem.getLocalStorageItem(
              //         //   localStorageKeys.userType,
              //         // ) === localStorageUserType.friend ||

              //         localStorageItem.getLocalStorageItem(
              //           localStorageKeys.userType,
              //         ) === localStorageUserType.dater &&
              //           showChatterBoxAcceptanceButtons && (
              //             // <Grid
              //             //   item
              //             //   alignItems="flex-start"
              //             //   marginRight={'15px'}
              //             //   // marginLeft={'15px'}
              //             //   justifyContent="center">
              //             //   <Button
              //             //     variant="contained"
              //             //     onClick={() => onNoClicked()}
              //             //     className={styles.exploreButton}
              //             //     style={{
              //             //       padding: `${scaleByRatio(10)}px ${scaleByRatio(
              //             //         24,
              //             //       )}px`,
              //             //       fontSize: `${scaleByRatio(24)}px`,
              //             //       background: '#FE0000',
              //             //       minWidth: '150px',
              //             //     }}>
              //             //     {/* Decline */}
              //             //     No
              //             //   </Button>
              //             // </Grid>
              //             <Grid
              //               container
              //               className={styles.maybNoYesbtnWrapper}
              //               direction="row"
              //               alignItems="flex-start"
              //               style={{
              //                 marginBottom: `${scaleByRatio(10)}px`,
              //                 marginTop: `${scaleByRatio(15)}px`,
              //                 gap: '10px',
              //               }}>
              //               <Grid
              //                 item
              //                 alignItems="flex-start"
              //                 justifyContent="center"
              //                 className={styles.noBtnWrapper}>
              //                 {/* <Button
              //                   variant="contained"
              //                   // onClick={() => onNoClicked()}
              //                   className={styles.exploreButton}
              //                   style={{
              //                     padding: `${scaleByRatio(8)}px ${scaleByRatio(
              //                       24,
              //                     )}px`,
              //                     fontSize: `${scaleByRatio(24)}px`,
              //                     background: '#FE0000',
              //                   }}>
              //                   No
              //                 </Button> */}
              //               </Grid>
              //               <Grid
              //                 item
              //                 alignItems="flex-start"
              //                 justifyContent="center"
              //                 className={styles.mayBeBtnWrapper}>
              //                 <Button
              //                   variant="contained"
              //                   onClick={() => onNoClicked()}
              //                   className={styles.exploreButton}
              //                   style={{
              //                     padding: `${scaleByRatio(8)}px ${scaleByRatio(
              //                       24,
              //                     )}px`,
              //                     fontSize: `${scaleByRatio(24)}px`,
              //                     background: '#FE0000',
              //                     minWidth: '100px',
              //                   }}>
              //                   No
              //                 </Button>
              //               </Grid>
              //               <Grid
              //                 item
              //                 alignItems="flex-start"
              //                 justifyContent="center"
              //                 className={styles.yesBtnWrapper}>
              //                 {/* <Button
              //                   variant="contained"
              //                   // onClick={() => onYesClicked()}
              //                   className={styles.exploreButton}
              //                   style={{
              //                     padding: `${scaleByRatio(8)}px ${scaleByRatio(
              //                       24,
              //                     )}px`,
              //                     fontSize: `${scaleByRatio(24)}px`,
              //                     background: '#3AB54A',
              //                   }}>
              //                   Yes
              //                 </Button> */}
              //               </Grid>
              //             </Grid>
              //           )
              //       }

              //       {localStorageItem.getLocalStorageItem(
              //         localStorageKeys.userType,
              //       ) === localStorageUserType.friend &&
              //         showChatterBoxAcceptanceButtons && (
              //           // <Grid
              //           //   item
              //           //   alignItems="flex-start"
              //           //   justifyContent="center">
              //           //   <Button
              //           //     variant="contained"
              //           //     onClick={() => onYesClicked()}
              //           //     className={styles.exploreButton}
              //           //     style={{
              //           //       padding: `${scaleByRatio(8)}px ${scaleByRatio(
              //           //         24,
              //           //       )}px`,
              //           //       fontSize: `${scaleByRatio(24)}px`,
              //           //       background: '#3AB54A',
              //           //     }}>
              //           //     Reach out
              //           //   </Button>
              //           // </Grid>

              //           <Grid
              //             container
              //             className={styles.maybNoYesbtnWrapper}
              //             direction="row"
              //             alignItems="flex-start"
              //             style={{
              //               marginBottom: `${scaleByRatio(10)}px`,
              //               marginTop: `${scaleByRatio(15)}px`,
              //               gap: '10px',
              //             }}>
              //             <Grid
              //               item
              //               alignItems="flex-start"
              //               justifyContent="center"
              //               className={styles.noBtnWrapper}>
              //               {/* <Button
              //                   variant="contained"
              //                   // onClick={() => onNoClicked()}
              //                   className={styles.exploreButton}
              //                   style={{
              //                     padding: `${scaleByRatio(8)}px ${scaleByRatio(
              //                       24,
              //                     )}px`,
              //                     fontSize: `${scaleByRatio(24)}px`,
              //                     background: '#FE0000',
              //                   }}>
              //                   No
              //                 </Button> */}
              //             </Grid>
              //             <Grid
              //               item
              //               alignItems="flex-start"
              //               justifyContent="center"
              //               className={styles.mayBeBtnWrapper}>
              //               <Button
              //                 variant="contained"
              //                 onClick={() => onYesClicked()}
              //                 className={styles.exploreButton}
              //                 style={{
              //                   padding: `${scaleByRatio(8)}px ${scaleByRatio(
              //                     24,
              //                   )}px`,
              //                   fontSize: `${scaleByRatio(24)}px`,
              //                   background: '#3AB54A',
              //                   minWidth: '180px',
              //                   marginLeft: '-10px',
              //                 }}>
              //                 Reach out
              //               </Button>
              //             </Grid>
              //             <Grid
              //               item
              //               alignItems="flex-start"
              //               justifyContent="center"
              //               className={styles.yesBtnWrapper}>
              //               {/* <Button
              //                   variant="contained"
              //                   // onClick={() => onYesClicked()}
              //                   className={styles.exploreButton}
              //                   style={{
              //                     padding: `${scaleByRatio(8)}px ${scaleByRatio(
              //                       24,
              //                     )}px`,
              //                     fontSize: `${scaleByRatio(24)}px`,
              //                     background: '#3AB54A',
              //                   }}>
              //                   Yes
              //                 </Button> */}
              //             </Grid>
              //           </Grid>
              //         )}
              //     </Grid>
              //     {/* )} */}
              //   </Grid>
              // </>

              <>
                <Grid
                  item
                  alignItems="flex-end"
                  xs={12}
                  className={styles.myProfileAboutMe}
                  style={{ width: '100%' }}>
                  <Grid
                    container
                    className={styles.maybNoYesbtnWrapper}
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    style={
                      localStorageItem.getLocalStorageItem(
                        localStorageKeys.userType,
                      ) === localStorageUserType.dater
                        ? {
                            marginBottom: `${scaleByRatio(10)}px`,
                            gap: '15px',
                            justifyContent: 'space-between',
                          }
                        : {
                            marginBottom: `${scaleByRatio(10)}px`,
                            gap: '15px',
                            justifyContent: 'center',
                            marginLeft: `${getwidth > 700 ? '-12%' : '-2%'}`,
                          }
                    }>
                    {localStorageItem.getLocalStorageItem(
                      localStorageKeys.userType,
                    ) === localStorageUserType.dater && (
                      <Grid
                        item
                        alignItems="flex-start"
                        justifyContent="center"
                        className={styles.noBtnWrapper}>
                        <Button
                          variant="contained"
                          // showChatterBoxAcceptanceButtons
                          onClick={() =>
                            elbow_request_status !== 'ES' ? onNoClicked() : ''
                          }
                          className={styles.exploreButton}
                          style={{
                            padding: `${scaleByRatio(8)}px ${scaleByRatio(
                              24,
                            )}px`,
                            fontSize: `${scaleByRatio(21)}px`,
                            background: '#E72933',
                            opacity: `${
                              elbow_request_status == 'ES' ? '0.5' : ''
                            }`,
                          }}
                          // disabled={showChatterBoxAcceptanceButtons && true}
                        >
                          Pass
                        </Button>
                      </Grid>
                    )}
                    {/* <Grid
                    item
                    alignItems="flex-start"
                    justifyContent="center"
                    className={styles.mayBeBtnWrapper}>
                    <Grid
                      variant="contained"
                      onClick={() => onMaybeClicked()}
                      className={styles.exploreButton}
                      style={{
                        padding: `${scaleByRatio(8)}px ${scaleByRatio(24)}px`,
                        fontSize: `${scaleByRatio(24)}px`,
                        // background: '#F7941D',
                      }}>
                      Maybe
                    </Grid>
                  </Grid> */}
                    <Grid
                      item
                      alignItems="flex-start"
                      justifyContent="center"
                      className={styles.yesBtnWrapper}>
                      <Button
                        variant="contained"
                        // onClick={() => onMaybeClicked()}
                        className={styles.exploreButton}
                        // style={{
                        //   padding: `${scaleByRatio(8)}px ${scaleByRatio(24)}px`,
                        //   fontSize: `${scaleByRatio(21)}px`,
                        //   // background: '#3AB54A',
                        //   background: '#F7941D',
                        //   opacity: 0.5,
                        // }}
                        style={{
                          padding: `${scaleByRatio(8)}px ${
                            getwidth == 430
                              ? scaleByRatio(32)
                              : scaleByRatio(24)
                          }px`,
                          fontSize: `${scaleByRatio(21)}px`,
                          // background: '#3AB54A',
                          background: '#F8A233',
                          opacity: 0.5,
                        }}
                        disabled>
                        {/* Yes */}
                        Up for Discussion
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item alignItems="flex-end">
                  <Grid
                  marginTop={'10px'}
                    container
                    direction="row"
                    justifyContent={'space-between'}
                    alignItems="flex-start"
                    // gap={'12px'}
                    >
                    <Grid
                      item
                      alignItems="flex-start"
                      justifyContent="center"
                      className={styles.myProfileAboutFirstSection}>
                      <Grid container direction="column">
                        <Grid item xs={12}>
                          <Typography
                            fullwidth
                            justifyContent="center"
                            className={styles.friendName}
                            style={{
                              cursor: 'pointer',
                              fontSize: `${scaleByRatio(24)}px`,
                              lineHeight: `${scaleByRatio(29)}px`,
                              marginBottom:'2px'
                            }}>
                            <img
                              src={
                                friendsList[0]?.profile_pic || Profile_Avatar
                              }
                              // width={scaleByRatio(200)}
                              // height={scaleByRatio(200)}
                              width={'95%'}
                              height={'95%'}
                              style={{
                                borderRadius: '50%',
                              }}
                              alt={'friend'}
                              onClick={() => handleFriendClickedExplore()}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {friendsList && friendsList.length > 0 && (
                            <Typography
                              fullwidth
                              justifyContent="center"
                              className={styles.friendName}
                              style={{
                                cursor: 'pointer',
                                fontFamily: 'Gill Sans Nova',
                                cursor: 'pointer',
                                fontSize: `${scaleByRatio(12)}px`,
                                lineHeight: `${scaleByRatio(15.62)}px`,
                              }}
                              onClick={() => handleFriendClickedExplore()}>
                              {friendsList[0].first_name}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    {
                      //   localStorageItem.getLocalStorageItem(
                      //   localStorageKeys.userType,
                      // ) === localStorageUserType.friend ||

                      localStorageItem.getLocalStorageItem(
                        localStorageKeys.userType,
                      ) === localStorageUserType.dater  ? (
                        <Grid
                        item
                        alignItems="flex-start"
                        // className={styles.myProfileAboutSecSection}
                        backgroundColor={'#2BB673'}
                        borderRadius={'18px'}
                        padding={'2px'}
                        //marginTop={'-30px'}
                        >
                        <Grid container direction="column" alignItems={'center'}>
                          <Grid item xs={12}
                          padding='12px'
                          paddingTop={'10px'}
                          paddingBottom={'10px'}
                          paddingRight={'10px'}
                          paddingLeft={'10px'}

                          >
                            <Typography
                              fullwidth
                              justifyContent="center"
                              // className={styles.myFriends}
                              style={{
                                // lineHeight: `${scaleByRatio(40)}px`,
                                color: '#fff',
                              }}>
                              <span
                                style={{
                                  fontFamily: 'Gill Sans Nova',
                                  fontWeight: '900',
                                  padding: '10px',
                                  fontSize: `${scaleByRatio(14)}px`,
                                }}>
                                Reach out
                              </span>
                              {/* <span>To one of my</span> */}
                            </Typography>

                            <Typography
                              fullwidth
                              justifyContent="center"
                              // className={styles.myFriends}
                              style={{
                                // lineHeight: `${scaleByRatio(40)}px`,
                                color: '#fff',
                                fontFamily: 'Gill Sans Nova',
                                fontWeight: '900',
                                fontSize: `${scaleByRatio(14)}px`,
                                textAlign: 'center',
                              }}>
                              {/* <span>Reach out</span> */}
                              <span>to one of </span>
                            </Typography>
                            <Typography
                              fullwidth
                              // justifyContent="center"
                              // className={styles.myFriends}
                              style={{
                                // lineHeight: `${scaleByRatio(40)}px`,
                                color: '#fff',
                                fontFamily: 'Gill Sans Nova',
                                fontSize: `${scaleByRatio(14)}px`,

                                fontWeight: '900',
                              }}>
                              <span
                                style={{
                                  padding: '10px',
                                }}>{`my Friends `}</span>
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      ) : (
                        <Grid
                          item
                          alignItems="flex-start"
                          // className={styles.myProfileAboutSecSection}
                          backgroundColor={'#2BB673'}
                          borderRadius={'10px'}
                          padding={'5px'}
                          marginTop={'10%'}>
                          <Grid container direction="column">
                            <Grid item xs={12}>
                              <Typography
                                fullwidth
                                justifyContent="center"
                                // className={styles.myFriends}
                                style={{
                                  // lineHeight: `${scaleByRatio(40)}px`,
                                  color: '#fff',
                                }}>
                                <span
                                  style={{
                                    padding: '5px',
                                  }}>
                                  My Friends
                                </span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    }

                    <Grid
                      item
                      className={styles.myProfileAboutThirdSection}
                      alignItems="flex-start"
                      justifyContent="center">
                      <Grid container direction="column">
                        <Grid item xs={12}>
                          <Typography
                            fullwidth
                            justifyContent="center"
                            className={styles.friendName}
                            style={{
                              cursor: 'pointer',
                              fontSize: `${scaleByRatio(24)}px`,
                              lineHeight: `${scaleByRatio(29)}px`,
                              marginBottom:'2px'
                            }}>
                            <img
                              src={
                                friendsList[1]?.profile_pic || Profile_Avatar
                              }
                              // width={scaleByRatio(200)}
                              // height={scaleByRatio(200)}
                              width={'90%'}
                              height={'90%'}
                              style={{
                                borderRadius: '50%',
                              }}
                              alt={'friend'}
                              onClick={() => handleFriendClickedExplore()}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {friendsList && friendsList.length > 1 && (
                            <Typography
                              fullwidth
                              justifyContent="center"
                              className={styles.friendName}
                              style={{
                                cursor: 'pointer',
                                fontFamily: 'Gill Sans Nova',
                                cursor: 'pointer',
                                fontSize: `${scaleByRatio(12)}px`,
                                lineHeight: `${scaleByRatio(15.62)}px`,
                              }}
                              onClick={() =>
                                handleMenuClicked(HomeMenuConstant.MyFriends)
                              }>
                              {friendsList[1].first_name}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {pageType === ProfilePageType.Convos && friendDescription==null && (
              <>
                <Grid
                  item
                  alignItems="flex-end"
                  className={styles.myProfileAboutMe}>
                  <Grid 
                  container direction="row" 
                  justifyContent={'space-between'}

                  alignItems="flex-start"
                  >
                    <Grid
                      item
                      alignItems="flex-start"
                      justifyContent="center"
                      className={styles.myProfileAboutFirstSection}>
                      <Grid container direction="column">
                        <Grid item xs={12}>
                          <Typography
                            fullwidth
                            justifyContent="center"
                            className={styles.friendName}
                            style={{
                              cursor: 'pointer',
                              fontSize: `${scaleByRatio(24)}px`,
                              lineHeight: `${scaleByRatio(29)}px`,
                            }}>
                            <img
                              src={
                                friendsList[0]?.profile_pic || Profile_Avatar
                              }
                              // width={scaleByRatio(200)}
                              // height={scaleByRatio(200)}
                              width={'90%'}
                              height={'90%'}
                              style={{
                                borderRadius: '50%',
                              }}
                              alt={'friend'}
                              onClick={() => handleFriendClickedExplore()}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {friendsList && friendsList.length > 0 && (
                            <Typography
                              fullwidth
                              justifyContent="center"
                              className={styles.friendName}
                              style={{
                                marginTop:'2px',
                                fontFamily: 'Gill Sans Nova',
                                cursor: 'pointer',
                                fontSize: `${scaleByRatio(12)}px`,
                                lineHeight: `${scaleByRatio(15.62)}px`,
                              }}
                              onClick={() => handleFriendClickedExplore()}>
                              {friendsList[0].first_name}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                          item
                          alignItems="flex-start"
                          alignSelf={'center'}
                          // className={styles.myProfileAboutSecSection}
                          backgroundColor={'#2BB673'}
                          borderRadius={'18px'}
                          padding={'2px'}
                          //marginTop={'-30px'}
                          >
                          <Grid container direction="column" alignItems={'center'}>
                            <Grid item xs={12}
                            padding='12px'
                            paddingTop={'10px'}
                            paddingBottom={'10px'}
                            paddingRight={'10px'}
                            paddingLeft={'10px'}

                            >
                              <Typography
                                fullwidth
                                justifyContent="center"
                                // className={styles.myFriends}
                                style={{
                                  // lineHeight: `${scaleByRatio(40)}px`,
                                  color: '#fff',
                                }}>
                                <span
                                  style={{
                                    fontFamily: 'Gill Sans Nova',
                                    fontWeight: '900',
                                    padding: '10px',
                                    fontSize: `${scaleByRatio(14)}px`,
                                  }}>
                                  Reach out
                                </span>
                                {/* <span>To one of my</span> */}
                              </Typography>

                              <Typography
                                fullwidth
                                justifyContent="center"
                                // className={styles.myFriends}
                                style={{
                                  // lineHeight: `${scaleByRatio(40)}px`,
                                  color: '#fff',
                                  fontFamily: 'Gill Sans Nova',
                                  fontWeight: '900',
                                  fontSize: `${scaleByRatio(14)}px`,
                                  textAlign: 'center',
                                }}>
                                {/* <span>Reach out</span> */}
                                <span>to one of </span>
                              </Typography>
                              <Typography
                                fullwidth
                                // justifyContent="center"
                                // className={styles.myFriends}
                                style={{
                                  // lineHeight: `${scaleByRatio(40)}px`,
                                  color: '#fff',
                                  fontFamily: 'Gill Sans Nova',
                                  fontSize: `${scaleByRatio(14)}px`,

                                  fontWeight: '900',
                                }}>
                                <span
                                  style={{
                                    padding: '10px',
                                  }}>{`my Friends `}</span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                    <Grid
                      item
                      className={styles.myProfileAboutThirdSection}
                      alignItems="flex-start"
                      justifyContent="center">
                      <Grid container direction="column">
                        <Grid item xs={12}>
                          <Typography
                            fullwidth
                            justifyContent="center"
                            marginTop={'5px'}
                            className={styles.friendName}
                            style={{
                              cursor: 'pointer',
                              fontSize: `${scaleByRatio(24)}px`,
                              lineHeight: `${scaleByRatio(29)}px`,
                            }}>
                            <img
                              src={
                                friendsList[1]?.profile_pic || Profile_Avatar
                              }
                              // width={scaleByRatio(200)}
                              // height={scaleByRatio(200)}
                              width={'90%'}
                              height={'90%'}
                              style={{
                                borderRadius: '50%',
                              }}
                              alt={'friend'}
                              onClick={() => handleFriendClickedExplore()}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {friendsList && friendsList.length > 1 && (
                            <Typography
                              fullwidth
                              justifyContent="center"
                              className={styles.friendName}
                              style={{
                                marginTop:'2px',
                                fontFamily: 'Gill Sans Nova',
                                cursor: 'pointer',
                                fontSize: `${scaleByRatio(12)}px`,
                                lineHeight: `${scaleByRatio(15.62)}px`,
                              }}
                              onClick={() =>
                                handleMenuClicked(HomeMenuConstant.MyFriends)
                              }>
                              {friendsList[1].first_name}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {localStorageItem.getLocalStorageItem(
                  localStorageKeys.userType,
                ) === localStorageUserType.friend &&
                  !convos_direct_session_introw_status && (
                    <Grid item alignItems="flex-end">
                      <Grid
                        container
                        direction="row"
                        alignItems="flex-start"
                        style={{
                          marginBottom: `${scaleByRatio(10)}px`,
                          gap: '5px',
                        }}>
                        <Grid
                          item
                          alignItems="flex-start"
                          justifyContent="center">
                          <Button
                            variant="contained"
                            onClick={() => onNoClicked()}
                            className={styles.exploreButton}
                            style={{
                              padding: `${scaleByRatio(8)}px ${scaleByRatio(
                                24,
                              )}px`,
                              fontSize: `${scaleByRatio(24)}px`,
                              background: '#FE0000',
                            }}>
                            Decline
                          </Button>
                        </Grid>

                        <Grid
                          item
                          alignItems="flex-start"
                          justifyContent="center">
                          <Button
                            variant="contained"
                            onClick={() => onYesClicked()}
                            className={styles.exploreButton}
                            style={{
                              padding: `${scaleByRatio(8)}px ${scaleByRatio(
                                24,
                              )}px`,
                              fontSize: `${scaleByRatio(24)}px`,
                              background: '#3AB54A',
                            }}>
                            Make An Introw
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
              </>
            )}
            {pageType === ProfilePageType.Explore && (
              <>
                <Grid
                  item
                  alignItems="flex-end"
                  className={styles.myProfileAboutMe}>
                  <Grid
                    container
                    className={styles.maybNoYesbtnWrapper}
                    direction="row"
                    alignItems="flex-start"
                    justifyContent={'space-around'}
                    style={
                      localStorageItem.getLocalStorageItem(
                        localStorageKeys.userType,
                      ) === localStorageUserType.dater
                        ? {
                            marginBottom: `${scaleByRatio(10)}px`,
                            gap: '15px',
                            // justifyContent: 'center',
                          }
                        : {
                            marginBottom: `${scaleByRatio(10)}px`,
                            gap: '15px',
                            justifyContent: 'center',
                            marginLeft: `${getwidth > 700 ? '-12%' : '-2%'}`,
                          }
                    }>
                    {localStorageItem.getLocalStorageItem(
                      localStorageKeys.userType,
                    ) === localStorageUserType.dater && (
                      <Grid
                        item
                        alignItems="flex-start"
                        justifyContent="center"
                        className={styles.noBtnWrapper}>
                        <Button
                          variant="contained"
                          onClick={() => onNoClicked()}
                          className={styles.exploreButton}
                          style={{
                            padding: `${scaleByRatio(8)}px ${scaleByRatio(
                              24,
                            )}px`,
                            fontSize: `${scaleByRatio(21)}px`,
                            background: '#E72933',
                          }}>
                          Pass
                        </Button>
                      </Grid>
                    )}
                    {/* <Grid
                      item
                      alignItems="flex-start"
                      justifyContent="center"
                      className={styles.mayBeBtnWrapper}>
                      <Grid
                        variant="contained"
                        onClick={() => onMaybeClicked()}
                        className={styles.exploreButton}
                        style={{
                          padding: `${scaleByRatio(8)}px ${scaleByRatio(24)}px`,
                          fontSize: `${scaleByRatio(24)}px`,
                          // background: '#F7941D',
                        }}>
                        Maybe
                      </Grid>
                    </Grid> */}
                    <Grid
                      item
                      alignItems="flex-start"
                      justifyContent="center"
                      className={styles.yesBtnWrapper}>
                      <Button
                        variant="contained"
                        onClick={() => onMaybeClicked()}
                        className={styles.exploreButton}
                        style={{
                          padding: `${scaleByRatio(8)}px ${
                            getwidth == 430
                              ? scaleByRatio(32)
                              : scaleByRatio(24)
                          }px`,
                          fontSize: `${scaleByRatio(21)}px`,
                          // background: '#3AB54A',
                          background: '#F8A233',
                        }}>
                        {/* Yes */}
                        Up for Discussion
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                item 
                alignItems="flex-end">
                  <Grid
                  marginTop={'10px'}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent={'space-between'}
                    //gap={'12px'}
                    >
                    <Grid
                      item
                      alignItems="flex-start"
                      justifyContent="center"
                      className={styles.myProfileAboutFirstSection}>
                      <Grid container direction="column">
                        <Grid item xs={12}>
                          <Typography
                            fullwidth
                            justifyContent="center"
                            className={styles.friendName}
                            style={{
                              cursor: 'pointer',
                              fontSize: `${scaleByRatio(24)}px`,
                              lineHeight: `${scaleByRatio(29)}px`,
                            }}>
                            <img
                              src={
                                friendsList[0]?.profile_pic || Profile_Avatar
                              }
                              // width={scaleByRatio(200)}
                              // height={scaleByRatio(200)}
                              width={'90%'}
                              height={'90%'}
                              style={{
                                borderRadius: '50%',
                              }}
                              alt={'friend'}
                              onClick={() => handleFriendClickedExplore()}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {friendsList && friendsList.length > 0 && (
                            <Typography
                              fullwidth
                              justifyContent="center"
                              className={styles.friendName}
                              style={{
                                fontFamily: 'Gill Sans Nova',
                                cursor: 'pointer',
                                fontSize: `${scaleByRatio(12)}px`,
                                lineHeight: `${scaleByRatio(15.62)}px`,
                              }}
                              onClick={() => handleFriendClickedExplore()}>
                              {friendsList[0].first_name}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    {
                      //   localStorageItem.getLocalStorageItem(
                      //   localStorageKeys.userType,
                      // ) === localStorageUserType.friend ||

                      localStorageItem.getLocalStorageItem(
                        localStorageKeys.userType,
                      ) === localStorageUserType.dater ? (
                        <Grid
                          item
                          alignItems="flex-start"
                          // className={styles.myProfileAboutSecSection}
                          backgroundColor={'#2BB673'}
                          borderRadius={'18px'}
                          padding={'2px'}
                          //marginTop={'-30px'}
                          >
                          <Grid container direction="column" alignItems={'center'}>
                            <Grid item xs={12}
                            padding='12px'
                            paddingTop={'10px'}
                            paddingBottom={'10px'}
                            paddingRight={'10px'}
                            paddingLeft={'10px'}

                            >
                              <Typography
                                fullwidth
                                justifyContent="center"
                                // className={styles.myFriends}
                                style={{
                                  // lineHeight: `${scaleByRatio(40)}px`,
                                  color: '#fff',
                                }}>
                                <span
                                  style={{
                                    fontFamily: 'Gill Sans Nova',
                                    fontWeight: '900',
                                    padding: '10px',
                                    fontSize: `${scaleByRatio(14)}px`,
                                  }}>
                                  Reach out
                                </span>
                                {/* <span>To one of my</span> */}
                              </Typography>

                              <Typography
                                fullwidth
                                justifyContent="center"
                                // className={styles.myFriends}
                                style={{
                                  // lineHeight: `${scaleByRatio(40)}px`,
                                  color: '#fff',
                                  fontFamily: 'Gill Sans Nova',
                                  fontWeight: '900',
                                  fontSize: `${scaleByRatio(14)}px`,
                                  textAlign: 'center',
                                }}>
                                {/* <span>Reach out</span> */}
                                <span>to one of </span>
                              </Typography>
                              <Typography
                                fullwidth
                                // justifyContent="center"
                                // className={styles.myFriends}
                                style={{
                                  // lineHeight: `${scaleByRatio(40)}px`,
                                  color: '#fff',
                                  fontFamily: 'Gill Sans Nova',
                                  fontSize: `${scaleByRatio(14)}px`,

                                  fontWeight: '900',
                                }}>
                                <span
                                  style={{
                                    padding: '10px',
                                  }}>{`my Friends `}</span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid
                          item
                          alignItems="flex-start"
                          // className={styles.myProfileAboutSecSection}
                          backgroundColor={'#2BB673'}

                          borderRadius={'50px'}
                          padding={'9px'}
                          paddingLeft={'17px'}
                          paddingRight={'17px'}

                          //marginTop={'10%'}
                          >
                          <Grid container direction="column">
                            <Grid item xs={12}>
                              <Typography
                                fullwidth
                                justifyContent="center"
                                // className={styles.myFriends}
                                style={{
                                  fontFamily:'Gill Sans Nova',
                                  fontWeight: 900,
                                  fontSize:'12px',
                                  // lineHeight: `${scaleByRatio(40)}px`,
                                  color: '#fff',
                                }}>
                                <span
                                  style={{
                                    padding: '5px',
                                  }}>
                                  My Friends
                                </span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    }

                    <Grid
                      item
                      className={styles.myProfileAboutThirdSection}
                      alignItems="flex-start"
                      justifyContent="center">
                      <Grid container direction="column">
                        <Grid item xs={12}>
                          <Typography
                            fullwidth
                            justifyContent="center"
                            className={styles.friendName}
                            style={{
                              cursor: 'pointer',
                              fontSize: `${scaleByRatio(24)}px`,
                              lineHeight: `${scaleByRatio(29)}px`,
                            }}>
                            <img
                              src={
                                friendsList[1]?.profile_pic || Profile_Avatar
                              }
                              // width={scaleByRatio(200)}
                              // height={scaleByRatio(200)}
                              // width={'100%'}
                              // height={'100%'}
                              width={'95%'}
                              height={'95%'}
                              style={{
                                borderRadius: '50%',
                              }}
                              alt={'friend'}
                              onClick={() => handleFriendClickedExplore()}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {friendsList && friendsList.length > 1 && (
                            <Typography
                              fullwidth
                              justifyContent="center"
                              className={styles.friendName}
                              style={{
                                cursor: 'pointer',
                                fontSize: `${scaleByRatio(12)}px`,
                                lineHeight: `${scaleByRatio(15.62)}px`,
                              }}
                              onClick={() =>
                                handleMenuClicked(HomeMenuConstant.MyFriends)
                              }>
                              {friendsList[1].first_name}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {pageType === ProfilePageType.Elbows && (
              <>
                <Grid
                  item
                  alignItems="flex-end"
                  className={styles.myProfileAboutMe}>
                  <Grid container direction="row" alignItems="flex-start">
                    <Grid
                      item
                      alignItems="flex-start"
                      justifyContent="center"
                      className={styles.myProfileAboutFirstSection}>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography
                            fullwidth
                            justifyContent="center"
                            className={styles.friendName}
                            style={{
                              cursor: 'pointer',
                              fontSize: `${scaleByRatio(24)}px`,
                              lineHeight: `${scaleByRatio(29)}px`,
                            }}>
                            <img
                              src={
                                friendsList[0]?.profile_pic || Profile_Avatar
                              }
                              // width={scaleByRatio(200)}
                              // height={scaleByRatio(200)}
                              width={'95%'}
                              height={'95%'}
                              style={{
                                borderRadius: '10px',
                              }}
                              alt={'friend'}
                              onClick={() => handleFriendClickedExplore()}
                            />
                          </Typography>
                        </Grid>
                        <Grid item>
                          {friendsList && friendsList.length > 0 && (
                            <Typography
                              fullwidth
                              justifyContent="center"
                              className={styles.friendName}
                              style={{
                                cursor: 'pointer',
                                fontSize: `${scaleByRatio(24)}px`,
                                lineHeight: `${scaleByRatio(29)}px`,
                              }}
                              onClick={() => handleFriendClickedExplore()}>
                              {friendsList[0].first_name}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      alignItems="flex-start"
                      className={styles.myProfileAboutSecSection}>
                      <Grid container direction="column">
                        <Grid item xs={12}>
                          <Typography
                            fullwidth
                            justifyContent="center"
                            className={styles.myFriends}
                            style={
                              {
                                // lineHeight: `${scaleByRatio(40)}px`,
                              }
                            }>
                            My
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            fullwidth
                            justifyContent="center"
                            className={styles.myFriends}
                            style={
                              {
                                // lineHeight: `${scaleByRatio(40)}px`,
                              }
                            }>
                            Friends
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            fullwidth
                            justifyContent="center"
                            className={styles.reachOut}>
                            reach out to us
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      // xs={1}
                      className={styles.myProfileAboutThirdSection}
                      alignItems="flex-start"
                      justifyContent="center">
                      <Grid container direction="column">
                        <Grid item xs={12}>
                          <Typography
                            fullwidth
                            justifyContent="center"
                            className={styles.friendName}
                            style={{
                              cursor: 'pointer',
                              fontSize: `${scaleByRatio(24)}px`,
                              lineHeight: `${scaleByRatio(29)}px`,
                            }}>
                            <img
                              src={
                                friendsList[1]?.profile_pic || Profile_Avatar
                              }
                              // width={scaleByRatio(200)}
                              // height={scaleByRatio(200)}
                              // width={'100%'}
                              // height={'100%'}
                              width={'95%'}
                              height={'95%'}
                              style={{
                                borderRadius: '10px',
                              }}
                              alt={'friend'}
                              onClick={() => handleFriendClickedExplore()}
                            />
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {friendsList && friendsList.length > 1 && (
                            <Typography
                              fullwidth
                              justifyContent="center"
                              className={styles.friendName}
                              style={{
                                cursor: 'pointer',
                                fontSize: `${scaleByRatio(24)}px`,
                                lineHeight: `${scaleByRatio(29)}px`,
                              }}
                              onClick={() =>
                                handleMenuClicked(HomeMenuConstant.MyFriends)
                              }>
                              {friendsList[1].first_name}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {elbow_request_status === 'P' && (
                  <Grid item alignItems="flex-end">
                    <Grid
                      container
                      direction="row"
                      alignItems="flex-start"
                      style={{
                        marginBottom: `${scaleByRatio(10)}px`,
                        display: 'flex',
                        gap: '10px',
                      }}>
                      <Grid
                        item
                        // xs={4}
                        alignItems="flex-start"
                        justifyContent="center">
                        <Button
                          variant="contained"
                          onClick={() => onNoClicked()}
                          className={styles.exploreButton}
                          style={{
                            padding: `${scaleByRatio(8)}px ${scaleByRatio(
                              24,
                            )}px`,
                            fontSize: `${scaleByRatio(24)}px`,
                            background: '#FE0000',
                          }}>
                          No
                        </Button>
                      </Grid>

                      <Grid
                        item
                        alignItems="flex-start"
                        justifyContent="center">
                        <Button
                          variant="contained"
                          onClick={() => onYesClicked()}
                          className={styles.exploreButton}
                          style={{
                            padding: `${scaleByRatio(8)}px ${scaleByRatio(
                              24,
                            )}px`,
                            fontSize: `${scaleByRatio(24)}px`,
                            background: '#3AB54A',
                          }}>
                          Yes
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
            {pageType === ProfilePageType.MyProfile && (
              <Grid
                item
                alignItems="flex-end"
                className={styles.myProfileAboutMe}>
                <Grid container direction="row" alignItems="center" justifyContent={'space-between'}>
                  <Grid
                    item
                    alignItems="flex-start"
                    justifyContent="center"
                    className={`${styles.myProfileAboutFirstSection} ${styles.myProfileAboutFirstSectionWrapper}`}>
                    <Grid container direction="column" alignItems="center">
                      <Grid item>
                        <Typography
                          fullwidth
                          justifyContent="center"
                          className={`${styles.friendName} ${styles.myProfileFriendName}`}
                          style={{
                            cursor: 'pointer',
                            fontSize: `${scaleByRatio(24)}px`,
                            lineHeight: `${scaleByRatio(29)}px`,
                          }}>
                          {friendsList && friendsList.length > 0 && (
                            <img
                              src={friendsList[0].profile_pic || Profile_Avatar}
                              // width={scaleByRatio(200)}
                              // height={scaleByRatio(200)}
                              width={'95%'}
                              height={'95%'}
                              style={{
                                borderRadius: '50%',
                              }}
                              alt={'friend'}
                              onClick={() =>
                                handleMenuClicked(HomeMenuConstant.MyFriends)
                              }
                              // style={{ objectFit: 'contain' }}
                            />
                          )}
                          {friendsList.length === 0 && (
                            <img
                              src={AddFriend}
                              alt={'add friend'}
                              // width={scaleByRatio(200)}
                              // height={scaleByRatio(200)}
                              width={'95%'}
                              height={'95%'}
                              // style={{marginTop: `${scaleByRatio(-38)}px`}}
                              onClick={() => setShowAddFriend(true)}
                            />
                          )}
                          {/* <img src={Friend} alt={'friend'} /> */}
                        </Typography>
                      </Grid>
                      <Grid item>
                        {friendsList && friendsList.length > 0 && (
                          <Typography
                            fullwidth
                            justifyContent="center"
                            className={styles.friendName}
                            style={{
                              cursor: 'pointer',
                              fontSize: `${scaleByRatio(12)}px`,
                              lineHeight: `${scaleByRatio(15.62)}px`,
                              textAlign: 'center', // Center the text horizontally

                            }}
                            onClick={() =>
                              handleMenuClicked(HomeMenuConstant.MyFriends)
                            }>
                            {friendsList[0].first_name}
                          </Typography>
                        )}
                        {friendsList.length === 0 && (
                          <Typography
                            fullwidth
                            justifyContent="center"
                            className={styles.friendName}
                            style={{
                              cursor: 'pointer',
                              fontSize: `${scaleByRatio(12)}px`,
                                lineHeight: `${scaleByRatio(15.62)}px`,
                            }}
                            onClick={() => setShowAddFriend(true)}>
                            Add Friend
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                          item
                          alignItems="flex-start"
                          // className={styles.myProfileAboutSecSection}
                          backgroundColor={'#2BB673'}
                          borderRadius={'50px'}
                          padding={'2px'}
                          marginTop={'-25px'}>
                          <Grid container direction="column" alignItems={'center'}>
                            <Grid item xs={12}>
                              
                              <Typography
                                fullwidth
                                // justifyContent="center"
                                // className={styles.myFriends}
                                style={{
                                  // lineHeight: `${scaleByRatio(40)}px`,
                                  color: '#fff',
                                  fontFamily: 'Gill Sans Nova',
                                  fontSize: `${scaleByRatio(14)}px`,
                                  paddingRight: '20px',
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                  paddingLeft: '20px',
                                  fontWeight: '900',
                                }}>
                                <span
                                  style={{
                                    //padding: '10px',
                                  }}>{`My friends `}</span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                  <Grid
                    item
                    alignItems="flex-start"
                    justifyContent="center"
                    className={styles.myProfileAboutThirdSection}>
                    <Grid container direction="column" alignItems="center">
                      <Grid item xs={12}>
                        <Typography
                          fullwidth
                          justifyContent="center"
                          className={styles.friendName}
                          style={{
                            cursor: 'pointer',
                            fontSize: `${scaleByRatio(24)}px`,
                            lineHeight: `${scaleByRatio(29)}px`,
                          }}>
                          {friendsList && friendsList.length > 1 && (
                            <img
                              src={friendsList[1].profile_pic || Profile_Avatar}
                              // width={scaleByRatio(200)}
                              // height={scaleByRatio(200)}
                              width={'95%'}
                              height={'95%'}
                              style={{
                                borderRadius: '50%',
                              }}
                              alt={'friend'}
                              onClick={() =>
                                handleMenuClicked(HomeMenuConstant.MyFriends)
                              }
                              // style={{ objectFit: 'contain' }}
                            />
                          )}
                          {friendsList.length < 2 && (
                            <img
                              src={AddFriend}
                              alt={'add friend'}
                              // width={scaleByRatio(200)}
                              // height={scaleByRatio(200)}
                              width={'95%'}
                              height={'95%'}
                              style={{
                                borderRadius: '50%',
                              }}
                              onClick={() => setShowAddFriend(true)}
                            />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        {friendsList && friendsList.length > 1 && (
                          <Typography
                            fullwidth
                            justifyContent="center"
                            className={styles.friendName}
                            style={{
                              cursor: 'pointer',
                              fontSize: `${scaleByRatio(12)}px`,
                              lineHeight: `${scaleByRatio(15.62)}px`,
                            }}
                            onClick={() =>
                              handleMenuClicked(HomeMenuConstant.MyFriends)
                            }>
                            {friendsList[1].first_name}
                          </Typography>
                        )}
                        {friendsList.length < 2 && (
                          <Typography
                            fullwidth
                            justifyContent="center"
                            className={styles.friendName}
                            style={{
                              cursor: 'pointer',
                              fontSize: `${scaleByRatio(12)}px`,
                              lineHeight: `${scaleByRatio(15.62)}px`,
                            }}
                            onClick={() => setShowAddFriend(true)}>
                            Add Friend
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MyProfileAboutUs;
