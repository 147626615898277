import { Button, Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Pencil from '../../assets/Pencil.svg';
import styles from './index.module.scss';
import {
  useGetAllQuestions,
  useGetQuestionDetailsById,
  useGetQuestionResponse,
  usePatchSingleChoiceAnswer,
  usePostSingleChoiceAnswer,
} from '../../services/mutations';
import SingleChoiceSelectionButton from '../../atoms/SingleChoiceSelectionButton';
import { Link, useNavigate } from 'react-router-dom';
import { routeUrls } from '../../utils/constants';
import {
  ButtonType,
  ButtonVariant,
  CustomButton,
} from '../../atoms/CustomButton';
import MyProfileSection from '../MyProfileSection';
import CustomChip from '../../atoms/CustomChip';
import { scaleByRatio } from '../../utils/helper';
import { Edit } from '@mui/icons-material';

function MyProfileOthersData({
  allQuestions,
  allAnswers,
  clickable,
  description = null,
  setEditMode,
}) {
  const [response, setResponse] = useState();
  const getAllQuestions = useGetAllQuestions();
  const [ans, setAllAns] = useState(allAnswers);
  const getQuestionResponse = useGetQuestionResponse();
  const [data, setData] = useState();
  const getQuestionDetailsById = useGetQuestionDetailsById();

  const [pendingQuestionList, setPendingQuestionList] = useState([]);
  const [question, setQuestion] = useState(null);
  const [questionDetails, setQuestionDetails] = useState({});
  const nav = useNavigate();

  const qId = [22, 1, 8, 2, 12, 6, 7, 9, 3, 10, 4, 11, 5];
  const nd = [];
  allQuestions != null &&
    allQuestions.map(
      (data) => console.log(data?.identifier, data),
      qId.includes(data?.identifier) ? console.log(true) : '',
    );
  useEffect(() => {
    setResponse(allAnswers);
  }, []);

  const getQuestionDetailsByIdentifier = (questionId) => {
    // setLoading(true);
    const storData = [];
    let qData = {
      id: questionId,
    };
    getQuestionDetailsById.mutate(qData, {
      onSuccess: (response) => {
        let _questionDetails = response?.data?.payload;
        setQuestionDetails(_questionDetails);
        storData.push(_questionDetails);
        //get answers
        return;
      },
      onError: (error) => {
        // setLoading(false);
        console.log('getQuestionDetailsById error: ', error);
      },
    });
    return storData;
  };

  const getMCAnswers = (question) => {
    const filterAns = ans.filter(
      (item) => item?.answer?.question?.identifier === question?.identifier,
    );
    if (filterAns.length) {
      return filterAns.map((item) => (
        <span
          style={{
            marginRight: '5px',
            // marginBottom: '5px',
            // background: '#494F4F',
            padding: '5px',
            borderRadius: '20px',
            textAlign: 'center',
          }}>
          {' '}
          <CustomChip
            label={item?.answer?.value != null && item?.answer?.value}
          />{' '}
        </span>
      ));
    }
    // return <CustomChip label={question?.name} />;
  };

  return (
    <>
      {description != null ? (
        <Grid className={styles.subHeader}>
          <Grid m={1} className={styles.subHeader}>Profile Description...</Grid>
          <Grid m={1} style={{ fontWeight: '600' }} className={styles.subHeaderAns}>
            {description}
          </Grid>
        </Grid>
      ) : (
        allQuestions != null &&
        allQuestions.map((question) =>
          qId.includes(question?.identifier) ? (
            question?.choice_type === 'TF' ? (
              <>
                <Grid container display={'flex'} justifyContent={'flex-end'}>
                  <Grid
                    item
                    className={styles.subHeader}
                    m={1}
                    style={{ cursor: `${clickable ? 'pointer' : 'auto'}` }}
                    onClick={() => {
                      if (clickable) {
                        nav(routeUrls.updateData, {
                          state: {
                            question: question,
                            questionId: question?.id,
                            choice_type: question?.choice_type,
                            title: question?.name,
                            allQ: ans,
                            allAnswers: allAnswers,
                            clickable: clickable,
                          },
                        });
                      }
                    }}>
                    {question?.name.endsWith('?')
                      ? question.name.replace(/ \?$/, '...')
                      : `${question?.name}...`}
                    {clickable && (
                      <Grid
                        //mt={-2}
                        item
                        style={{ float: 'right' }}>
                        <img
                          src={Pencil}
                          alt="EditIcon"
                          className={styles.TopMenuICon}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  m={1}
                  style={{ fontWeight: '600' }}
                  className={styles.subHeaderAns}>
                  {ans.find(
                    (item) =>
                      item?.answer?.question?.identifier ===
                      question?.identifier,
                  )?.value
                    ? ans.find(
                        (item) =>
                          item?.answer?.question?.identifier ===
                          question?.identifier,
                      )?.value
                    : // question?.name
                      ''}
                </Grid>
              </>
            ) : question?.choice_type === 'MC' ? (
              <>
                <Grid container display={'flex'} justifyContent={'flex-end'}>
                  <Grid
                    item
                    className={styles.subHeader}
                    m={1}
                    style={{ cursor: `${clickable ? 'pointer' : 'auto'}` }}
                    onClick={() => {
                      if (clickable) {
                        nav(routeUrls.daterUpdatedQuestion, {
                          state: {
                            questionId: question?.id,
                            choice_type: question?.choice_type,
                            title: question?.name,
                            allQ: ans,
                          },
                        });
                      }
                    }}>
                    {question?.name.replace(/ \?$/, '...')}
                    {clickable && (
                      <Grid
                        // mt={-2}
                        style={{ float: 'right' }}>
                        <img
                          src={Pencil}
                          alt="EditIcon"
                          className={styles.TopMenuICon}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  className={styles.subHeaderAns}
                  item
                  m={1}
                  justifyContent="left"
                  alignItems="flex-start"
                  style={{
                    paddingRight: '15px',
                    margin: '0px',
                  }}>
                  <Grid
                    item
                    xs={12}
                    direction="row"
                    justifyContent="left"
                    // m={1}
                    // style={{ fontSize: '1px' }}
                    alignItems="flex-start">
                    {getMCAnswers(question)}
                  </Grid>
                </Grid>
              </>
            ) : question?.choice_type === 'SC' ? (
              <>
                <Grid container display={'flex'} justifyContent={'flex-end'}>
                  <Grid
                    className={styles.subHeader}
                    style={{ cursor: `${clickable ? 'pointer' : 'auto'}` }}
                    m={1}
                    onClick={() => {
                      if (clickable) {
                        nav(routeUrls.daterUpdatedSCQuestion, {
                          state: {
                            questionId: question?.id,
                            choice_type: question?.choice_type,
                            title: question?.name,
                            allQ: ans,
                          },
                        });
                      }
                    }}>
                    {question?.name.replace(/ \?$/, '...')}
                    {clickable && (
                      <Grid
                        //   mt={-2}
                        style={{ float: 'right' }}>
                        <img
                          src={Pencil}
                          alt="EditIcon"
                          className={styles.TopMenuICon}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  className={styles.subHeaderAns}
                  item
                  justifyContent="left"
                  m={1}
                  alignItems="flex-start"
                  style={{ paddingRight: '15px', margin: '0px' }}>
                  <Grid
                    item
                    xs={12}
                    direction="row"
                    justifyContent="left"
                    alignItems="flex-start"
                    style={{
                      cursor: `${clickable ? 'pointer' : 'auto'}`,
                    }}
                    className="chipHeight">
                    {ans.find(
                      (item) =>
                        item?.answer?.question?.identifier ===
                        question?.identifier,
                    )?.value ? (
                      <Stack spacing={1} m={1} fontSize={2}>
                        <CustomChip
                          label={
                            ans.find(
                              (item) =>
                                item?.answer?.question?.identifier ===
                                question?.identifier,
                            )?.value
                          }
                        />
                      </Stack>
                    ) : (
                      <Stack spacing={1}>
                        <CustomChip
                        // style={{ margin: '5px' }}
                        // label={question?.name}
                        />
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : null
          ) : null,
        )
      )}
      {/* <Link
        style={{ display: 'block', textAlign: 'center', color: '#F46B1B' }}
        //  to={routeUrls.reportUser}
      >
        Report User
      </Link> */}
    </>
  );
}

export default MyProfileOthersData;
