import styles from './index.module.scss'
import { Grid } from '@mui/material'

const FullPageGridLayout = ({ heightt = '75vh', children }) => {
    return ( 
        <Grid container xs={11} sm={11} md={11} lg={11} 
        height={heightt}
        className={styles.container} justifyContent="center" 
        alignItems="center">
            {children}
        </Grid>
     );
}
 
export default FullPageGridLayout;