import React, {useState, useEffect} from 'react'
import styles from './index.module.scss'
import {  Grid } from '@mui/material'
import Typography from '@mui/material/Typography';
import { scaleByRatio } from '../../utils/helper';
import Slider from '@mui/material/Slider';
import { AnswerType } from '../../utils/constants'
import { useWindowSize } from '../../utils/useWindowSize';
import DownArrow from '../../assets/DownArrow.svg';
import UpArrow from '../../assets/UpArrow.svg';
import Box from '@mui/material/Box';


const RangeSlider = (
    {
        question,
        id,
        identifier,
        minValue,
        maxValue,
        question_color,
        displayMin,
        displayMax,
        selectedValue,
        handleOnChangeCallBack,
        question_type
    }
) => {
    const [showData, setShowData] = useState(false);

    const [value, setValue] = useState(selectedValue);
    const [_displayMin, set_displayMin] = useState(displayMin)
    const [_displayMax, set_displayMax] = useState(displayMax)
    const [height, width] = useWindowSize()
    const [getwidth, setWidth] = useState(0);

    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window?.innerWidth)
        }
    }, [width]);

    const toFeet = (n)=>{

        let heightin = Math.round(parseInt(n) / 2.54)
        let heightft = Math.floor(heightin/12)
        let remainingInch = heightin - (Math.floor(heightft)*12)
        
        // var realFeet = ((n*0.393700) / 12);
        // var feet = Math.floor(realFeet);
        // var inches = Math.round((realFeet - feet) * 12);
        return heightft + "'" + remainingInch + '"';
      }
  
      const commonCircleStyle = {
        width: '40px',
        height: '40px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: `${scaleByRatio(12)}px`,
        borderRadius: '50%',
        textAlign: 'center',
        backgroundColor: '#f0f0f0', // Optional: Add background color for better visibility
    };

    const circleDiameter = `${scaleByRatio(40)}px`; // Adjust the scale factor if needed

    const handleChange = (event, newValue) => {
        console.log('handleChange: ', newValue)
        setValue(newValue);
        if(identifier === 'height'){
            set_displayMin(toFeet(newValue[0]))
            set_displayMax(toFeet(newValue[1]))
        }
        if(identifier === 'age'){
            set_displayMin(newValue[0])
            set_displayMax(newValue[1])
        }
        handleOnChangeCallBack(newValue, id, identifier, question_type, AnswerType.Range)
        
      };
    return ( 
        <>
<Grid marginBottom={'10px'}>
<Grid container direction="row">
        <Grid
          item
          display={'flex'}
          justifyContent={'flex-start'}
          alignContent={'flex-start'}>
          <Typography
            textAlign="left"
            className={styles.question}
            style={{
              // color: `${question_color}`,
              color: '#F4AA4D',
              lineHeight: `${scaleByRatio(24)}px`,
              letterSpacing: '0.02em'
            }}>
            {question}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        padding={'20px'}
        paddingBottom={'15px'}
        display={'flex'}
        justifyContent={'flex-end'}
        alignContent={'flex-end'}
        onClick={() => setShowData(!showData)}
        style={{ cursor: 'pointer' }}
        mt={-5}>
        {showData ? (
          <img
            src={UpArrow}
            alt="dropIcon"
            width={'16px'}
            height={'14px'}
            // style={{ marginRight: '15px' }}
          />
        ) : (
          <img
            src={DownArrow}
            alt="upIcon"
            width={'16px'}
            height={'14px'}
            // style={{ marginRight: '15px' }}
          />
        )}
      </Grid>
{ showData && 
<>
<Box sx={{ display: 'flex', alignItems: 'center', marginBottom: `${scaleByRatio(18)}px` }} className="datingRange" marginTop={'18px'}>
            <Box sx={{ marginRight: '5px' }}>
                <Typography className={styles.sliderBox} style={commonCircleStyle}>
                    {_displayMin}
                </Typography>
            </Box>
            <Box sx={{ flex: 1,  }}>
                <Slider
                    getAriaLabel={() => 'Height range'}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay="off"
                    min={minValue}
                    max={maxValue}
                    size="small"
                    sx={{
                        '& .MuiSlider-track': {
                            color: '#F93',
                        },
                        '& .MuiSlider-thumb': {
                            color: '#F93',
                        },
                        '& .MuiSlider-rail': {
                            color: '#cccccc',
                        },
                    }}
                />
            </Box>
            <Box sx={{ marginLeft: '5px' }}>
                <Typography className={styles.sliderBox} style={commonCircleStyle}>
                    {_displayMax}
                </Typography>
            </Box>
        </Box>
        </>
        }
        </Grid>
        </>
     );
}
 
export default RangeSlider;